import React, {useEffect, useMemo, useState} from 'react';
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  Stack,
  Typography,
  useTheme
} from "@mui/material";
import {darken, styled} from "@mui/material/styles";
import {
  useGetEquipmentTypesQuery,
  useGetSportCategoriesQuery,
  useGetSportKindsQuery
} from "../../../@core/@graphql/types";
import {useNavigate, useSearchParams} from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Tooltiper from "../../../@core/components/Tooltiper";
import StickyBox from "react-sticky-box";
import {useReactiveVar} from "@apollo/client";
import {EventType, sportObjectVar, useSportObjectVars, Warehouse} from "../../../@core/store/sportObject";
import MenuItem from "@mui/material/MenuItem";

interface Props {

}

const List = styled(Box)(({theme}) => ({}))

const ListItems = styled(Box)(({theme}) => ({}))

const ListHeader = ({hasCategory, onBackButtonClick, children}: any) => {

  return (
    <Typography variant={"h5"} component={'div'}>
      {hasCategory ?
        <ListItem onClick={onBackButtonClick}>{children}</ListItem>
        : children
      }
    </Typography>

  )
}

const ListItem = styled(({active, ...props}: any) => (
  <Button
    variant={'text'}
    fullWidth
    size={'small'}
    disableRipple
    color={active ? 'primary' : 'inherit'}
    style={{
      textAlign: 'left'
    }}
    {...props} />
))(({theme}) => ({
  justifyContent: 'left',
  fontWeight: 'normal',
}))

function ProductsFilters(props: Props) {
  const [searchParams, setSearchParams] = useSearchParams()


  const {data: categoriesData} = useGetSportCategoriesQuery()
  const {data: sportKindsData} = useGetSportKindsQuery()
  const {data: equipmentTypesData} = useGetEquipmentTypesQuery()


  const hasCategory = searchParams.get('categoryId')
  const hasSportKind = searchParams.get('sportKindId')
  const hasEquipmentType = searchParams.get('equipmentTypeId')
  const getCategoryName = () => {

    return categoriesData?.getAllSportCategoryDictionary.find(item => item.id === hasCategory)?.name || 'Категория'
  }

  const getSportKindName = () => {

    return sportKindsData?.getAllSportKindDictionary?.sportKinds.find(item => item.id === hasSportKind)?.name || ''
  }


  function backButtonHandler() {
    if (hasEquipmentType) {
      searchParams.delete('equipmentTypeId')
      searchParams.delete('sportKindId')
      setSearchParams(searchParams)
      return;
    }
    if (hasSportKind) {
      searchParams.delete('sportKindId')
      setSearchParams(searchParams)
      return;
    }
    if (hasCategory) {
      searchParams.delete('categoryId')
      setSearchParams(searchParams)
      return;
    }
  }

  function backFromCategoryHandler() {
    searchParams.delete('equipmentTypeId')
    searchParams.delete('sportKindId')
    searchParams.delete('categoryId')
    setSearchParams(searchParams)
    return;
  }

  const {currentSportObject, eventType, count, selectedSportObjectIds} = useReactiveVar(sportObjectVar)
  const {setEventType, changeSportObject, setSelectedSportObjectIds} = useSportObjectVars()
  const navigate = useNavigate()
  const [sportObjectHovered, setSportObjectHovered] = useState(false);

  const ITEMS: { eventType: EventType, label: string }[] = [
    {eventType: EventType.external, label: 'Внешнее'},
    {eventType: EventType.internal, label: 'В мосспортобъекте'},
  ]

  const [value, setValue] = useState<{ eventType: EventType, label: string }>(ITEMS.find(item => item.eventType === eventType)!);

  useEffect(() => {
    setValue(ITEMS.find(item => item.eventType === eventType)!)
  }, [eventType]);


  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme()


  const [checked, setChecked] = React.useState([true, true]);

  useEffect(() => {
    let sportObjectIds: any[] = []

    if (eventType === EventType.internal) {
      if (checked[0]) {
        sportObjectIds.push(Warehouse.id)
      } else {
        sportObjectIds.filter(id => id !== Warehouse.id)
      }
      if (!currentSportObject?.id) {
        sportObjectIds = checked[0] ? [Warehouse.id] : []
      } else {
        if (checked[1]) {
          sportObjectIds.push(currentSportObject.id)
        } else {
          sportObjectIds.filter(id => id !== currentSportObject.id)
        }
      }
    } else {
      sportObjectIds = checked[1] ? [Warehouse.id] : []
    }

    setSelectedSportObjectIds(sportObjectIds)
  }, [eventType, checked]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([event.target.checked, checked[1]]);
  };

  const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked([checked[0], event.target.checked]);
  };


  return (
    <StickyBox offsetTop={80} offsetBottom={20}>

      <Card
        sx={{mb: 2}}
        variant={'outlined'}
        onMouseEnter={() => setSportObjectHovered(true)}
        onMouseLeave={() => setSportObjectHovered(false)}
      >
        <Box
          sx={{
            mb: 2,
            mt: 1,
            p: 2,
            width: '100%',
            position: 'relative'
          }}

        >
          <ButtonBase
            sx={{
              width: '100%',
              justifyContent: 'start',
              textAlign: 'left',
            }}
            onClick={handleClick}
          >
            <Box sx={{
              py: 1,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: open ? darken(theme.palette.background.default, .03) : theme.palette.background.default,
              position: 'relative',
              borderRadius: 2,
              transition: 'background-color, .3s',
              '&:hover': {
                backgroundColor: darken(theme.palette.background.default, .02),
              },
              px: 4,
            }}>
              <Typography
                variant={'caption'}
                sx={{
                  zIndex: 1,
                  left: 34,
                  top: 4,
                  color: 'text.secondary'
                }}
              >
                Тип мероприятия
              </Typography>
              <Typography sx={{
                textOverflow: 'ellipsis',
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                fontWeight: 'bold',


              }}>
                {value.label}
              </Typography>
            </Box>
          </ButtonBase>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {ITEMS.map(item => (
            <MenuItem key={item.eventType} sx={{
              whiteSpace: 'initial'
            }} onClick={() => {
              setEventType(item.eventType)
              handleClose()
            }}>{item.label}</MenuItem>
          ))}
        </Menu>
        <FormGroup>
          {eventType === EventType.internal &&
            <FormControlLabel
              sx={{alignItems: 'start', px: 3, mb: 1}}
              control={<Box><Checkbox checked={checked[0]} onChange={handleChange}/></Box>}
              label={
                <CardHeader
                  sx={{alignItems: 'start', width: '100%', p: 0, ml: 1}}
                  title={<Typography variant={'h5'} color={'inherit'} sx={{lineHeight: 1.5, marginTop: '10px'}}>
                    {Warehouse?.name}
                  </Typography>}
                />
              }/>}
          <FormControlLabel
            sx={{alignItems: 'start', px: 3}}
            control={<Box><Checkbox checked={checked[1]} onChange={handleChange2}/></Box>}
            label={
              <CardHeader
                sx={{alignItems: 'start', width: '100%', p: 0, ml: 1}}
                title={<Typography variant={'h5'} color={'inherit'} sx={{lineHeight: 1.5, marginTop: '10px'}}>
                  {currentSportObject?.name}
                </Typography>}
                subheader={currentSportObject?.address ? <Typography
                  variant={'body1'}
                  fontSize={'13px'}
                  color={'text.secondary'}
                  sx={{
                    lineHeight: 1, mb: 2
                  }}
                >
                  {currentSportObject?.address ? currentSportObject?.address : 'Адрес не указан'}
                </Typography> : undefined}
              />
            }/>
        </FormGroup>

        <CardContent sx={{p: 3, pt: 0}}>

          {eventType === EventType.internal && <Button
            fullWidth
            color={'inherit'}
            size={'small'}
            sx={{
              mt: 2
            }}
            onClick={changeSportObject}
          >
            Изменить
          </Button>}

          <Typography
            variant={'body1'}
            fontSize={'13px'}
            color={'text.secondary'}
            sx={{lineHeight: 1, mt: 2}}
          >{"Доступное оборудование: "} <b>{selectedSportObjectIds.length > 0 ? count : 0}</b> </Typography>

        </CardContent>


      </Card>

      <Card variant={'outlined'}>
        <List py={1} px={2}>
          <ListHeader
            hasCategory={hasCategory}
            onBackButtonClick={backFromCategoryHandler}
          >
            <Stack
              spacing={1}
              direction={'row'}
              alignItems={'center'}
              overflow={'hidden'}
              px={!hasCategory ? 2 : 0}
              py={!hasCategory ? 1 : 0}
            >
              {hasCategory &&
                <Box>
                  <ArrowBackIosIcon fontSize={'inherit'}/>
                </Box>
              }
              <Tooltiper text={getCategoryName()} props={{
                variant: 'h5',
                sx: {
                  fontSize: 'inherit',
                  fontWeight: 'inherit',
                }
              }}/>
            </Stack>
          </ListHeader>
          {hasSportKind && <ListHeader
            hasCategory={hasSportKind}
            onBackButtonClick={backButtonHandler}
          >
            <Stack
              spacing={1}
              direction={'row'}
              alignItems={'center'}
              overflow={'hidden'}
              px={!hasCategory ? 2 : 0}
              py={!hasCategory ? 1 : 0}
            >
              {hasCategory &&
                <Box>
                  <ArrowBackIosIcon fontSize={'inherit'}/>
                </Box>
              }
              <Tooltiper text={getSportKindName()} props={{
                variant: 'h5',
                sx: {
                  fontSize: 'inherit',
                  fontWeight: 'inherit',
                }
              }}/>
            </Stack>
          </ListHeader>}
          <ListItems>
            {!hasCategory && categoriesData?.getAllSportCategoryDictionary.map((sportCategory) => (
              <ListItem key={sportCategory?.id} onClick={() => {
                searchParams.set("categoryId", sportCategory?.id)
                searchParams.delete("page")

                setSearchParams(searchParams)
              }}
              >
                {sportCategory.name}
              </ListItem>
            ))
            }
            {hasCategory && !hasSportKind && sportKindsData?.getAllSportKindDictionary.sportKinds
              .filter((item) => item?.sportCategoryId === hasCategory)
              .map((sportKind) => (
                <ListItem key={sportKind?.id} onClick={() => {
                  searchParams.set("sportKindId", sportKind?.id)
                  searchParams.delete("page")

                  setSearchParams(searchParams)
                }}
                >
                  {sportKind.name}
                </ListItem>
              ))
            }
            {hasSportKind && equipmentTypesData?.getAllEquipmentTypeDictionary
              .filter((item) => item?.sportKindId === hasSportKind)
              .map((equipmentType) => (
                <ListItem key={equipmentType?.id} onClick={() => {
                  searchParams.set("equipmentTypeId", equipmentType?.id)
                  searchParams.delete("page")

                  setSearchParams(searchParams)
                }}
                          active={searchParams.get('equipmentTypeId') === equipmentType?.id}
                >
                  {equipmentType.name}
                </ListItem>
              ))
            }
          </ListItems>
        </List>
      </Card>
    </StickyBox>
  );
}

export default ProductsFilters;
