import React from 'react';

interface Props {

}

export function FavoritesList(props: Props) {

  return (
    <>FavoritesList</>
  );
}


