import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router";
import {useSearchParams} from "react-router-dom";

const ScrollToTop = (props:any) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation();
  const params = useParams();

  const [prevSearchLocation, setPrevSearchLocation] = useState('');

  useEffect(() => {
    setPrevSearchLocation(location.search)

    !prevSearchLocation.includes('preview') && !searchParams.has('preview') &&  window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>
};

export default ScrollToTop;
