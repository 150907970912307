import {Navigate, Route, Routes} from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import Error404 from "../../../pages/errors/404";
import HomePage from "../../../pages/home";
import LoginPage from "../../../pages/login";
import {MainLayout} from "../../../layouts/MainLayout";
import ProductsPage from "../../../pages/products";
import CheckoutPage from "../../../pages/checkout";
import MossportEventsPage from "../../../pages/mossport-events";

import AccountPage from "../../../pages/account";
import {Account, AccountSettings} from "../../../views/account";

import SubscriptionPage from "../../../pages/subscription";

import ChatPage from "../../../pages/chat";
import {Chat} from "../../../views/chat";

import FavoritesPage from "../../../pages/favorites";
import {FavoritesList, Favorite} from "../../../views/favorites";

import CartPage from "../../../pages/cart";
import {CartItemsList} from "../../../views/cart";

import OrdersPage from "../../../pages/orders";
import {OrdersList, Order} from "../../../views/orders";
import ComponentsSelectionDialog from "../../components/EquipmentSelectionDialog";

function AppRoutes() {

  return (
    <Routes>

      <Route element={<MainLayout/>}>
        <Route path={'/login'} element={<LoginPage/>}/>
      </Route>
      <Route path="/404" element={<Error404/>}/>
      <Route path="*" element={<Navigate to="/404"/>}/>


      <Route element={<PrivateRoute/>}>

        <Route element={<MainLayout/>}>
          <Route index element={<HomePage/>}/>
          <Route path={'/products'} element={<ProductsPage/>}/>
          <Route path={'/checkout'} element={<CheckoutPage/>}/>
          <Route path={'/equipment-selection'} element={<ComponentsSelectionDialog/>}/>

          <Route path="/my" element={<AccountPage/>}>
            <Route index element={<Account />} />
            <Route path="settings/" element={<AccountSettings />} />
            <Route path="subscription/" element={<SubscriptionPage />} />

            <Route path="chat/" element={<ChatPage />} >
              <Route index element={<Chat />} />
              <Route path=":id/" element={<Chat />} />
            </Route>

            <Route path="favorites/" element={<FavoritesPage />} >
              <Route index element={<FavoritesList />} />
              <Route path=":id/" element={<Favorite />} />
            </Route>

            <Route path="cart/" element={<CartPage />} />

            <Route path="orders/" element={<OrdersPage />} >
                <Route index element={<OrdersList />} />
                <Route path=":id/" element={<Order />} />
            </Route>

          </Route>
        </Route>
      </Route>


    </Routes>
  )
}

export default AppRoutes;
