import {useCallback, useEffect, useMemo, useState} from "react";
import Cookies from "js-cookie";

import {client} from "src";
import {
  AuthContext,
  IUserAddresses,
  IUserContacts,
  UserAddressesInput,
  UserContactsInput
} from "src/@core/context/AuthContext";
import {UserEntity} from "src/@core/@graphql/types";
import { faker } from '@faker-js/faker';


function AuthProvider(props: any) {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [user, setUserData] = useState<UserEntity | null>(null);
  const [token, setTokenData] = useState<string | null>(null);
  const [userContacts, setUserContactsData] = useState<IUserContacts[] | null>(null);
  const [userAddresses, setUserAddressData] = useState<IUserAddresses[] | null>(null);


  const setToken = useCallback((tokenData: string | null) => {
    setTokenData(tokenData);

    if (tokenData) {
      Cookies.set("auth-token", tokenData);
    } else {
      Cookies.remove("auth-token");
    }
  }, []);

  const setUser = useCallback((user: UserEntity | null) => {
    setUserData(user);
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }

  }, []);

  const logOut = useCallback(() => {
    setUser(null);
    setToken(null);
    client.clearStore().then(() => client.resetStore())
    localStorage.clear()
  }, [setToken, setUser]);

  const loadData = useCallback(async () => {
    const tokenData = Cookies.get("auth-token");
    const userData = localStorage.getItem("user");
    const userContactsData = localStorage.getItem("user-contacts");
    const userAddressesData = localStorage.getItem("user-addresses");

    try {
      if (tokenData && userData) {
        setTokenData(tokenData!);
        setUserData(JSON.parse(userData!));
      }

    } catch {
      setToken(null);
      setUser(null);
    }

    try {
      if (userContactsData) {
        setUserContactsData(JSON.parse(userContactsData!));
      }
    } catch {
      setUser(null);
    }

    try {
      if (userAddressesData) {
        setUserAddressData(JSON.parse(userAddressesData!));
      }
    } catch {
      setUser(null);
    }
  }, [setToken]);

  useEffect(() => {
    loadData().finally(() => setIsLoaded(true));
  }, [loadData]);

  const setUserAddress = useCallback((newUserAddress:IUserAddresses | null) => {
    if (newUserAddress) {
      setUserAddressData((prevState) => {
        const updateArr = prevState ? [...prevState as IUserAddresses[]] : []
        updateArr.push(newUserAddress)
        localStorage.setItem("user-addresses", JSON.stringify(updateArr));
        return updateArr
      });
    } else {
      setUserAddressData(newUserAddress)
      localStorage.removeItem("user-addresses");
    }
  }, []);

  const setUserContacts = useCallback((newUserContacts:IUserContacts | null) => {
    if (newUserContacts) {
      setUserContactsData((prevState) => {
        const updateArr = prevState ? [...prevState as IUserContacts[]] : []
        updateArr.push(newUserContacts)
        localStorage.setItem("user-contacts", JSON.stringify(updateArr));
        return updateArr
      });
    } else {
      setUserContactsData(newUserContacts)
      localStorage.removeItem("user-contacts");
    }
  }, []);

  const addUserAddress = (input: UserAddressesInput) => {
    const newUserAddress: IUserAddresses = {
      id: faker.datatype.uuid(),
      region: input.region,
      city: input.city,
      street: input.street,
      building: input.building,
      ...(input?.apartment && {apartment: input?.apartment}),
      ...(input?.entrance && {entrance: input?.entrance}),
      ...(input?.floor && {floor: input?.floor}),
      ...(input?.intercom && {intercom: input?.intercom}),
    }
    setUserAddress(newUserAddress)
  }

  const updateUserAddress = (input: IUserAddresses) => {
    setUserAddressData((prevState) => {
      const updateArr = [...prevState as IUserAddresses[]].map(item => {
        if (item.id === input.id) {
          return input
        }
        return item
      } )
      localStorage.setItem("user-addresses", JSON.stringify(updateArr));
      return updateArr
    });
  }

  const addUserContacts = (input: UserContactsInput) => {
    const newUserContacts: IUserContacts = {
      id: faker.datatype.uuid(),
      fio: input.fio,
      phone: input.phone,
      position: input.position,
    }
    setUserContacts(newUserContacts)
  }

  const updateUserContacts = (input: IUserContacts) => {
    setUserContactsData((prevState) => {
      const updateArr = [...prevState as IUserContacts[]].map(item => {
        if (item.id === input.id) {
          return input
        }
        return item
      } )
      localStorage.setItem("user-contacts", JSON.stringify(updateArr));
      return updateArr
    });
  }

  const dropUserAddress = useCallback((id: string) => {
    setUserAddressData((prevState) => {
      const updateArr = [...prevState as IUserAddresses[]].filter(item => item.id !== id)
      localStorage.setItem("user-addresses", JSON.stringify(updateArr));
      return updateArr
    });
  }, [])

  const dropUserContacts = useCallback((id: string) => {
    setUserContactsData((prevState) => {
      const updateArr = [...prevState as IUserContacts[]].filter(item => item.id !== id)
      localStorage.setItem("user-contacts", JSON.stringify(updateArr));
      return updateArr
    });
  }, [])

  const removeUserAddress = (id: string) => {
    dropUserAddress(id)
  }

  const removeUserContacts = (id: string) => {
    dropUserContacts(id)
  }

  const contextValue = useMemo(
    () => ({
      user,
      token,
      setUser,
      setToken,
      logOut,
      isLoaded,

      userAddresses,
      addUserAddress,
      updateUserAddress,
      removeUserAddress,

      userContacts,
      addUserContacts,
      updateUserContacts,
      removeUserContacts,
    }),
    [user, token, setToken, logOut, isLoaded, userAddresses, userContacts]
  );



  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;



