import React, {forwardRef} from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import {styled} from "@mui/material/styles";

const PageLayout = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  flexGrow: 1,
}))

const PageStyle = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
}))


const Page = forwardRef<any, any>(({children, title = 'Московский спорт — Управление оборудованием', ...rest}, ref) => {
  return (
    <PageLayout
      className={'pageLayout'}
    >
      <PageStyle
        ref={ref} {...rest}
        className={'page'}
      >
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </PageStyle>
    </PageLayout>
  );
});

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

export default Page;
