import {Box} from '@mui/material';
import React from 'react';
import Profile from './profile';
import SidebarList from './list';

interface Props {

}

export function AccountSidebar(props: Props) {

  return (
    <Box p={2}>
      <Profile/>

      <SidebarList
        title={'Личный кабинет'}
        links={[
          {title: 'Заказы', link: '/my/orders'},
          {title: 'Сообщения', link: '/my/chat'},
          {title: 'Корзина', link: '/my/cart'},
        ]}
      />

      {/*<SidebarList*/}
      {/*  title={'Подписки'}*/}
      {/*  links={[*/}
      {/*    {title: 'Избранное', link: '/my/favorites'},*/}
      {/*    {title: 'Настройки уведомлений', link: '/my/subscription'},*/}
      {/*  ]}*/}
      {/*/>*/}


    </Box>
  );
}
