import React, {useState} from 'react';
import {Avatar, Link, Typography, Box, Skeleton, Stack} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {stringAvatar} from "../../../../@core/utils/stringAvatar";
import useAuth from "../../../../@core/hooks/useAuth";
import {styled} from "@mui/material/styles";
import Iconify from "../../../../@core/components/Iconify";
import UploaderService from "../../../../@core/API/UplodaService";
import {ImageStoreEntity} from "../../../../@core/@graphql/types";
import AppConfig from "../../../../configs/appConfig";

interface Props {

}

const UploadAvatarButton = styled(Box)(({theme}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: theme.palette.grey['600'],
  width: '100%',
  height: '100%',
  opacity: 0,
  transition: 'all .3s',
  borderRadius: '50%',
  overflow: 'hidden',
  cursor: 'pointer',
  "&.hovered": {
    opacity: 1,
  }
})) as typeof Box

function Profile(props: Props) {
  const {user} = useAuth()
  const [userAvatar, setUserAvatar] = useState<ImageStoreEntity | null>(null);
  const [uploadAvatarButtonHovered, setUploadAvatarButtonHovered] = useState<boolean>(false);
  const uploadFileInput = React.createRef<HTMLInputElement>();
  const clearInput = () => {
    if (!uploadFileInput || !uploadFileInput.current)
      return;

    uploadFileInput.current.value = '';
  };
  const handleChangeImage = (event: any) => {
    UploaderService.uploadImage(event)
      .then((res) =>
        res && setUserAvatar((prevState) => (res as ImageStoreEntity))
      )
  };

  return (
    <Stack width={'100%'} justifyContent={'center'} justifyItems={'center'} alignItems={'center'}
           alignContent={'center'}>
      <Box
        sx={{
          mt: 1,
          mb: 0,
          fontSize: 24,
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '50%'
        }}
      >
        {
          user?.login ? <Avatar
            {...stringAvatar(user.login)}
            sx={{
              width: 112,
              height: 112,
            }}
            {...(AppConfig?.serverUrl && {...(userAvatar && {src: AppConfig.serverUrl + userAvatar.avatarUrl})})}
          /> : <Skeleton variant="circular" width={112} height={112}/>
        }
        <UploadAvatarButton
          className={uploadAvatarButtonHovered ? 'hovered' : ''}
          onMouseEnter={() => setUploadAvatarButtonHovered(true)}
          onMouseLeave={() => setUploadAvatarButtonHovered(false)}
          component={'label'}
          htmlFor="uploadAvatar"
        >
          <Box sx={{
            color: 'white',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            display: 'flex',
          }}>
            <Iconify icon={'ic:outline-add-photo-alternate'} color={'inherit'}/>
          </Box>

          <input
            type="file"
            name={'uploadAvatar'}
            id={'uploadAvatar'}
            hidden
            style={{display: 'none'}}
            accept="image/*, .heic"
            ref={uploadFileInput}
            onChange={handleChangeImage}
          />

        </UploadAvatarButton>
      </Box>

      <Typography mt={2} variant={'h2'} width={'100%'} textAlign={'center'} mb={2}>
        {user?.fio || user?.login}
      </Typography>
      {/*<Link component={RouterLink} mb={2} to={'/my/settings'} sx={{*/}
      {/*  width: '100%',*/}
      {/*  textAlign: 'center',*/}
      {/*  display: 'block'*/}
      {/*}}>*/}
      {/*  Профиль пользователя*/}
      {/*</Link>*/}
    </Stack>
  );
}

export default Profile;
