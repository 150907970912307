import {alpha, Theme} from "@mui/material/styles";


const GlobalStyles = (theme: Theme) => {

  return {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      'input[readonly]': {
        cursor: 'default'
      },
      html: {
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        height: '100%',
        width: '100%',
        '& ::-webkit-scrollbar-thumb': {
          height: 56,
          borderRadius: 8,
          border: '4px solid transparent',
          backgroundClip: 'content-box',
          backgroundColor: alpha(theme.palette.grey["500"], 0.6) ,
          "&:hover": {
            backgroundColor: alpha(theme.palette.grey["500"], 0.8),

          }
        },
        '& ::-webkit-scrollbar': {
          width: 16
        },
      },
      body: {
        backgroundColor: `${theme.palette.background.default} !important`,
        color: `${theme.palette.text.primary} !important`,
        height: '100%',
        width: '100%',
        margin: 0,
        fontFamily: '"Roboto", -apple-system, apple color emoji, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !important',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        fontSize: '16px',
        WebkitTextSizeAdjust: '100%',
        WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
        cursor: 'default',
      },
      a: {
        textDecoration: 'none'
      },
      '#root': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%'
      },
      'input[type="number"]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none'
      },
      'input[type="number"]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none'
      },
      "input[type='number']": {
        appearance: 'none',
        MozAppearance: 'textfield'
      },
      'input[type="number"]:hover': {
        appearance: 'none',
        MozAppearance: 'textfield'
      },
      'input[type="number"]:focus': {
        appearance: 'none',
        MozAppearance: 'textfield'
      },
      'input:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0px 1000px #e0e1f3 inset',
        transition: 'background-color 5000s ease-in-out 0s'
      },
      'input:-webkit-autofill:hover': {
        WebkitBoxShadow: '0 0 0px 1000px #e0e1f3 inset',
        transition: 'background-color 5000s ease-in-out 0s'
      },
      'input:-webkit-autofill:focus': {
        WebkitBoxShadow: '0 0 0px 1000px #e0e1f3 inset',
        transition: 'background-color 5000s ease-in-out 0s'
      },
      'textarea:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0px 1000px #e0e1f3 inset',
        transition: 'background-color 5000s ease-in-out 0s'
      },
      'textarea:-webkit-autofill:hover': {
        WebkitBoxShadow: '0 0 0px 1000px #e0e1f3 inset',
        transition: 'background-color 5000s ease-in-out 0s'
      },
      'textarea:-webkit-autofill:focus': {
        WebkitBoxShadow: '0 0 0px 1000px #e0e1f3 inset',
        transition: 'background-color 5000s ease-in-out 0s'
      },
      'select:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0px 1000px #e0e1f3 inset',
        transition: 'background-color 5000s ease-in-out 0s'
      },
      'select:-webkit-autofill:hover': {
        WebkitBoxShadow: '0 0 0px 1000px #e0e1f3 inset',
        transition: 'background-color 5000s ease-in-out 0s'
      },
      'select:-webkit-autofill:focus': {
        WebkitBoxShadow: '0 0 0px 1000px #e0e1f3 inset',
        transition: 'background-color 5000s ease-in-out 0s'
      },
    '.pswp__bg':{
        backgroundColor: theme.palette.background.paper
    },
    '.pswp__container':{
      width: '90%',
      left: '10%',
    },
    '.pswp__zoom-wrap':{
      left: '-5%',
    },
    '.pswp__icn':{
      fill: theme.palette.text.primary,
      color: theme.palette.background.paper
    },
    '.pswp__counter':{
      textShadow: 'none',
      color: theme.palette.text.primary,
    },
    '[style="position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;"]':{
      display: 'none',
    },

    }

};

export default GlobalStyles;
