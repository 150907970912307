import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {SportEquipmentEntity, useGetEquipmentByIdLazyQuery} from "../../@graphql/types";
import {Gallery, GalleryProps, Item} from "react-photoswipe-gallery";
import {DataSourceArray} from "photoswipe";
import {
  Box,
  Button,
  CardContent, Collapse,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Link,
  Skeleton,
  Stack,
  Typography
} from "@mui/material";
import Slider from "react-slick";
import appConfig from "../../../configs/appConfig";
import {TechnicalCondition} from "../../@graphql/equipment-list/technical-condition.enum";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import useCart from "../../hooks/useCart";
import Iconify from "../Iconify";

interface Props {

}

function ProductPreview(props: Props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [getEquipmentById] = useGetEquipmentByIdLazyQuery()

  const [product, setProduct] = useState<SportEquipmentEntity | null>(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    return () => {
      setProduct(null)
      setLoading(true)
    };
  }, []);


  const previewEquipmentId = searchParams.get('preview')

  useEffect(() => {

    !!previewEquipmentId && getEquipmentById({
      variables: {
        id: previewEquipmentId as string
      }
    }).then((res) => {
      if (res?.data?.getSportEquipmentById) {
        setProduct(res.data.getSportEquipmentById as SportEquipmentEntity)
        setLoading(false)
      }
    })


  }, [previewEquipmentId]);


  const uiElements: GalleryProps['uiElements'] = [
    {
      name: 'bulletsIndicator',
      order: 9,
      isButton: false,
      appendTo: 'wrapper',
      onInit: (el, pswpInstance) => {
        let prevIndex = -1
        const thumbnails: HTMLElement[] = []


        el.style.position = 'absolute'
        // el.style.bottom = '20px'
        el.style.top = '70px'
        el.style.left = '20px'
        // el.style.right = '0'
        el.style.display = 'grid'
        el.style.gridGap = '10px'
        el.style.gridTemplateColumns = 'repeat(auto-fit, 100px)'
        el.style.gridTemplateRows = 'repeat(auto-fit, 100px)'
        el.style.justifyContent = 'center'


        const dataSource = pswpInstance.options.dataSource as DataSourceArray

        for (let i = 0; i < dataSource.length; i++) {
          const slideData = dataSource[i]

          const thumbnail = document.createElement('div')
          thumbnail.style.transition = 'transform 0.15s ease-in'
          thumbnail.style.opacity = '0.6'
          thumbnail.style.cursor = 'pointer'
          thumbnail.onclick = (e: MouseEvent) => {
            const target = e.target as HTMLImageElement | HTMLDivElement
            const thumbnailEl =
              target.tagName === 'IMG'
                ? target.parentElement
                : (e.target as HTMLImageElement | HTMLDivElement)
            pswpInstance.goTo(thumbnails.indexOf(thumbnailEl!))
          }

          const thumbnailImage = document.createElement('img')
          slideData?.msrc && thumbnailImage.setAttribute('src', slideData.msrc)
          thumbnailImage.style.width = '100%'
          thumbnailImage.style.height = '100%'
          thumbnailImage.style.objectFit = 'cover'

          thumbnail.appendChild(thumbnailImage)

          el.appendChild(thumbnail)

          thumbnails.push(thumbnail)
        }

        pswpInstance.on('change', () => {
          if (prevIndex >= 0) {
            const prevThumbnail = thumbnails[prevIndex]
            prevThumbnail.style.opacity = '0.6'
            prevThumbnail.style.cursor = 'pointer'
            // prevThumbnail.style.transform = 'scale(1)'
          }

          const currentThumbnail = thumbnails[pswpInstance.currIndex]
          currentThumbnail.style.opacity = '1'
          currentThumbnail.style.cursor = 'unset'
          // currentThumbnail.style.transform = 'scale(1.2)'

          prevIndex = pswpInstance.currIndex
        })
      },
    },
  ]


  const {addToCart, dropFromCart, checkIsInCart, getButtonLabel} = useCart()

  const cartButtonHandler = (equipment: SportEquipmentEntity) => {
    const isInCart = checkIsInCart(equipment.id)
    if (isInCart) {
      dropFromCart(equipment.id)
    } else {
      addToCart(equipment)
    }
  };

  const hasDocuments = () => {
    return (
      product?.certificate?.[0] ||
      product?.commissioningCertificates?.[0] ||
      product?.guarantee?.[0] ||
      product?.manual?.[0] ||
      product?.passport?.[0] ||
      (product?.otherDocuments?.length && product?.otherDocuments.length > 0)
    ) || null
  };

  const [descriptionChecked, setDescriptionChecked] = useState(false);
  const [failureReasonChecked, setFailureReasonChecked] = useState(false);

  return (
    <>
      <Dialog
        open={!!previewEquipmentId}
        onClose={() => {
          searchParams.delete('preview')
          setSearchParams(searchParams)
          setProduct(null)
          setLoading(true)
        }}
        maxWidth={'md'}

        PaperProps={{
          style: {width: '100%'}
        }}
      >

        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              position: 'relative'
            }}
          >

            <Box
              sx={{
                height: 550,
                overflow: 'hidden',
                '& .slick-prev': {
                  left: 10,
                  zIndex: 1
                },
                '& .slick-next': {
                  right: 10
                },
                '& .slick-dots': {
                  bottom: 10
                },
              }}
            >
              <Gallery
                uiElements={uiElements}
                options={{bgOpacity: 1}}
              >
                <Slider {...{
                  dots: true,
                  infinite: false,
                  speed: 500,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }}>
                  {product?.photos && product?.photos?.length > 0 ? (
                    product?.photos?.map((photo, index) => (
                      <Box
                        key={index}
                      >
                        <Item
                          original={appConfig.serverUrl + photo.url}
                          thumbnail={appConfig.serverUrl + (photo?.avatarUrl || photo.url)}
                          width={photo.width as number}
                          height={photo.height as number}
                          alt={product.name as string}
                        >
                          {({ref, open}) => (
                            <Box
                              position={'relative'}
                              sx={{
                                bgcolor: 'background.default',
                                minHeight: 550,
                                height: '100%',
                                width: '100%',
                              }}
                            >
                              <Box
                                sx={{
                                  top: '0',
                                  left: '0',
                                  bottom: '0',
                                  position: 'absolute',
                                  height: '100%',
                                  width: '100%',
                                  backgroundImage: `url(${appConfig.serverUrl + photo.url})`,
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'center center'
                                }}
                                onDoubleClick={open}
                                ref={ref as React.MutableRefObject<HTMLImageElement>}
                              />
                            </Box>

                          )}
                        </Item>
                      </Box>

                    ))
                  ) : (
                    product?.mainPhoto ? (
                      <Item
                        original={appConfig.serverUrl + product?.mainPhoto.url}
                        thumbnail={appConfig.serverUrl + product?.mainPhoto.url}
                        width={product?.mainPhoto.width as number}
                        height={product?.mainPhoto.height as number}
                        alt={product.name as string}
                      >
                        {({ref, open}) => (
                          <Box
                            sx={{
                              minHeight: 550,
                              height: '100%',
                              width: '100%',
                              backgroundImage: `url(${appConfig.serverUrl as string + product?.mainPhoto?.url as string})`,
                              backgroundSize: 'cover',
                              backgroundRepeat: 'none',
                              backgroundPosition: 'center center'
                            }}
                            ref={ref as React.MutableRefObject<HTMLImageElement>}
                            onClick={open}
                          />
                        )}
                      </Item>

                    ) : (

                      loading ? <Skeleton
                        variant='rectangular'
                        width={'100%'}
                        height={550}
                      /> : <Box
                        sx={{
                          minHeight: 550,
                          height: '100%',
                          width: '100%',
                          backgroundImage: `url(static/sport-pattern.png)`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'none',
                          backgroundPosition: 'center center'
                        }}
                      />
                    )
                  )

                  }
                </Slider>
              </Gallery>

            </Box>


          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Box
              sx={{
                height: 550,
                overflow: 'hidden',
              }}
            >
              <Stack
                direction={'column'}
                height={'100%'}
              >
                {!loading ? (
                  <>
                    <Box
                      position={'sticky'}
                      top={0}
                      p={3}
                      sx={{
                        bgcolor: 'background.paper'
                      }}
                    >
                      <Typography
                        variant={'h2'}
                        color={'text.main'}
                      >
                        {product?.name}
                        {product?.technicalCondition === TechnicalCondition.NOT_WORKING && <Iconify
                          sx={{
                            ml: 2
                          }}
                          icon={'mdi:progress-wrench'}
                          color={'error.main'}
                        />}
                      </Typography>
                      <Box>
                        <Typography
                          variant={'caption'}
                          component={'span'}
                          mr={1}
                          sx={{color: 'text.secondary'}}
                        >
                          Инв. номер:
                        </Typography>
                        <Typography
                          variant={'caption'}
                          component={'span'}
                          sx={{color: 'text.primary'}}
                        >
                          {product?.inventoryNumber}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      px={3}
                      sx={{
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column',
                        overflow: 'hidden'
                      }}
                    >
                      <Box
                        sx={{
                          overflow: 'auto',
                          height: '100%'
                        }}
                      >

                        {product?.technicalCondition && <Box>
                          <Typography
                            variant={'caption'}
                            component={'span'}
                            mr={1}
                            sx={{color: 'text.secondary'}}
                          >
                            Техническое состояние:
                          </Typography>
                          {
                            product?.technicalCondition === TechnicalCondition.WORKING ? (
                              <Typography
                                variant={'caption'}
                                component={'span'}
                                sx={{color: 'text.primary'}}
                              >
                                в рабочем состоянии
                              </Typography>
                            ) : (
                              <Typography
                                variant={'caption'}
                                component={'span'}
                                sx={{color: 'error.main', fontWeigh: 'bold !important'}}
                              >
                                Не исправно!
                              </Typography>
                            )
                          }
                        </Box>}
                        {product?.failureReason && product?.technicalCondition === TechnicalCondition.NOT_WORKING &&
                          <>
                            <Divider/>
                            <Box onClick={() => setFailureReasonChecked(prev => !prev)}>

                              <Typography
                                variant={'caption'}
                                sx={{color: 'error.main', fontWeight: 'bold'}}
                              >
                                Причина неисправности:
                              </Typography>
                              <Collapse
                                in={failureReasonChecked}
                                collapsedSize={40}
                              >
                                <Typography
                                  variant={'caption'}
                                  component={'span'}
                                  sx={{
                                    color: 'text.primary',
                                    display: '-webkit-box',
                                    WebkitLineClamp: !failureReasonChecked ? 2 : 'auto',
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                  }}
                                >
                                  {product?.failureReason}
                                </Typography>
                              </Collapse>
                            </Box>
                          </>
                        }
                        {product?.specifications?.[0]?.valueString &&
                          <>
                            <Divider/>
                            <Box onClick={() => setDescriptionChecked(prev => !prev)}>

                              <Typography
                                variant={'caption'}
                                sx={{color: 'text.secondary', fontWeight: 'bold'}}
                              >
                                Описание:
                              </Typography>
                              <Collapse
                                in={descriptionChecked}
                                collapsedSize={40}
                              >
                                <Typography
                                  variant={'caption'}
                                  component={'span'}
                                  sx={{
                                    color: 'text.primary',
                                    display: '-webkit-box',
                                    WebkitLineClamp: !descriptionChecked ? 2 : 'auto',
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                  }}
                                >
                                  {product?.specifications?.[0]?.valueString}
                                </Typography>
                              </Collapse>
                            </Box>
                          </>
                        }


                        {hasDocuments() &&
                          <>
                            <Divider/>

                            <Typography
                              variant={'caption'}
                              sx={{color: 'text.secondary', fontWeight: 'bold'}}
                            >
                              Документы:
                            </Typography>

                            {product?.passport?.[0] && <Box>
                              <Typography
                                variant={'caption'}
                                component={'span'}
                                mr={1}
                                sx={{color: 'text.secondary'}}
                              >
                                {product?.passport?.[0].description}:
                              </Typography>
                              <Typography
                                variant={'caption'}
                                component={'span'}
                                sx={{color: 'text.primary'}}
                              >
                                {product?.passport?.[0].name}
                              </Typography>
                            </Box>}
                            {product?.certificate?.[0] && <Box>
                              <Typography
                                variant={'caption'}
                                component={'span'}
                                mr={1}
                                sx={{color: 'text.secondary'}}
                              >
                                {product?.certificate?.[0].description}:
                              </Typography>
                              <Typography
                                variant={'caption'}
                                component={'span'}
                                sx={{color: 'text.primary'}}
                              >
                                {product?.certificate?.[0].name}
                              </Typography>
                            </Box>}
                            {product?.commissioningCertificates?.[0] && <Box>
                              <Typography
                                variant={'caption'}
                                component={'span'}
                                mr={1}
                                sx={{color: 'text.secondary'}}
                              >
                                {product?.commissioningCertificates?.[0].description}:
                              </Typography>
                              <Typography
                                variant={'caption'}
                                component={'span'}
                                sx={{color: 'text.primary'}}
                              >
                                {product?.commissioningCertificates?.[0].name}
                              </Typography>
                            </Box>}
                            {product?.guarantee?.[0] && <Box>
                              <Typography
                                variant={'caption'}
                                component={'span'}
                                mr={1}
                                sx={{color: 'text.secondary'}}
                              >
                                {product?.guarantee?.[0].description}:
                              </Typography>
                              <Typography
                                variant={'caption'}
                                component={'span'}
                                sx={{color: 'text.primary'}}
                              >
                                {product?.guarantee?.[0].name}
                              </Typography>
                            </Box>}
                            {product?.manual?.[0] && <Box>
                              <Typography
                                variant={'caption'}
                                component={'span'}
                                mr={1}
                                sx={{color: 'text.secondary'}}
                              >
                                {product?.manual?.[0].description}:
                              </Typography>
                              <Typography
                                variant={'caption'}
                                component={'span'}
                                sx={{color: 'text.primary'}}
                              >
                                {product?.manual?.[0].name}
                              </Typography>
                            </Box>}
                            {product?.otherDocuments?.length && product?.otherDocuments.length > 0 && product?.otherDocuments?.map(item => (
                              <Box key={item.id}>
                                <Typography
                                  variant={'caption'}
                                  component={'span'}
                                  mr={1}
                                  sx={{color: 'text.secondary'}}
                                >
                                  {item.description}
                                </Typography>
                                <Typography
                                  variant={'caption'}
                                  component={'span'}
                                  sx={{color: 'text.primary'}}
                                >
                                  {item.name}
                                </Typography>
                              </Box>
                            ))}
                          </>
                        }
                      </Box>

                    </Box>
                  </>
                ) : (
                  <Box p={3}>
                    <Skeleton
                      variant='text'
                      sx={{fontSize: '1.2rem'}}
                    />
                    <Box mb={2}>
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        width={70}
                        style={{display: 'inline-block'}}
                      />
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        style={{display: 'inline-block', marginLeft: '.5rem'}}
                        width={200}
                      />
                    </Box>

                    <Box>
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        width={90}
                        style={{display: 'inline-block'}}
                      />
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        style={{display: 'inline-block', marginLeft: '.5rem'}}
                        width={180}
                      />
                    </Box>
                    <Box>
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        width={80}
                        style={{display: 'inline-block'}}
                      />
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        style={{display: 'inline-block', marginLeft: '.5rem'}}
                        width={190}
                      />
                    </Box>

                    <Box>
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        width={60}
                        style={{display: 'inline-block'}}
                      />
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        style={{display: 'inline-block', marginLeft: '.5rem'}}
                        width={170}
                      />
                    </Box>


                    <Box>
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        width={85}
                        style={{display: 'inline-block'}}
                      />
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        style={{display: 'inline-block', marginLeft: '.5rem'}}
                        width={250}
                      />
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                      />
                    </Box>
                  </Box>
                )}

                <Stack
                  direction={'row'}
                  spacing={1}
                  p={3}
                  position={'sticky'}
                  bottom={0}
                  sx={{
                    bgcolor: 'background.paper'
                  }}
                >
                  <Stack
                    direction={'column'}
                    spacing={2}
                    flexGrow={0}
                    width={'100%'}
                  >
                    <Button
                      disabled={loading}
                      variant={'contained'}
                      onClick={() => cartButtonHandler(product as SportEquipmentEntity)}
                    >{
                      getButtonLabel(product?.id as string)
                    }</Button>
                  </Stack>
                  <Box>
                    <IconButton
                      disabled={loading}
                      size={'small'}
                    >
                      <FavoriteBorderIcon fontSize={'small'}/>
                    </IconButton>
                  </Box>
                </Stack>

              </Stack>
            </Box>

          </Grid>
        </Grid>

      </Dialog>

    </>
  )
}

export default ProductPreview;
