import {useEffect, useMemo, useState} from "react";
import {CartContext, ProductsGroupedBySportObject} from "src/@core/context/CartContext";
import {
  CartRecordEntity,
  GetCartDocument, NotExistingCartRecordEntity,
  SportEquipmentEntity, SportObjectEntity, SportSubZoneEntity, SportZoneEntity,
  useAddToCartMutation,
  useDropFromCartMutation,
  useGetCartQuery, useRemoveNotExistingEquipmentRecordFromCartMutation,
} from "../../@graphql/types";
import useAuth from "../../hooks/useAuth";


function CartProvider(props: any) {
  const [cartOpen, setCartOpen] = useState(false);
  const [cartItems, setCartItems] = useState<SportEquipmentEntity[]>([]);
  const [notExistingProducts, setNotExistingProducts] = useState<NotExistingCartRecordEntity[]>([]);
  const [cartTotalCount, setCartTotalCount] = useState(0);
  const [notExistingTotalCount, setNotExistingTotalCount] = useState(0);
  const {user} = useAuth()

  const {data: queryCartData, error: queryCartError, loading: queryCartLoading, refetch} = useGetCartQuery({
    skip: !user
  })

  useEffect(() => {
    if (user && !queryCartData) {
      refetch()
    }
  }, [user]);


  const [addToCartMutation] = useAddToCartMutation()
  const [dropFromCartMutation] = useDropFromCartMutation()

  const [selectedProducts, setSelectedProducts] = useState<SportEquipmentEntity[]>([]);

  const dropFromCart = async (id: string) => {
    const records = queryCartData?.getCart?.records
    const recordId = records?.find((item: any) => item?.equipment?.id === id)?.id
    if (recordId) {
      dropFromCartMutation({
        variables: {
          input: {
            cartRecordId: recordId,
            equipmentCount: 1
          }
        },
        refetchQueries: [
          {query: GetCartDocument}
        ]
      })
    }
  }

  const addToCart = async (equipment: SportEquipmentEntity) => {
    addToCartMutation({
      variables: {
        input: {
          equipmentId: equipment.id,
          equipmentCount: 1
        }
      },
      refetchQueries: [
        {query: GetCartDocument}
      ]
    })
  }


  useMemo(() => {

      const records = queryCartData?.getCart?.records
      const notExistingEquipmentRecord = queryCartData?.getCart?.notExistingEquipmentRecords
      setCartItems(records ? records?.map(item => item.equipment as SportEquipmentEntity) as SportEquipmentEntity[] : []);
      setCartTotalCount(records ? records?.reduce((acc, obj) => {
        return acc + obj.equipmentCount;
      }, 0) as number : 0);
      setNotExistingProducts(notExistingEquipmentRecord ? notExistingEquipmentRecord as NotExistingCartRecordEntity[] : []);
    setNotExistingTotalCount(notExistingEquipmentRecord ? notExistingEquipmentRecord?.reduce((acc, obj) => {
      return acc + obj.equipmentCount;
    }, 0) as number : 0);


    return () => {
      return setCartItems([]);
    }
  }, [queryCartData]);

  const checkIsInCart = (id: string) => {
    return cartItems.length > 0 && !!(cartItems?.find(item => item?.id === id))
  };

  const getButtonLabel = (id: string) => checkIsInCart(id) ? 'Убрать' : 'В корзину';

  const productsGroupedBySportObjects: ProductsGroupedBySportObject[] = useMemo(() => {

    const groupBy = (arr: any[], criteria: any) => {
      return arr.reduce((obj, item) => {
        const key = typeof criteria === 'function' ? criteria(item) : item[criteria];

        if (!obj.hasOwnProperty(key)) {
          obj[key] = [];
        }

        obj[key].push(item);
        return obj;

      }, {});
    };

    const groupedBySportObject: SportEquipmentEntity[][] = Object.values(groupBy(cartItems, (item: SportEquipmentEntity) =>
      item.sportObject?.id
    ))



    const res: ProductsGroupedBySportObject[] = [...groupedBySportObject].map((items) => {
      return {
        sportObject: items[0].sportObject as SportObjectEntity,
        sportZones: Object.values(groupBy(items, (item: SportEquipmentEntity) =>
          item.sportZone?.id
        )).map((items: any ) => ({
          sportZone: items[0].sportZone as SportZoneEntity,
          sportSubZones: Object.values(groupBy(items, (item: SportEquipmentEntity) =>
            item.sportSubZone?.id
          )).map((item:any) => ({
            sportSubZone: items[0].sportSubZone as SportSubZoneEntity,
            products: items.map((item:any) => ({
              product: item
            }))
          }))
        }))
      }
    })
    return res

  }, [cartItems]);


  const [removeNotExistingCartRecord] = useRemoveNotExistingEquipmentRecordFromCartMutation()
  const cartClear = (cartItems:any) => {
    cartItems.forEach((item:any) => {
      dropFromCart(item.id)
    })
    notExistingProducts.forEach((item:any) => {
      removeNotExistingCartRecord({
        variables: {
          input:{
            cartRecordId: item.id,
            equipmentCount: item.equipmentCount
          }
        }
      })
    })
  };

  const contextValue = useMemo(
    () => ({
      addToCart,
      dropFromCart,
      cartItems,
      cartClear,
      cartTotalCount,
      cartOpen,
      setCartOpen,
      checkIsInCart,
      getButtonLabel,
      productsGroupedBySportObjects,
      selectedProducts,
      setSelectedProducts,
      notExistingProducts,
      notExistingTotalCount
    }),
    [
      cartOpen,
      cartItems,
      cartTotalCount,
      selectedProducts,
      notExistingProducts,
      notExistingTotalCount
    ]
  );

  return (
    <CartContext.Provider value={contextValue}>
      {props.children}
    </CartContext.Provider>
  );
}

export default CartProvider;



