import React, {useState} from 'react';
import {AppBar, Box, Button, Container, Stack, Toolbar, Tooltip} from '@mui/material';
import {useTheme} from "@mui/styles";
import Brand from './Brand';
import Search from "./Search";
import Navigate from "./Navigate";
import UserPanel from "./UserPanel";
import WishPanel from "./WishPanel";
import BasketPanel from "./BasketPanel";
import {styled} from "@mui/material/styles";
import {mainNavItems} from "../../../navigation";
import useCart from "../../hooks/useCart";
import useWishlist from "../../hooks/useWishlist";
import RequestsPanel from "./RequestsPanel";
import {Link, useNavigate} from "react-router-dom";
import {useLocation} from "react-router";
import useNotExistentEquipment from "../../hooks/useNotExistentEquipment";
import Tooltiper from "../Tooltiper";


const HeaderStyle = styled(AppBar)(({theme}) => ({
  top: 0,
  height: 64,
  borderBottom: `1px solid ${theme.palette.divider}`,
  background: theme.palette.background.paper
})) as typeof AppBar

interface HeaderLayoutProps {
  className?: string

  [key: string]: any
}


const EquipmentSelection = () => {
  const {setDialogOpen} = useNotExistentEquipment()

  return (
    <Tooltip
      title={'Не нашли нужный товар? Подаём заявку на подбор!'}
    >
      <Button
        variant={'outlined'}
        color={'secondary'}
        size={'small'}
        sx={{
          whiteSpace: 'nowrap',
          minWidth: 'initial',
        }}
        onClick={() => {
          setDialogOpen(true)
        }}
      >
        Новая заявка
      </Button>

    </Tooltip>
  )
};

const HeaderLayout = ({className, ...rest}: HeaderLayoutProps) => {
  const theme = useTheme();
  const [groupActive, setGroupActive] = useState<string[]>([])
  const [currentActiveGroup, setCurrentActiveGroup] = useState<string[]>([])

  const {cartTotalCount, notExistingTotalCount} = useCart()
  const {wishlistTotalCount} = useWishlist()


  return (
    <HeaderStyle
      className={className}
      elevation={0}
      color={'inherit'}
      position={'sticky'}
      {...rest}
    >
      <Container maxWidth={'xl'}>
        <Toolbar
          disableGutters
          sx={{
            display: 'flex'
          }}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={3}
            flexGrow={1}
          >
            <Brand/>
            <Navigate
              navItems={mainNavItems()}
              groupActive={groupActive}
              setGroupActive={setGroupActive}
              currentActiveGroup={currentActiveGroup}
              setCurrentActiveGroup={setCurrentActiveGroup}
            />
            <Search/>
            <EquipmentSelection/>
          </Stack>

          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={2}
          >
            <UserPanel/>
            <RequestsPanel count={wishlistTotalCount}/>
            {/*<WishPanel count={wishlistTotalCount}/>*/}
            <BasketPanel count={cartTotalCount + notExistingTotalCount}/>
          </Stack>
        </Toolbar>
      </Container>
    </HeaderStyle>
  );
};

export default HeaderLayout;
