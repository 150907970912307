import React from 'react';
import ProductPreview from "../../components/product-preview";


interface Props {

}

function PreviewProvider(props: any) {

  return (
    <>
      <ProductPreview/>

      { props.children }
    </>
  )
    ;
}

export default PreviewProvider;
