import {makeVar} from "@apollo/client";
import {SportObjectEntity} from "../@graphql/types";

export const Warehouse: SportObjectEntity = {
  id: "80d3d122-58dd-4e2c-b513-3413e79e1e10",
  name: "Склад МСО",
  address: "",
  activeDirectoryGroup: "",
  sportZones: [{
    id: "e0a8ecd7-4913-42ed-becc-896fc7d5f10c",
    name: "Склад",
    address: "Рязанский проспект, 8Ас24",
    createdAt: "1649028109335",
    __typename: "SportZoneEntity"
  }],
  sportSubZones: [{
    id: "eaa1dff3-7761-403f-b202-9669d8c8414e",
    name: "Склад",
    eps: 1,
    area: 100,
    createdAt: "1649028138946",
    __typename: "SportSubZoneEntity"
  }],
  createdAt: "1649027285313",
  __typename: "SportObjectEntity"
} as SportObjectEntity

export enum EventType {
  external = 'external',
  internal = 'internal'
}

interface SportObjectVar {
  currentSportObject: SportObjectEntity | null,
  prevSportObject: SportObjectEntity | null,
  eventType: EventType,
  count: number,
  selectedSportObjectIds: string[],
}

//----------------------------------------------------------------



const initialSportObject: SportObjectVar = {
  currentSportObject: Warehouse,
  eventType: EventType.external,
  prevSportObject: null,
  count: 0,
  selectedSportObjectIds: [Warehouse.id],
}


export const sportObjectVar = makeVar(initialSportObject);

export const useSportObjectVars = () => {

  const setCurrentSportObject = (newSportObject: SportObjectEntity) => {
    const sportObject = sportObjectVar()

    sportObjectVar({
      ...sportObject,
      currentSportObject: newSportObject
    })
  }

  const setEventType = (newEventType: EventType) => {
    const sportObject = sportObjectVar()

    if (newEventType === EventType.internal) {
      sportObjectVar({
        ...sportObject,
        eventType: EventType.internal,
        ...((sportObject.currentSportObject?.id === Warehouse.id && !sportObject.prevSportObject?.id) || sportObject.prevSportObject?.id === Warehouse.id   ? {currentSportObject: null} : {currentSportObject: sportObject.prevSportObject} )
      })
    }

    if (newEventType === EventType.external) {
      sportObjectVar({
        ...sportObject,
        eventType: EventType.external,
        currentSportObject: Warehouse,
        ...(sportObject.prevSportObject?.id !== Warehouse.id && {prevSportObject: sportObject.currentSportObject})
      })
    }
  }

  const changeSportObject = () => {
    const sportObject = sportObjectVar()

    sportObjectVar({
      ...sportObject,
      currentSportObject: null,
      ...(sportObject.prevSportObject?.id !== Warehouse.id && {prevSportObject: sportObject.currentSportObject})
    })
  }
  const cancelChangeSportObject = () => {
    const sportObject = sportObjectVar()

    sportObjectVar({
      ...sportObject,
      eventType: sportObject?.prevSportObject ? EventType.internal : EventType.external,
      currentSportObject: sportObject?.prevSportObject ? sportObject?.prevSportObject : Warehouse,
    })
  }
  const setCount = (count: number) => {
    const sportObject = sportObjectVar()

    sportObjectVar({
      ...sportObject,
      count: count,
    })
  }
  const setSelectedSportObjectIds = (selected: string[]) => {
    const sportObject = sportObjectVar()

    sportObjectVar({
      ...sportObject,
      selectedSportObjectIds: selected,
    })
  }


  return {
    setCurrentSportObject,
    setEventType,
    changeSportObject,
    cancelChangeSportObject,
    setCount,
    setSelectedSportObjectIds,
  }
};
