import type {MouseEventHandler} from 'react'
import {useCallback, useEffect, useState} from 'react'
import StarIcon from '@mui/icons-material/Star';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
// ** MUI Imports
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import {useSearchParams} from "react-router-dom";
import {Maybe} from "graphql/jsutils/Maybe";
import {ImageStoreEntity} from "../../../@graphql/types";
import appConfig from "../../../../configs/appConfig";
import {alpha} from "@mui/material/styles";
import {Box, CardActions, Chip, Divider, IconButton, Stack, Tooltip, useTheme} from "@mui/material";

interface Props {
  images?: Maybe<ImageStoreEntity[] | undefined>,
  title?: Maybe<string>,
  categoryLabel?: Maybe<string>,
  sportKindLabel?: Maybe<string>,
  equipmentTypeLabel?: Maybe<string>,
  description?: Maybe<string>,
  inventoryNumber?: Maybe<string>,
  rating?: Maybe<string>,
  reviews?: Maybe<string>,
  available?: Maybe<boolean>,
  view?: 'grid' | 'list',
  onPreviewClick?: MouseEventHandler<HTMLElement>
  onButtonCartClick?: MouseEventHandler<HTMLElement>
  sportObjectLabel?: Maybe<string>
  sportObjectAddressLabel?: Maybe<string>
  buttonLabel?: Maybe<string>
  productId: string
  onButtonFavoriteClick?: MouseEventHandler<HTMLElement>
  isFavorite?: Maybe<boolean>
}

const CategoryChips = ({children, ...props}: { children: string, [key: string]: any }) => {


  return (
    <Box display={'block'} mr={1} mb={1} width={'100%'}>

      <Chip label={children} size={'small'} sx={{
        mr: 1, mb: 1
      }} {...props}/>

    </Box>
  )
}

function ProductCategory({children, ...props}: { children: any, [key: string]: any }) {
  return (
    <>
      <CategoryChips {...props} >{children}</CategoryChips>
    </>
  );
}

function ImagesHoverCarousel(props: { images: ImageStoreEntity[], productId: string }) {
  const {productId} = props
  const [searchParams, setSearchParams] = useSearchParams()

  const IMAGE_URL = 'static/sport-pattern.png'
  const [images, setImages] = useState<ImageStoreEntity[]>([]);
  const [imageUrl, setImageUrl] = useState(IMAGE_URL);
  const [hoverIndex, setHoverIndex] = useState(0);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (props.images.length > 0) {
      setImageUrl(appConfig.serverUrl + props.images[0].url)
      setImages([...props.images].slice(0, 10))
    }
  }, [props.images]);


  return (
    <Box px={2}>
      <Box
        position={'relative'}
        sx={{
          overflow: 'hidden'
        }}
        onMouseEnter={() => {
          setHovered(true)
        }}
        onMouseLeave={() => {
          setHovered(false)
        }}
      >


        <CardMedia
          sx={{height: 250, bgcolor: 'grey.100', borderRadius: 1}}
          image={imageUrl}
        />

        <Stack className={'hoverBoxes'} height={'100%'} width={'100%'} direction={'row'} position={'absolute'}
               top={'0'}>
          {images.map((image, index) => (
            <Box
              className={'hoverBox'}
              key={index}
              sx={{width: `${100 / images.length}%`, height: '100%',}}
              onMouseEnter={() => {
                setImageUrl(appConfig.serverUrl + image.url || IMAGE_URL)
                setHoverIndex(index)
              }}
            />
          ))}
        </Stack>
        <Box position={'absolute'} width={'100%'} height={'100%'} top={0} sx={{
          opacity: hovered ? 1 : 0,
          transition: 'opacity .3s',
          pointerEvents: 'none',
        }}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'100%'} width={'100%'}>
            <Box>
              <Button
                variant={'contained'}
                color={'primary'}
                size={'small'}
                sx={{
                  pointerEvents: 'auto'
                }}
                onClick={() => {
                  searchParams.set('preview', productId)
                  setSearchParams(searchParams)
                }}
              >
                Быстрый просмотр
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Stack
        className={'thumbnails'}
        direction={'row'}
        spacing={.5}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          pt: 1
        }}
      >
        {images.length > 1 && images.map((image, index) => (
          <Box
            className={'thumbnail'}
            key={index}
            sx={{
              width: 5,
              height: 5,
              bgcolor: hoverIndex === index ? 'primary.main' : 'grey.500',
              borderRadius: '100%',
              cursor: 'pointer'
            }}
            onClick={() => {
              setImageUrl(appConfig.serverUrl + image.url || IMAGE_URL)
              setHoverIndex(index)
            }}
          />
        ))}
      </Stack>
    </Box>
  );
}

const ProductCard = (props: Props) => {
  const {
    images,
    categoryLabel,
    title,
    description,
    inventoryNumber,
    rating,
    reviews,
    available = true,
    view,
    onPreviewClick,
    onButtonCartClick,
    sportKindLabel,
    equipmentTypeLabel,
    sportObjectLabel,
    sportObjectAddressLabel,
    buttonLabel,
    productId,
    onButtonFavoriteClick,
    isFavorite
  } = props

  const theme = useTheme()

  return (
    <Card elevation={0} variant={'outlined'} sx={{
      opacity: !available ? .5 : 1,
      height: '100%',
      display: 'flex',
      flexDirection: "column"
    }}>
      <Box p={2} position={'relative'}>
        <Stack
          direction={'row'}
          display={'flex'}
          alignContent={'flex-start'}
          width={'80%'}
        >
          {/*{(equipmentTypeLabel) &&*/}
          {/*  <Chip*/}
          {/*    label={equipmentTypeLabel}*/}
          {/*    sx={{*/}
          {/*      bgcolor: alpha(theme.palette.secondary.main, .05),*/}
          {/*      py: 0,*/}
          {/*      px: .2,*/}
          {/*      borderRadius: 1,*/}
          {/*      fontSize: '12px',*/}
          {/*      color: 'secondary.main',*/}
          {/*      fontWeight: 'bold',*/}
          {/*      mr: 1,*/}
          {/*      flexGrow: 1,*/}
          {/*      flexShrink: 1,*/}
          {/*      flexBasis: 0,*/}
          {/*      overflow: 'auto'*/}
          {/*    }}*/}
          {/*  />*/}
          {/*}*/}
          {/*<Box*/}
          {/*  sx={{*/}
          {/*    bgcolor: alpha(theme.palette.info.main, .05),*/}
          {/*    display: 'inline-flex',*/}
          {/*    py: 0,*/}
          {/*    px: 1,*/}
          {/*    borderRadius: 1,*/}
          {/*    fontSize: '12px',*/}
          {/*    color: 'info.main',*/}
          {/*    alignItems: 'center',*/}
          {/*    flexGrow: 0,*/}
          {/*    flexShrink: 0,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <StarIcon fontSize={'inherit'} sx={{mr: .5}}/> <b>4.8</b> <Box component={'span'} ml={1}>(2)</Box>*/}
          {/*</Box>*/}
        </Stack>

        <Box ml={'auto'} top={theme.spacing(2)}>
          <IconButton size={'small'} onClick={onButtonFavoriteClick}>
            {isFavorite ?
              <FavoriteIcon fontSize={'small'} color={'primary'}/>
              :
              <FavoriteBorderIcon fontSize={'small'} color={'inherit'}/>
            }

          </IconButton>
        </Box>
      </Box>
      <ImagesHoverCarousel
        images={images || []}
        productId={productId}
      />


      <CardContent sx={{
        flex: '1 0 auto'
      }}
      >
        <Typography variant='h6' sx={{marginBottom: 2, lineHeight: 1.5}}>
          {title}
        </Typography>

        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Typography variant={'caption'} color={'text.secondary'}> Инв. номер: </Typography><Typography
          variant={'body2'}><b>{inventoryNumber}</b></Typography>
        </Stack>

        <Typography variant='body2'>
          {description}
        </Typography>


        {(categoryLabel || equipmentTypeLabel || sportKindLabel) && <Divider sx={{my: 3}}/>}

        {(categoryLabel) &&
          <Box mb={2}>
            <Typography variant={'caption'} color={'text.secondary'} whiteSpace={'nowrap'}>Категория
              спорта: {" "}</Typography>
            <Typography display={'inline'} variant={'body2'}>{categoryLabel}</Typography>
          </Box>
        }

        {(equipmentTypeLabel) &&
          <Box mb={2}>
            <Typography display={'inline'} variant={'caption'} color={'text.secondary'} whiteSpace={'nowrap'}>Тип
              оборудования: {" "}</Typography>
            <Typography display={'inline'} variant={'body2'}>{equipmentTypeLabel}</Typography>
          </Box>
        }

        {(sportKindLabel) &&
          <Box>
            <Typography display={'inline'} variant={'caption'} color={'text.secondary'} whiteSpace={'nowrap'}>Вид спорта: {" "}</Typography>
            <Typography display={'inline'} variant={'body2'}>{sportKindLabel}</Typography>
          </Box>
        }


        {/*{sportObjectLabel && <Typography variant='body2'>*/}
        {/*  {sportObjectLabel}*/}
        {/*</Typography>}*/}
      </CardContent>
      <CardActions sx={{
        flex: '0 0 auto'
      }}>

        <Button variant='contained' size={'small'} onClick={onButtonCartClick}>
          {buttonLabel}
        </Button>
      </CardActions>
    </Card>
  )
}

export default ProductCard
