import {styled} from "@mui/material/styles";
import {toggleButtonClasses, ToggleButtonGroup as MuiToggleButtonGroup} from "@mui/material";

export const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  flexWrap: 'wrap',
  '& .MuiToggleButtonGroup-grouped': {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:last-of-type': {
      marginRight: 0,
    },
    [`&.${toggleButtonClasses.root}`]:{
      border: `1px solid ${theme.palette.divider}`,
      textTransform: 'none',
      maxWidth: '250px'
    }
  },
}));
