export {}

Storage.prototype.set = function(key:string, value:any) {
  value = typeof value === 'object' ? JSON.stringify(value) : value
  this.setItem(key, value);
}

Storage.prototype.get = function(key:string) {
  const value = this.getItem(key);
  return value && typeof value === 'object' ? JSON.parse(value) : value;
}
