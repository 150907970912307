import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
  useRef, ReactNode
} from "react";
import {Box, ButtonBase, Dialog, DialogProps, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'

export type EpicDialogProps = DialogProps & {
  title?: string;
};

type DialogContainerProps = EpicDialogProps & {
  closeDialog: (data?: any) => void;
  confirmation?: boolean;
};

function DialogTitleWithCloseButton(props: { onClose: (data?: any) => void, children: ReactNode }) {
  const {children, onClose, ...other} = props;
  return (
    <Box className="Dialog-title" display={'flex'} justifyContent={'space-between'}>
      <Box className="Dialog-title_text">
        {children}
      </Box>
      {onClose ? (
        <Box className="Dialog-title_close_button">
          <ButtonBase
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon fontSize={'small'}/>
          </ButtonBase>
        </Box>
      ) : null}
    </Box>
  );
}

export function DialogContainer({
                                  children,
                                  title,
                                  closeDialog,
                                  confirmation,
                                  ...props
                                }: PropsWithChildren<DialogContainerProps>) {
  return (
    <Dialog onClose={() => !confirmation ? closeDialog() : undefined} {...props}>
      {title ? (
        <DialogTitleWithCloseButton onClose={closeDialog}>
          {title}
        </DialogTitleWithCloseButton>
      ) : null}
      {children}
    </Dialog>
  );
}


type ProviderContext = readonly [
  (option: EpicDialogOption) => void,
  () => void
];

const DialogContext = createContext<ProviderContext>(
  (null as unknown) as ProviderContext
);

export type EpicDialogOption = Omit<EpicDialogProps, "open">;

export function DialogProvider({children}: PropsWithChildren<{}>) {
  const [dialogs, setDialogs] = useState<EpicDialogProps[]>([]);
  const createDialog = (option: EpicDialogOption) => {
    const dialog = {...option, open: true};
    setDialogs((dialogs) => [...dialogs, dialog]);
  };
  const closeDialog = () => {
    setDialogs((dialogs) => {
      dialogs.pop();
      return [...dialogs];
    });
  };

  const contextValue = useRef([createDialog, closeDialog] as const);
  return (
    <DialogContext.Provider value={contextValue.current}>
      {children}
      {dialogs.map((dialog, i) => {
        const {children: dialogChildren, ...dialogParams} = dialog;
        return (
          <DialogContainer key={i} closeDialog={closeDialog} {...dialogParams}>
            {dialogChildren}
          </DialogContainer>
        );
      })}
    </DialogContext.Provider>
  );
}

export function useDialog(): ProviderContext {
  const result = useContext(DialogContext);
  if (!result) {
    throw new Error("Контекст диалога доступен только внутри его провайдера");
  }
  return result;
}
