import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import {ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache} from "@apollo/client";
import {onError} from "@apollo/client/link/error";
import {setContext} from '@apollo/client/link/context';
// import AuthProvider from "./providers/AuthProvider";
import appConfig from "./configs/appConfig";
import Cookies from "js-cookie";
import AuthProvider from "./@core/providers/AuthProvider";
import ReactDOM from 'react-dom';

require('../src/@core/utils/StorageFunctions')

// ----------------------------------------------------------------------

const httpLink = createHttpLink({
  uri: `${appConfig.serverUrl}/graphql`,
});

const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({extensions, message, locations, path}) => {

        if (extensions.code === 'UNAUTHENTICATED') {
          const token = Cookies.get('auth-token');
          if (token) {
            Cookies.remove("auth-token");
            window.location.reload()
          }
          console.error(extensions.code)
        }

        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations!)}, Path: ${path}`,
        )
      },
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, {headers}) => {
  // get the authentication token from local storage if it exists
  const authToken = Cookies.get("auth-token");

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: authToken ? `Bearer ${authToken}` : "",
    }
  }
});


export const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
});


const container = document.getElementById('root');

if (container){
  ReactDOM.render(
    <HelmetProvider>
      <AuthProvider>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <App/>
          </BrowserRouter>
        </ApolloProvider>
      </AuthProvider>
    </HelmetProvider>, container
  );
}


// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
