import React from 'react';
import {Box, Paper} from "@mui/material";
import {ChatList, Messages} from "../messages";
import {useParams} from "react-router";
import {styled} from "@mui/material/styles";


const ChatWrapper = styled(Box)({
  display: 'flex',
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const ChatListContainer = styled(Paper)({
  height: '100%',
  width: '30%',
  position: 'relative',
});


const ChatContainer = styled(Box)({
  height: '100%',
  width: '70%',
  overflow: 'hidden',
});

const Root = styled(Box)({
  height: '100%',
  width: '100%',
  position: 'relative',
});


export const Chat = () => {
  const {id} = useParams()

  return (
    <Root>
      <ChatWrapper>

        <ChatListContainer>
          <ChatList/>
        </ChatListContainer>

        <ChatContainer>
          <Messages chatId={id}/>
        </ChatContainer>

      </ChatWrapper>
    </Root>
  );
};

