import {ExternalOrderMessageEntity} from "../../@core/@graphql/types";
import useAuth from "../../@core/hooks/useAuth";
import {Avatar, Box, Typography, useTheme} from "@mui/material";
import dayjs from "dayjs";
import {stringAvatar} from "../../@core/utils/stringAvatar";
import {lighten, styled} from "@mui/material/styles";
import {getExternalOrderStatusLocalName} from "./chat-list/chat-list-item";
import {ExternalOrderStatusEnum} from "../../@core/@graphql/external-order/external-order-status.enum";
import React, {FC} from "react";


interface MessageProps {
  message: ExternalOrderMessageEntity
}

export const Message: FC<MessageProps> = (props) => {
  const {
    message: {
      externalOrderStatus, // ExternalOrderStatusEnum: 'NEW' | 'IN_PROGRESS' | 'DONE' | 'CANCELED'
      createdAt,  // дата создания сообщения
      message, // текст сообщения
      user: {
        login: messageUserLogin, // логин отправителя
        id: messageUserId, // id отправителя
      }
    }
  } = props;

  const {user} = useAuth();
  const own = messageUserId === user?.id;

  return (
    <>
      <MessageMeta className='MessageMeta'>
        {dayjs(Number(createdAt)).format('DD MMM YYYY, HH:mm')}
      </MessageMeta>

      <MessageContainer
        isOwn={own}
        className='MessageContainer'
      >

        <AvatarContainer
          isOwn={own}
          className='AvatarContainer'
        >
          <Avatar
            {...stringAvatar(messageUserLogin as string)}
            sx={{width: 30, height: 30,}}
            className='avatar'
          />
        </AvatarContainer>

        <MessageContent
          isOwn={own}
          className='MessageContent'
        >

          <MessageAuthor
            variant={'subtitle1'}
            className='MessageAuthor'
          >
            {messageUserLogin}
          </MessageAuthor>

          <MessageStatus
            isOwn={own}
            className='MessageStatus'
          >
            <MessageStatusText className='MessageStatusText'>
              {getExternalOrderStatusLocalName(externalOrderStatus as ExternalOrderStatusEnum)}
            </MessageStatusText>
          </MessageStatus>

          <MessageText
            variant={'body1'}
            className='MessageText'
            sx={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
            }}
          >
            {message}
          </MessageText>
        </MessageContent>
      </MessageContainer>
    </>
  );
};


const MessageMeta = styled(Typography)(({theme}) => ({
  textAlign: 'center',
  display: 'block',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(2),
})) as typeof Typography

const MessageContainer = styled(Box)<{ isOwn?: boolean }>(({theme, isOwn}) => ({
  display: 'flex',
  alignItems: 'flex-end',
  marginBottom: theme.spacing(2),
  ...isOwn && {
    flexDirection: 'row-reverse',
  }
}))

const AvatarContainer = styled(Box)<{ isOwn?: boolean }>(({isOwn, theme}) => ({
  marginRight: !isOwn ? theme.spacing(1) : 0,
  marginLeft: isOwn ? theme.spacing(1) : 0,
}))

const MessageContent = styled(Box)<{ isOwn?: boolean }>(({theme, isOwn}) => ({
  boxShadow: theme.shadows[1],
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(2, 3),
  backgroundColor: !isOwn ? theme.palette.background.paper : theme.palette.secondary.light,
  color: !isOwn ? theme.palette.text.primary : theme.palette.common.white,
  maxWidth: 500,
  marginLeft: !isOwn ? 0 : 'auto',
  marginRight: isOwn ? 0 : 'auto',
}))

const MessageAuthor = styled(Typography)(() => ({
  color: 'inherit',
})) as typeof Typography

const MessageStatus = styled(Box)<{ isOwn?: boolean }>(({theme, isOwn}) => ({
  display: 'inline-flex',
  backgroundColor: !isOwn ? theme.palette.background.default : lighten(theme.palette.secondary.light, .2),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(.5, 2),
  color: 'inherit',
  fontSize: theme.typography.caption.fontSize,
}))

const MessageStatusText = styled(Typography)(({theme}) => ({
  fontSize: 'inherit',
  color: 'inherit',
  fontWeight: theme.typography.fontWeightBold,
})) as typeof Typography


const MessageText = styled(Typography)(({theme}) => ({
  color: 'inherit',
  marginTop: theme.spacing(2),
})) as typeof Typography
