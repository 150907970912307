import React from 'react';
//@ts-ignore
import { ReactComponent as LogoComponent } from "../../assets/mossport_logo.svg";

const Logo = (props: any) => {
  return (
    <LogoComponent
      {...props}
    />
  );
};

export default Logo;
