import React from 'react';
import Page from "../../@core/components/Page";
import useAuth from "../../@core/hooks/useAuth";
import {Outlet} from "react-router-dom";
import {Card, Container, Grid} from "@mui/material";
import {AccountSidebar} from "../../views/account";
import StickyBox from "react-sticky-box";

interface Props {

}

function AccountPage(props: Props) {
  const {user} = useAuth()
  return (
    <Page title={user?.fio || 'Личный кабинет — Московский Спорт'}>
      <Container maxWidth={'xl'} sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        mb: 4
      }}>
        <Grid container spacing={3} mt={1}
              sx={{
                height: '100%',
                flexGrow: 1
              }}
        >
          <Grid item lg={2}>
            <StickyBox offsetTop={80} offsetBottom={20}>
              <Card>
                <AccountSidebar/>
              </Card>
            </StickyBox>
          </Grid>
          <Grid item lg={10}>
            <Card sx={{
              height: '100%'
            }}>
              <Outlet/>
            </Card>
          </Grid>
        </Grid>
      </Container>

    </Page>
  );
}

export default AccountPage;
