import axios, {AxiosRequestConfig} from "axios";
import Cookies from "js-cookie";
import React from "react";
import {FileStoreEntity, ImageStoreEntity} from "../@graphql/types";
import AppConfig from "../../configs/appConfig";

export default class UploaderService {
  private static token: string | undefined = Cookies.get('auth-token');
  private static imagesURL: string = AppConfig.serverUrl + "/images/upload";
  private static filesURL: string = AppConfig.serverUrl + "/files/upload";

  /*----------------------------------------------------
  * Загрузка изображений
  * --------------------------------------------------*/
  static uploadImage = async (
    event: React.FormEvent<HTMLInputElement>,
    axiosConfig?: AxiosRequestConfig
  ): Promise<undefined | ImageStoreEntity> => {
    const target = event.target as HTMLInputElement;
    const imagesList = target?.files;

    if (imagesList && imagesList[0]) {
      const image: File = imagesList[0];

      return await this.upload(image, this.imagesURL, axiosConfig).then(res => {
        return res?.data as ImageStoreEntity
      })
    }
  };

  static uploadImages = async (
    event: React.FormEvent<HTMLInputElement>,
    axiosConfig?: AxiosRequestConfig
  ): Promise<undefined | ImageStoreEntity[]> => {
    const target = event.target as HTMLInputElement;
    const imagesList = target?.files;

    if (imagesList && imagesList[0]) {
      const result: ImageStoreEntity[] = []

      for (const image of Array.from(imagesList)) {
        await this.upload(image as File, this.imagesURL, axiosConfig).then(res => {
          result.push(res?.data as ImageStoreEntity)
        })
      }
      return result
    }
  };


  /*----------------------------------------------------
  * Загрузка файлов
  * --------------------------------------------------*/
  static uploadFile = async (
    event: React.FormEvent<HTMLInputElement>,
    axiosConfig?: AxiosRequestConfig
  ): Promise<undefined | FileStoreEntity> => {
    const target = event.target as HTMLInputElement;
    const filesList = target?.files;

    if (filesList && filesList[0]) {
      const file: File = filesList[0];

      return await this.upload(file, this.filesURL, axiosConfig).then(res => {
        return res?.data as FileStoreEntity
      })
    }
  };

  static uploadFiles = async (
    event: React.FormEvent<HTMLInputElement>,
    axiosConfig?: AxiosRequestConfig
  ): Promise<undefined | FileStoreEntity[]> => {
    const target = event.target as HTMLInputElement;
    const filesList = target?.files;

    if (filesList && filesList[0]) {
      const result: FileStoreEntity[] = []

      for (const file of Array.from(filesList)) {
        await this.upload(file as File, this.filesURL, axiosConfig).then(res => {
          result.push(res?.data as FileStoreEntity)
        })
      }
      return result
    }
  };

  private static upload = async (
    file: File,
    url: string,
    axiosConfig?: AxiosRequestConfig
  ):Promise<any> => {
    const data = new FormData();
    data.append('file', file)
    data.append('type', file.type)
    data.append('description', file.name)
    const options = {
      headers: {
        "authorization": `Bearer ${this.token}`,
      },
    }

    return await axios.post(url, data, {...options, ...axiosConfig})
  };
}
