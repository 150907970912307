import {createContext} from "react";
import {
  NotExistingCartRecordEntity,
  SportEquipmentEntity,
  SportObjectEntity,
  SportSubZoneEntity,
  SportZoneEntity
} from "../../@graphql/types";


export interface ProductsGroupedBySportObject {
  sportObject: SportObjectEntity,
  sportZones: {
    sportZone: SportZoneEntity,
    sportSubZones: {
      sportSubZone: SportSubZoneEntity,
      products: {
        product: SportEquipmentEntity
      }[]
    }[]
  }[]
}

export interface CheckedProductsSummery {
  checked: boolean,
  sportObject: SportObjectEntity,
  sportZones: {
    checked: boolean,
    sportZone: SportZoneEntity,
    sportSubZones: {
      checked: boolean,
      sportSubZone: SportSubZoneEntity,
      products: {
        checked: boolean,
        product: SportEquipmentEntity
      }[]
    }[]
  }[]
}

interface ICartContext {
  cartOpen: boolean
  setCartOpen: (state: boolean) => void
  addToCart: (equipment: SportEquipmentEntity) => void
  dropFromCart: (id: string) => void
  cartClear: (cartItems:any) => void
  cartItems: SportEquipmentEntity[]
  cartTotalCount: number
  checkIsInCart: (id: string) => boolean
  getButtonLabel: (id: string) => string
  productsGroupedBySportObjects: ProductsGroupedBySportObject[],
  selectedProducts: SportEquipmentEntity[]
  setSelectedProducts: (selected: SportEquipmentEntity[]) => void,
  notExistingProducts: NotExistingCartRecordEntity[],
  notExistingTotalCount: number
}

const defaultState = {
  addToCart: () => {
  },
  dropFromCart: () => {
  },
  cartOpen: false,
  cartItems: [],
  setCartOpen: () => {
  },
  cartTotalCount: 0,
  checkIsInCart: () => false,
  cartClear: () => {} ,
  getButtonLabel: () => 'В корзину',
  productsGroupedBySportObjects: [],
  selectedProducts: [],
  setSelectedProducts: () => {},
  notExistingProducts: [],
  notExistingTotalCount: 0
};

export const CartContext = createContext<ICartContext>(defaultState);

