import React from 'react';

interface Props {

}

export function Favorite(props: Props) {

  return (
    <></>
  );
}
