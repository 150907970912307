import React, {useMemo, useState} from 'react';
import {Card, Container, Grid, Stack, Typography} from "@mui/material";
import CheckoutForm from "../../views/checkout/CheckoutForm";
import CheckoutSidebar from "../../views/checkout/CheckoutSidebar";
import StickyBox from "react-sticky-box";
import {useCreateOrderMutation, CreateExternalOrderInput, useGetOrdersQuery} from "../../@core/@graphql/types";
import dayjs from "dayjs";
import useCart from "../../@core/hooks/useCart";
import {useNavigate} from "react-router-dom";
import {VehicleTypeEnum} from "../../@core/@graphql/external-order/vehicle-type.enum";
import {sportObjectVar, Warehouse} from "../../@core/store/sportObject";
import {useReactiveVar} from "@apollo/client";


interface Props {

}

interface State {
  createOrderInput: CreateExternalOrderInput
}


function CheckoutPage(props: Props) {
  const {cartTotalCount, cartClear, cartItems, notExistingTotalCount, notExistingProducts} = useCart()
  const {currentSportObject} = useReactiveVar(sportObjectVar)


  const count = useMemo(()=>{
    return cartItems?.slice()?.filter(item => item.sportObject.id === currentSportObject?.id || item.sportObject.id === Warehouse.id).length
  }, [cartItems])

  const [state, setState] = useState<State>({
    createOrderInput: {
      equipmentRecords: cartItems?.slice()?.filter(item => item.sportObject.id === currentSportObject?.id || item.sportObject.id === Warehouse.id).map(item => ({
        equipmentId: item.id, equipmentCount: 1
      })),
      notExistingEquipmentRecords: notExistingProducts?.map(item => ({
        name: item.name,
        description: item.description,
        equipmentCount: item.equipmentCount,
        imageIds: item?.images?.map(image => image.id) || []
      })),
      comments: '',
      receivingName: '',
      receivingPhone: '',
      targetPointAddress: '',
      startVehicleDeliveryDate: dayjs(new Date()).format('DD.MM.YYYY') + '',
      returnDate: dayjs(new Date()).format('DD.MM.YYYY') + '',
      // vehicleType: VehicleTypeEnum.Van,
      receivingPosition: ''
    }
  });

  const [createOrder] = useCreateOrderMutation()
  const navigate = useNavigate()

  const checkoutSubmitHandler = () => {
    createOrder({
      variables: {
        input: state.createOrderInput
      }
    }).then((res) => {
      cartClear(cartItems?.slice()?.filter(item => item.sportObject.id === currentSportObject?.id || item.sportObject.id === Warehouse.id))
      navigate('/my/orders/'+res.data?.createOrder.id)
    })
  }

  const [rentRange, setRentRange] = useState({
    start: '',
    end: ''
  });

  return (
    <>
      <Container maxWidth={'xl'} sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        mb: 8
      }}>

        <Typography variant={'h2'} my={4}> Оформление заказа </Typography>

        <Grid container spacing={4}>
          <Grid item lg={9}>
            <Stack
              component={'main'}
              direction={'column'}
              spacing={4}
              justifyContent={'justify-between'}
              sx={{overflow: 'hidden'}}
            >
              <CheckoutForm
                comments={state.createOrderInput.comments || ''}
                onChangeVehicleType={(vehicleType) => {
                  setState(prevState => ({
                    ...prevState, createOrderInput: {
                      ...prevState.createOrderInput,
                      vehicleType: vehicleType,
                    }
                  }))
                }}
                onChangeComments={(comments) => {
                  setState(prevState => ({
                    ...prevState, createOrderInput: {
                      ...prevState.createOrderInput,
                      comments: comments,
                    }
                  }))
                }}
                onChangeContacts={(contacts) => {
                  setState(prevState => ({
                    ...prevState, createOrderInput: {
                      ...prevState.createOrderInput,
                      receivingName: contacts?.fio || '',
                      receivingPhone: contacts?.phone || '',
                      receivingPosition: contacts?.position || '',
                    }
                  }))
                }}
                onChangeAddress={(address) => {
                  setState(prevState => ({
                    ...prevState, createOrderInput: {
                      ...prevState.createOrderInput,
                      targetPointAddress: address,
                    }
                  }))
                }}
                onChangeRentDates={(value) => {
                  setRentRange(prevState => ({
                    ...prevState,
                    start: value.startRentDate,
                    end: value.endRentDate,
                  }))
                  setState(prevState => ({
                    ...prevState, createOrderInput: {
                      ...prevState.createOrderInput,
                      startVehicleDeliveryDate: dayjs(value.startRentDate).format('YYYY-MM-DD HH:mm:ss').toString(),
                      returnDate: dayjs(value.endRentDate).format('YYYY-MM-DD HH:mm:ss').toString(),
                    }
                  }))
                }}
              />
            </Stack>
          </Grid>

          <Grid item lg={3}>
            <StickyBox offsetTop={80} offsetBottom={20}>
              <CheckoutSidebar
                onSubmit={checkoutSubmitHandler}
                cartTotalCount={count+notExistingTotalCount}
                rentRange={rentRange}
              />
            </StickyBox>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default CheckoutPage;
