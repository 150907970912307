// ** React Imports
import {ReactElement, ReactNode} from 'react'


// ** MUI Components
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'

// ** Layout Import
import BlankLayout from 'src/layouts/BlankLayout'

// ** Demo Imports
import {Link} from "react-router-dom";

// ** Styled Components
const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '90vw'
  }
}))

const Img = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(10),
  [theme.breakpoints.down('lg')]: {
    height: 450,
    marginTop: theme.spacing(10)
  },
  [theme.breakpoints.down('md')]: {
    height: 400
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(13)
  }
}))

const TreeIllustration = styled('img')(({ theme }) => ({
  left: 0,
  bottom: '5rem',
  position: 'absolute',
  [theme.breakpoints.down('lg')]: {
    bottom: 0
  }
}))

const Error404 = () => {
  return (
    <Box className='content-center'>
      <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', justifyContent:'center', height: '100vh' }}>
        <BoxWrapper>
          <Typography variant='h1' sx={{ fontSize: '8rem !important' }}>404</Typography>
          <Typography variant='h2' sx={{ mb: 1 }}>
            Страница не найдена ⚠️
          </Typography>
          <Typography variant='body1' sx={{ mb: 6 }}>К сожалению. такой страницы не существует.</Typography>
        </BoxWrapper>
        <Link to='/'>
          <Button component='a' variant='contained' sx={{ px: 5.5 }} size={'small'}>
            Вернуться на главную
          </Button>
        </Link>
      </Box>
    </Box>
  )
}

Error404.getLayout = (page: ReactElement<any>) => <BlankLayout>{page}</BlankLayout>

export default Error404
