// ** React Imports
import {useState, SyntheticEvent, Fragment} from 'react'

// ** Next Import
// import { useRouter } from 'next/router'

// ** MUI Imports
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import {styled} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import {darken} from "@material-ui/core";
import {Button, ButtonBase, Popover, useTheme} from "@mui/material";

import PersonIcon from '@mui/icons-material/Person';
import useAuth from "../../hooks/useAuth";
import navigate from "./Navigate";
import {useNavigate} from "react-router-dom";


// ** Styled Components
const BadgeContentSpan = styled('span')(({theme}) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const UserPanel = () => {
  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const theme = useTheme()
  const {logOut, user} = useAuth()
  const navigate = useNavigate()

  // ** Hooks
  // const router = useRouter()

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }
  const handleDropdownClose = (event: SyntheticEvent) => {
    setAnchorEl(null)
  }

  // const handleDropdownClose = (url?: string) => {
  //   if (url) {
  //     // router.push(url)
  //   }
  //   setAnchorEl(null)
  // }

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      fontSize: '1.375rem',
      color: 'text.secondary'
    }
  }

  const open = Boolean(anchorEl);

  function logOutHandler() {
    setAnchorEl(null)
    logOut()
  }

  function loginClickHandler() {
    navigate('login')
  }

  function accountHandleClick() {
    navigate('my/orders')
  }
  function messagesHandleClick() {
    navigate('my/chat')
  }

  return (
    <Fragment>
      <Box
        onMouseEnter={handleDropdownOpen}
        onMouseLeave={handleDropdownClose}
      >
        {user ?
          (
            <Box
              onClick={accountHandleClick}
              sx={{
                ml: 2,
                p: 1,

                cursor: 'pointer'
              }}
            >

              <Badge
                overlap='circular'
                badgeContent={<BadgeContentSpan/>}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
              >
                <Avatar
                  sx={{
                    width: 28,
                    height: 28,
                    bgcolor: darken(theme.palette.background.default, .05),
                    color: 'primary.main',
                    fontSize: "14px"
                  }}
                  src='/images/avatars/1.png'
                >
                  <PersonIcon color={'inherit'} fontSize={'inherit'}/>
                </Avatar>
              </Badge>


              <Box
                ml={1}
                component={'span'}>
                <Box
                  component={'span'}
                  sx={{fontSize: "12px"}}
                >Привет</Box> <b>{user?.login}</b>
              </Box>
            </Box>
          ) :
          (
          <Box
            sx={{
              ml: 2,
              p: 1,

              cursor: 'pointer'
            }}
          >
            <Badge
              overlap='circular'
              badgeContent={<BadgeContentSpan/>}
              anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            >
              <Avatar
                alt='Войти'
                sx={{
                  width: 28,
                  height: 28,
                  bgcolor: darken(theme.palette.background.default, .05),
                  color: 'primary.main',
                  fontSize: "14px"
                }}
              >
                <PersonIcon color={'inherit'} fontSize={'inherit'}/>
              </Avatar>
            </Badge>
            <Box
              ml={1}
              component={'span'}>
              <Box
                component={'span'}
                sx={{fontSize: "12px"}}
              >Войти
              </Box>
            </Box>
          </Box>
          )}


          <Menu
            elevation={15}
            anchorEl={anchorEl}
            open={open}
            disableRestoreFocus
            onClose={handleDropdownClose}
            sx={{
              pointerEvents: 'none',
              '& .MuiMenu-paper': {
                width: 230,
                pointerEvents: 'auto'
              }
            }}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            hideBackdrop
            disablePortal
            disableAutoFocus
            BackdropProps={{
              style: {
                pointerEvents: 'none'
              }
            }}
          >
            {
              user ? (
                <Box>
                  <Box sx={{px: 2, pb: 1}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                      <Badge
                        overlap='circular'
                        badgeContent={<BadgeContentSpan/>}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                      >
                        <Avatar alt='user' src='/images/avatars/1.png' sx={{
                          width: '2.5rem', height: '2.5rem',
                          bgcolor: darken(theme.palette.background.default, .05),
                          color: 'primary.main',
                        }}>
                          <PersonIcon color={'inherit'} fontSize={'inherit'}/>
                        </Avatar>
                      </Badge>
                      <Box sx={{display: 'flex', marginLeft: 3, alignItems: 'flex-start', flexDirection: 'column'}}>
                        <Typography sx={{fontWeight: 600}}>
                          {user?.fio || user?.login}
                        </Typography>
                        <Typography variant='body2' sx={{fontSize: '0.8rem', color: 'text.disabled'}}>
                          {user.userSettings?.phone}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider sx={{mt: 0, mb: 1}}/>
                  <MenuItem onClick={accountHandleClick}>
                    Личный кабинет
                  </MenuItem>
                  <MenuItem onClick={messagesHandleClick}>
                    Сообщения
                  </MenuItem>
                  <Divider/>
                  <MenuItem onClick={logOutHandler}>
                    <Typography color={'text.disabled'} >Выход</Typography>
                  </MenuItem>
                </Box>
              ) : (
                <Box p={3}>
                  <Typography mb={2}>Войдите, чтобы арендовать оборудования и отслеживать заказы.</Typography>

                  <Button size={'small'} fullWidth  variant={'contained'} onClick={loginClickHandler}>
                    Войти
                  </Button>
                </Box>
              )
            }

          </Menu>
          </Box>

          </Fragment>
          )
        }

        export default UserPanel
