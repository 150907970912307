import React from 'react';
import {Avatar, Box, Button, Card, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, useNavigate, useSearchParams} from "react-router-dom";
import {useParams} from "react-router";
import {FileStoreEntity, useGetOrderByIdQuery, useExportExternalOrderLazyQuery} from "../../../@core/@graphql/types";
import dayjs from "dayjs";
import appConfig from "../../../configs/appConfig";
import Iconify from "../../../@core/components/Iconify";
import AppConfig from "../../../configs/appConfig";


interface Props {

}

export function Order(props: Props) {
  const {id: orderId} = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const {data} = useGetOrderByIdQuery({
    variables: {
      orderId: Number(orderId)
    }
  })

  const [exportExternalOrder] = useExportExternalOrderLazyQuery()

  const handleExportSportEquipmentsInExcel = () => {
    exportExternalOrder({
      variables: {
        externalOrderId: Number(orderId)
      }
    }).then((res) => {
      const exportExternalOrder = res?.data?.exportExternalOrder
      const url = exportExternalOrder?.url
      url && window.location.replace(`${AppConfig.serverUrl}${url}`)
    })
  };

  return (
    <Box p={4}>
      <Box mb={2}>
        <Link
          component={RouterLink}
          to={'/my/orders'}
        >
          К списку заказов
        </Link>
      </Box>

      <Stack
        direction={'column'}
        mb={2}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
        >
          <Typography variant={'h2'}>
            Заказ № {data?.getOrderById?.orderNumber}
          </Typography>

          <Box>
            <Button
              startIcon={<Iconify icon={'mdi:microsoft-excel'}/>}
              onClick={() => {
                handleExportSportEquipmentsInExcel()
              }}
              size={'small'}
            >
              Скачать Excel файл
            </Button>
          </Box>

        </Stack>
        <Typography variant={'caption'}>
          от {dayjs(Number(data?.getOrderById?.createdAt)).format('DD MMM')}
        </Typography>
      </Stack>

      <Box
        p={3}
        sx={{
          bgcolor: 'background.default',
          borderRadius: 1
        }}
      >
        <Stack
          direction={'row'}
          spacing={4}
          justifyContent={'space-between'}
        >
          <Box>
            <Typography variant={'caption'}>
              Период аренды
            </Typography>
            <Typography
              variant={'body1'}
              sx={{fontWeight: 'bold'}}
            >
              {dayjs(Number(data?.getOrderById?.startEventDate)).format('DD.MM.YYYY HH:mm')} - {dayjs(Number(data?.getOrderById?.returnDate)).format('DD.MM.YYYY HH:mm')}
            </Typography>
          </Box>
          <Box>
            <Typography variant={'caption'}>
              Адрес доставки
            </Typography>
            <Typography
              variant={'body1'}
              sx={{fontWeight: 'bold'}}
            >
              {data?.getOrderById?.targetPointAddress}
            </Typography>
          </Box>
          <Box>
            <Typography variant={'caption'}>
              Получатель
            </Typography>
            <Typography
              variant={'body1'}
              sx={{fontWeight: 'bold'}}
            >
              {data?.getOrderById?.receivingName}
            </Typography>
            <Typography
              variant={'body1'}
              sx={{fontWeight: 'bold'}}
            >
              {data?.getOrderById?.receivingPhone}
            </Typography>
            <Typography
              variant={'body1'}
              sx={{fontWeight: 'bold'}}
            >
              {data?.getOrderById?.receivingPosition}
            </Typography>
          </Box>
          <Box>
            <Typography variant={'caption'}>
              Примечание к заказу
            </Typography>
            <Typography
              variant={'body1'}
              sx={{fontWeight: 'bold'}}
            >
              {data?.getOrderById?.comments === '' ? 'Без примечаний' : data?.getOrderById?.comments}
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Stack
        direction={'column'}
        mt={2}
      >
        <Stack
          direction={'row'}
          spacing={1}
          alignItems={'center'}
        >
          <Typography
            variant={'h4'}
            my={2}
            component={'div'}
          >
            Список оборудования
          </Typography>

          <Typography
            variant={'subtitle2'}
            sx={{color: 'text.secondary'}}
          >({(data?.getOrderById?.records?.length || 0) + (data?.getOrderById?.notExistingEquipmentRecords?.length || 0)})</Typography>
        </Stack>

        <Stack
          direction={'column'}
          spacing={2}
        >
          {
            data?.getOrderById?.records.map((record) => (
              <>
                <Card
                  elevation={0}
                  variant={'outlined'}
                  sx={{
                    width: '100%',
                    p: 2,
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    searchParams.set('preview', record?.equipment?.id)
                    setSearchParams(searchParams)
                  }}
                >
                  <Stack
                    direction={'row'}
                    spacing={2}
                    alignItems={'center'}
                  >
                    {appConfig?.serverUrl && <Avatar
                      key={record.id}
                      variant={'square'}
                      src={appConfig?.serverUrl + record?.equipment?.photos?.[0]?.avatarUrl || ''}
                    />}
                    <Stack spacing={-2}>
                      <Typography variant='h6'>
                        {record?.equipment.name}
                      </Typography>
                      <Typography
                        variant={'body2'}
                        color={'text.secondary'}
                      >
                        Инв. номер: {record?.equipment.inventoryNumber}
                      </Typography>
                    </Stack>
                    {/*{record?.equipment?.description && <Typography variant={'body1'} color={'text.secondary'}>*/}
                    {/*  Описание: {record?.equipment?.description}*/}
                    {/*</Typography>}*/}
                  </Stack>
                </Card>
              </>
            ))
          }
        </Stack>
        <Stack
          direction={'column'}
          spacing={2}
          mt={3}
        >
          <Typography
            variant={'h6'}
            mb={0}
            component={'div'}
          >
            Ваши заявки на оборудование
          </Typography>

          {
            data?.getOrderById?.notExistingEquipmentRecords?.map((record) => (
              <>
                <Card
                  elevation={0}
                  variant={'outlined'}
                  sx={{
                    width: '100%',
                    p: 2,
                  }}
                >
                  <Stack
                    direction={'row'}
                    spacing={2}
                    alignItems={'center'}
                  >
                    {appConfig?.serverUrl && <Avatar
                      key={record.id}
                      variant={'square'}
                      src={appConfig?.serverUrl + record?.images?.[0]?.avatarUrl || ''}
                    />}
                    <Stack spacing={-2}>
                      <Typography variant='h6'>
                        {record?.name}
                      </Typography>
                      <Typography
                        variant={'body2'}
                        color={'text.secondary'}
                      >
                        {record?.description}
                      </Typography>
                    </Stack>
                    {/*{record?.equipment?.description && <Typography variant={'body1'} color={'text.secondary'}>*/}
                    {/*  Описание: {record?.equipment?.description}*/}
                    {/*</Typography>}*/}
                  </Stack>
                </Card>
              </>
            ))
          }
        </Stack>
      </Stack>

    </Box>
  );
}
