import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import {useDialog} from "../../../@core/providers/DialogProvider";

interface Props {
  onChangeComments: (comments: string) => void
  comments: string
}

interface DialogProps {
  onChangeComments: (comments: string) => void
  onConfirm: () => void
  comments: string
}

function CommentsDialogContent(props: DialogProps) {
  const {onChangeComments, comments, onConfirm} = props
  const [value, setValue] = useState(comments);

  useEffect(() => {
    setValue(comments);
  }, [comments]);

  return (
    <>
      <DialogContent>
        <TextField
          value={value}
          onChange={({target: {value}}) => setValue(value)}
          label={'Примечание к доставке'}
          multiline
          minRows={5}
          fullWidth
          size={'small'}
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button size={'small'} color={'primary'} variant={'contained'} disabled={value==='' && comments === ''} onClick={() => {
          onChangeComments(value)
          onConfirm()
        }}>
          Добавить примечание
        </Button>
      </DialogActions></>
  );
}

function CheckoutFormFormNotesForDelivery(props: Props) {
  const [openDialog, closeDialog] = useDialog();

  const {onChangeComments, comments} = props
  const [value, setValue] = useState(comments);

  useEffect(() => {
    setValue(comments);
  }, [comments]);



  return (
    <Card sx={{
      p: 3,
      width: '100%'
    }}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography variant={'h4'}> Примечания к доставке </Typography>
        <Button size={'small'} color={'inherit'}
                onClick={() => {
                  openDialog({
                    PaperProps: {
                      sx: {
                        p: 0
                      }
                    },
                    sx: {
                      '& .MuiDialogTitle-root':{
                        p:2
                      }

                    },
                    fullWidth: true,
                    maxWidth: 'xs',

                    title: 'Укажите примечание к доставке',
                    children: <CommentsDialogContent
                      comments={value}
                      onChangeComments={onChangeComments}
                      onConfirm={()=>closeDialog()}
                    />
                  })
                }}
        >{
          comments === '' ? '+ Добавить примечание' : 'Изменить примечание'
        }</Button>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        { comments !== '' &&
          <Typography variant={'body1'}> {comments} </Typography>
        }

      </Box>

    </Card>
  );
}

export default CheckoutFormFormNotesForDelivery;
