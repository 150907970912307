// routes
import Routes from "./@core/routes/Routes";

// ** Contexts
import {SettingsConsumer, SettingsProvider} from 'src/@core/context/settingsContext'
import ThemeComponent from 'src/@core/theme/ThemeComponent'

// dayjs
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/ru';
import CartProvider from "./@core/providers/CartProvider";
import PreviewProvider from "./@core/providers/PreviewProvider";
import WishlistProvider from "./@core/providers/WishlistProvider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'photoswipe/dist/photoswipe.css'
import ScrollToTop from "./@core/components/shared-components/ScrollToTop ";
import {DialogProvider} from "./@core/providers/DialogProvider";
import RequestForNotExistentEquipmentProvider from "./@core/providers/RequestForNotExistentEquipmentProvider";


dayjs.locale('ru');
dayjs.extend(LocalizedFormat);

export default function App() {

  return (
    <SettingsProvider>
      <SettingsConsumer>
        {({settings}) => {
          return <ThemeComponent settings={settings}>
            <DialogProvider>
            <WishlistProvider>
              <CartProvider>
                <RequestForNotExistentEquipmentProvider>
                <PreviewProvider>
                  <ScrollToTop>

                    <Routes/>
                  </ScrollToTop>
                </PreviewProvider>
            </RequestForNotExistentEquipmentProvider>
              </CartProvider>
            </WishlistProvider>
            </DialogProvider>
          </ThemeComponent>
        }}
      </SettingsConsumer>
    </SettingsProvider>
  );
}
