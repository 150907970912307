import React, {useEffect, useMemo, useState} from 'react';
import {styled} from "@mui/material/styles";
import {Avatar, Box, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {ExternalOrderEntity, ExternalOrderMessageEntity} from "../../../../@core/@graphql/types";
import dayjs from "dayjs";
import {ExternalOrderStatusEnum} from "../../../../@core/@graphql/external-order/external-order-status.enum";
import {stringAvatar} from "../../../../@core/utils/stringAvatar";
import {useLocation, useParams} from "react-router";

const Wrapper = styled(Box)(({theme})=>({
  borderBottom: '1px solid',
  borderColor: theme.palette.divider,
  padding: theme.spacing(2),
  '&.active':{
    backgroundColor: theme.palette.action.hover
  }
}))

interface Props {
  order: ExternalOrderEntity
}
export function getExternalOrderStatusLocalName(externalOrderStatus: ExternalOrderStatusEnum) {
  switch (externalOrderStatus){
    case ExternalOrderStatusEnum.Created: return "Готовится к отправке"
    case ExternalOrderStatusEnum.InTransportService: return "Отправлено"
    case ExternalOrderStatusEnum.ReturnedIncomplete: return "Проблемы при возврате"
    case ExternalOrderStatusEnum.ReturnedComplete: return "Успешный возврат"
  }
}
function ChatListItem(props: Props) {
  const {order} = props
  const navigate = useNavigate()
  const itemClickHandler = () =>{
    navigate('/my/chat/'+order?.id)
  }
  const {id} = useParams()

  const [lastMessage, setLastMessage] = useState<ExternalOrderMessageEntity | null>(null);

  useEffect(() => {
    if (order && order?.messages){
      setLastMessage(order.messages[order.messages.length - 1 ])
    }
  }, [order]);

  const isActive = useMemo(() => {
    return Boolean(order?.id === id)
  }, [order, id]);


  return (
    <Wrapper onClick={itemClickHandler} className={`${isActive ? 'active' : ''} chat-list__item`} component={'div'}  >
      <Stack direction={'row'} spacing={2} sx={{fontSize: '12px'}}>
        <Avatar
          {...stringAvatar(lastMessage?.user?.login as string)}
          sx={{
          width: 30,
          height: 30,
        }}/>
        <Stack direction={'column'}>
          <Typography variant={'subtitle1'} sx={{fontSize: '14px'}}>
            {lastMessage?.user?.login}
          </Typography>
          <Typography variant={'subtitle2'} sx={{fontSize: '12px'}}>
            Заказ № {order.orderNumber}
          </Typography>
          <Typography variant={'caption'} sx={{fontSize: '12px'}}>
            {lastMessage?.externalOrderStatus && <Box sx={{
              py:.5,
              px:2,
              borderRadius: 1,
              bgcolor: 'background.default',
              display: 'inline',
              fontWeight: 'bold'
            }}>
              {getExternalOrderStatusLocalName(lastMessage.externalOrderStatus as ExternalOrderStatusEnum)}
            </Box>}
          </Typography>
          <Typography variant={'caption'} sx={{fontSize: '12px'}}>
            <Box
              component={'pre'}
              sx={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 200,
              fontSize: '12px',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
              }}
            >
            {lastMessage?.message}
            </Box>
          </Typography>
        </Stack>

        <Typography variant={'body2'}>
          {dayjs(lastMessage?.createdAt).format('DD.MM')}
        </Typography>
      </Stack>
    </Wrapper>
  );
}

export default ChatListItem;
