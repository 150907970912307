import React, {useEffect, useMemo, useState} from 'react';
import Map, {Marker} from 'react-map-gl';
import {
  Avatar,
  Box,
  Button,
  CardHeader,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import {
  SportObjectEntity,
  useGetAllSportObjectsQuery,
  useUpdateSportObjectsCoordsMutation
} from "../../@core/@graphql/types";
import axios from "axios";
import {stringAvatar} from "../../@core/utils/stringAvatar";
import Iconify from "../../@core/components/Iconify";
import {styled, useTheme} from "@mui/material/styles";
//@ts-ignore
import MarkerHoveredIcon from "../../@core/assets/sportobject-hovered.svg"
//@ts-ignore
import MarkerIcon from "../../@core/assets/sportobject.svg"
import {sportObjectVar, useSportObjectVars} from "../../@core/store/sportObject";
import {useReactiveVar} from "@apollo/client";

const Root = styled(Box)(({theme}) => ({
  flexGrow: 1, display: 'flex', position: 'relative', height: '100%', width: '100%'
}))

const Sidebar = styled(Box)(({theme}) => ({
  width: 380,
  minWidth: 380,
  backgroundColor: theme.palette.background.paper,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  position: "relative",
  padding: theme.spacing(3)
}))

const SidebarWrapper = styled(Box)(({theme}) => ({
  width: '100%', position: "relative", flexGrow: 1, display: 'flex', flexDirection: 'column',
}))
const ListWrapper = styled(Box)(({theme}) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  position: "relative",
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}))
const MapRoot = styled(Box)(({theme}) => ({
  flexGrow: 1, display: 'flex', position: 'relative', width: '100%', overflow: 'hidden'
}))

const MapWrapper = styled(Box)(({theme}) => ({
  position: 'absolute', height: '100%', width: '100%'
}))

const SportObjectItem = ({item, ...props}: any) => (
  <CardHeader
    sx={{alignItems: 'start', width: '100%', p: 0}}
    title={<Typography variant={'h5'} color={'inherit'} sx={{lineHeight: 1, mb: 2}}>
      {item?.name}
    </Typography>}
    subheader={<Typography variant={'body1'} fontSize={'inherit'} color={'inherit'} sx={{lineHeight: 1}}>
      {item?.address ? item?.address : 'Адрес не указан'}
    </Typography>}
    avatar={<Avatar {...stringAvatar(item?.name)} sx={{width: 20, height: 20, mt: 1}}/>}
    {...props}
  />
)

interface Props {

}

interface SportObjectMarker {
  id: string
  name: string
  address: string
  latitude?: number
  longitude?: number
  zoom: number
}

const MossportEventsPage = (props: Props) => {
  const theme = useTheme()
  const {setCurrentSportObject, cancelChangeSportObject} = useSportObjectVars()
  const {prevSportObject} = useReactiveVar(sportObjectVar)

  const [markers, setMarkers] = useState<SportObjectMarker[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState('');

  const markersFiltered = useMemo(() => {
    return [...markers].filter(item => item.address.toLowerCase().includes(search.toLowerCase()) || item.name.toLowerCase().includes(search.toLowerCase()))?.filter(item => item?.id !== prevSportObject?.id)
  }, [markers, search, prevSportObject]);

  const [selected, setSelected] = useState<SportObjectMarker | null>(null);

  const {data: sportObjectsData} = useGetAllSportObjectsQuery()

  const [updateSportObjectsCoords] =  useUpdateSportObjectsCoordsMutation()

  useEffect(() => {
    updateSportObjectsCoords()


    if (sportObjectsData?.getAllSportObjects && markers?.length === 0) {
      setMarkers(
        sportObjectsData?.getAllSportObjects?.slice().map((item) => ({
            id: item.id,
            name: item.name,
            address: item?.address,
            latitude: item?.latitude ? item?.latitude === 55.741469 ? undefined : item?.latitude : undefined,
            longitude: item?.longitude ? item?.longitude === 37.615561 ? undefined : item?.longitude : undefined,
            zoom: 10
          })
        )
      )


    }
  }, [sportObjectsData, markers]);


  const [viewState, setViewState] = useState({
    latitude: 55.741469,
    longitude: 37.615561,
    zoom: 9
  });


  const isMultipleObjects = () => markers?.filter(item => item?.latitude === selected?.latitude && item?.longitude === selected?.longitude).length > 1;
  const isCoordinates = () => selected?.latitude && selected?.longitude;

  function titleSelectedObject() {

    if (selected) {

      if (isCoordinates() && isMultipleObjects()) {
        return (
          <Typography variant={'h4'}>{selected?.address}</Typography>
        )
      }

      return (
        <Stack direction={'row'} spacing={2} alignItems={'start'}>
          <Avatar {...stringAvatar(selected?.name)} sx={{width: 20, height: 20, mt: 1}}/>
          <Typography variant={'h4'}>{selected?.name}</Typography>
        </Stack>
      )

    }

    return null
  }

  function contentSelectedObject() {
    if (selected) {
      if (isCoordinates() && isMultipleObjects()) {
        const multipleObjects = () => {
          return markers
            ?.filter(item => item?.latitude === selected?.latitude && item?.longitude === selected?.longitude)
            ?.sort((a, b) =>
              -b?.name.localeCompare(a?.name)
            )
        }
        return (
          <List sx={{m: 0, overflow: 'auto', height: '100%', width: '100%', position: "absolute"}}>
            {multipleObjects()?.map(item => (
              <ListItem key={item.id}>
                <SportObjectItem
                  item={item}
                  action={
                    <Box ml={3}>
                      <Button variant={'contained'} color={'secondary'} size={'small'} fullWidth onClick={() => {
                        setCurrentSportObject(sportObjectsData?.getAllSportObjects.find(sportObject => sportObject.id === item.id) as SportObjectEntity)
                      }}
                      >
                        Выбрать
                      </Button>
                    </Box>
                  }
                />
              </ListItem>
            ))}
          </List>
        )
      }
      return (
        <Box>
          <Typography variant={'subtitle1'} fontSize={'inherit'} color={'inherit'} sx={{lineHeight: 1}}>
            {selected?.address ? selected.address : 'Адрес не указан'}
          </Typography>
          <Box mt={3}>
            <Button
              variant={'contained'}
              color={'secondary'}
              size={'medium'}
              fullWidth
              onClick={() => {
                setCurrentSportObject(sportObjectsData?.getAllSportObjects.find(sportObject => sportObject.id === selected.id) as SportObjectEntity)
              }}
            >
              Выбрать этот спортобъект
            </Button>
          </Box>
        </Box>
      )

    }
    return null;
  }

  return (
    <Root>
      <Sidebar>
        <Box>
          <Button
            color={'inherit'}
            size={"small"}
            sx={{
              mb:2
            }}
            onClick={cancelChangeSportObject}
          >
            <Iconify icon={'eva:arrow-back-fill'} sx={{mr:2}} />
            <Typography sx={{display:'inline-flex'}} >Назад</Typography>
          </Button>
        </Box>

        {selected ? (
          <SidebarWrapper>
            <Stack width={'100%'} direction={'row'} spacing={1} justifyContent={'space-between'} alignItems={"start"}>
              {titleSelectedObject()}
              <Box>
                <IconButton size={'small'} onClick={() => setSelected(null)}>
                  <Iconify icon={'mdi:close'}/>
                </IconButton>
              </Box>
            </Stack>
            <ListWrapper>
              {contentSelectedObject()}
            </ListWrapper>
          </SidebarWrapper>
        ) : (
          <SidebarWrapper>
            <Typography variant={'h4'}>Где будет проходить мероприятие?</Typography>
            <Typography variant={'caption'} mb={2}>Выберите спортобъект на карте или используйте поиск</Typography>
            <OutlinedInput
              size={'small'}
              placeholder={'Навзвание спортобъекта или адрес'}
              value={search}
              onChange={({target: {value}}) => setSearch(value)}
              type={'search'}
            />
            <ListWrapper>
              <List sx={{m: 0, overflow: 'auto', height: '100%', width: '100%', position: "absolute"}}>
                {loading ? <LinearProgress/> :
                  markersFiltered?.sort((a, b) => -b?.name.localeCompare(a?.name))?.slice()?.map(item => (
                    <ListItem
                      key={item.id}
                      button
                      onClick={() => {
                        if (item?.latitude && item?.longitude) {
                          setViewState({
                            latitude: item.latitude,
                            longitude: item.longitude,
                            zoom: 14
                          })
                        }
                        setSelected(item)
                      }}>
                      <SportObjectItem item={item}/>
                    </ListItem>
                  ))
                }
              </List>
            </ListWrapper>
          </SidebarWrapper>)
        }
      </Sidebar>

      <MapRoot>
        <MapWrapper>
          <Map
            {...viewState}
            onMove={evt => setViewState(evt.viewState)}

            style={{
              width: '100%',
              height: '100%',
            }}

            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            mapStyle={'mapbox://styles/ammth/clal2ph3s003w14mpf298ikr0'}
          >
            {!loading && markers && markers?.length > 0 &&
              markers?.map((marker, index) => {
                if (!marker?.latitude || !marker?.longitude) return
                return (
                  <Marker
                    key={marker.id + index}
                    {...{
                      latitude: marker.latitude,
                      longitude: marker.longitude,
                    }}
                    anchor="bottom"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setViewState({
                        latitude: marker.latitude as number,
                        longitude: marker.longitude as number,
                        zoom: 14
                      })
                      setSelected(marker)
                    }}
                  >

                    {viewState.zoom > 11 ? (
                      <Box
                        sx={{
                          position: 'absolute',
                          left: -22,
                          top: -64,
                          width: 44,
                          height: 64,
                          background: `url(${MarkerIcon})`,
                          '& .label': {
                            textIndent: '-9999rem',
                            textShadow: '1px 1px 2px white',
                            color: 'black',
                            position: 'absolute',
                            top: 64,
                            left: '-30%',
                            whiteSpace: 'nowrap',
                            fontSize: '16px',
                            fontWeight: 'bold',
                          },
                          '&:hover': {
                            background: `url(${MarkerHoveredIcon})`,
                            left: -30,
                            top: -102,
                            width: 60,
                            height: 102,
                            '& .label': {
                              top: 102,
                              textIndent: 1,
                            }
                          }
                        }}
                      >
                        <Box className={'label'}> {marker.name} </Box>
                      </Box>
                    ) : (
                      <Box

                        sx={{
                          position: 'relative',
                          top: viewState.zoom > 10 ? 25 / 2 : 15 / 2,
                          left: viewState.zoom > 10 ? 25 / 2 : 15 / 2,

                          '& .MuiAvatar-root': {
                            transform: 'scale(1)',
                          },
                          '& .label': {
                            textIndent: '-9999rem',
                            textShadow: '1px 1px 2px white',
                            color: 'black',
                            position: 'absolute',
                            left: '-30%',
                            whiteSpace: 'nowrap',
                            fontSize: '16px',
                            fontWeight: 'bold',
                          },
                          '&:hover': {
                            '& .MuiAvatar-root': {
                              transform: 'scale(1.2)',
                              backgroundColor: 'primary.main',
                            },
                            '& .label': {
                              textIndent: 1,
                            }
                          }
                        }}
                      >
                        <Avatar
                          children={stringAvatar(marker?.name).children}
                          sx={{
                            fontSize: '10px',
                            width: viewState.zoom > 10 ? 25 : 15,
                            height: viewState.zoom > 10 ? 25 : 15,
                            color: viewState.zoom > 10 ? stringAvatar(marker?.name)?.style?.color : 'transparent',
                            backgroundColor: viewState.zoom > 10 ? stringAvatar(marker?.name)?.style?.backgroundColor : 'primary.main',
                          }}
                        />
                        <Box className={'label'}> {marker.name} </Box>
                      </Box>

                    )}

                  </Marker>

                )
              })
            }
          </Map>
        </MapWrapper>
      </MapRoot>
    </Root>

  );
};

export default MossportEventsPage
