import {useMemo, useState} from "react";
import {WishlistContext} from "src/@core/context/WishlistContext";
import {SportEquipmentEntity} from "../../@graphql/types";


function WishlistProvider(props: any) {
  const [wishlistOpen, setWishlistOpen] = useState(false);
  const [wishlistItems, setWishlistItems] = useState<SportEquipmentEntity[]>([]);
  const [wishlistTotalCount, setWishlistTotalCount] = useState(0);

  const dropFromWishlist = async (id:string) => {
    setWishlistItems(prevState => ([...prevState].filter(item => item.id !== id)))
    setWishlistTotalCount(prevState => prevState - 1)
  }
  const addToWishlist = async (equipment:SportEquipmentEntity) => {
    setWishlistItems(prevState => ([...prevState, equipment]))
    setWishlistTotalCount(prevState => prevState + 1)
  }

  useMemo(() => {
    localStorage.setItem('wishlistItems', JSON.stringify(wishlistItems))
    localStorage.setItem('wishlistTotalCount', JSON.stringify(wishlistItems.length))
  }, [wishlistItems]);

  const checkIsInWishlist = (id: string) => {
    return wishlistItems.length > 0 && !!(wishlistItems?.find(item => item?.id === id))
  };

  const contextValue = useMemo(
    () => ({
      addToWishlist,
      dropFromWishlist,
      wishlistItems,
      wishlistTotalCount,
      wishlistOpen,
      setWishlistOpen,
      checkIsInWishlist,
    }),
    [
      wishlistOpen,
      wishlistItems,
      wishlistTotalCount
    ]
  );

  return (
    <WishlistContext.Provider value={contextValue}>
      {props.children}
    </WishlistContext.Provider>
  );
}

export default WishlistProvider;



