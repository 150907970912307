import React from 'react';
import Page from "../../@core/components/Page";
import {Outlet} from "react-router-dom";

interface Props {

}

function FavoritesPage(props: Props) {

  return (
    <Page title={'Избранное'}>
      <Outlet/>
    </Page>
  );
}

export default FavoritesPage;
