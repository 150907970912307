import React from 'react';
import {Container, Grid} from "@mui/material";
import ProductsFilters from "../../views/products/filters";
import ProductsList from "../../views/products/products-list";
import {EventType, sportObjectVar} from "../../@core/store/sportObject";
import MossportEventsPage from "../mossport-events";
import {useReactiveVar} from "@apollo/client";

interface Props {

}

function ProductsPage(props: Props) {
  const {currentSportObject, eventType} = useReactiveVar(sportObjectVar)

  return (
    <>
      {currentSportObject !== null ? (
        <Container maxWidth={'xl'} sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          mb: 4
        }}>
          <Grid container spacing={2} mt={1}>
            <Grid item lg={2}>
              <ProductsFilters/>
            </Grid>
            <Grid item lg={10}>
              <ProductsList/>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <MossportEventsPage/>
      )}

    </>
  );
}

export default ProductsPage;
