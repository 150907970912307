import React, {FC, useEffect, useMemo} from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent, IconButton,
  Stack,
  TextField,
  ToggleButton, Tooltip,
  Typography
} from "@mui/material";
import {ToggleButtonGroup} from "../../../@core/components/shared-components/ToggleButtonGroup";
import {EpicDialogOption, useDialog} from "../../../@core/providers/DialogProvider";
import {IUserAddresses, UserAddressesInput} from "../../../@core/context/AuthContext";
import * as Yup from "yup";
import {Form, FormikHelpers, FormikProvider, useFormik} from "formik";
import useAuth from "../../../@core/hooks/useAuth";
import {useReactiveVar} from "@apollo/client";
import {EventType, sportObjectVar} from "../../../@core/store/sportObject";
import {Delete, Edit} from "@mui/icons-material";


interface AddAddressDialogContentProps {
  onClose: () => void
  userAddress?: IUserAddresses
}

// компонент диалогового окна добавления адреса доставки пользователя в профиле пользователя
const AddAddressDialogContent: FC<AddAddressDialogContentProps> = props => {
  const {onClose, userAddress} = props
  const {addUserAddress, updateUserAddress} = useAuth()

  // схема валидации формы добавления адреса доставки пользователя в профиле пользователя
  const UserAddressSchema = Yup.object().shape({
    region: Yup.string().required('Заполните поле'), // область, поле обязательно для заполнения (required)
    city: Yup.string().required('Заполните поле'), // город, поле обязательно для заполнения (required)
    street: Yup.string().required('Заполните поле'), // улица, поле обязательно для заполнения (required)
    building: Yup.string().required('Заполните поле'), // дом, поле обязательно для заполнения (required)
  });

  const formik = useFormik({
    initialValues: {
      region: userAddress?.region || '',
      city: userAddress?.city || '',
      street: userAddress?.street || '',
      building: userAddress?.building || '',
    },
    validationSchema: UserAddressSchema,
    onSubmit: (
      values: UserAddressesInput,
      {setSubmitting}: FormikHelpers<UserAddressesInput>,
    ) => {
      if (userAddress) {
        updateUserAddress({...values, id: userAddress.id})
      } else {
        addUserAddress({...values})
      }
      setSubmitting(false)
      onClose()
    },
  });

  const {errors, touched, handleSubmit, getFieldProps} = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit}
        >
          <DialogContent>
            <Stack
              spacing={3}
              mb={4}
            >
              <Stack
                spacing={2}
                direction={'row'}
              >
                <TextField
                  autoFocus
                  required
                  fullWidth
                  size={'small'}
                  autoComplete='off'
                  type='text'
                  label='Область'
                  {...getFieldProps('region')}
                  error={Boolean(touched.region && errors.region)}
                  helperText={touched.region && errors.region}
                />
                <TextField
                  required
                  fullWidth
                  size={'small'}
                  autoComplete='off'
                  type='text'
                  label='Город'
                  {...getFieldProps('city')}
                  error={Boolean(touched.city && errors.city)}
                  helperText={touched.city && errors.city}
                />
              </Stack>

              <Stack
                spacing={2}
                direction={'row'}
              >
                <TextField
                  required
                  fullWidth
                  size={'small'}
                  autoComplete='off'
                  type='text'
                  label='Улица'
                  {...getFieldProps('street')}
                  error={Boolean(touched.street && errors.street)}
                  helperText={touched.street && errors.street}
                />
                <TextField
                  required
                  fullWidth
                  size={'small'}
                  autoComplete='off'
                  type='text'
                  label='Дом'
                  {...getFieldProps('building')}
                  error={Boolean(touched.building && errors.building)}
                  helperText={touched.building && errors.building}
                />

              </Stack>

            </Stack>

          </DialogContent>
          <DialogActions>
            <Button
              size={'small'}
              color={'primary'}
              variant={'contained'}
              type='submit'
            >
              {
                userAddress ? 'Сохранить изменения' : 'Добавить адрес'
              }
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </>
  );
};


interface CheckoutFormFormDeliveryAddressProps {
  onChangeAddress: (address: string) => void
}

// компонент выбора адреса доставки
const CheckoutFormFormDeliveryAddress: FC<CheckoutFormFormDeliveryAddressProps> = props => {
  const {onChangeAddress} = props; // функция обратного вызова, которая вызывается при выборе адреса доставки
  const [openDialog, closeDialog] = useDialog(); // хук диалогового окна
  const {userAddresses, removeUserAddress} = useAuth() // хук контекста авторизации
  const {eventType, currentSportObject} = useReactiveVar(sportObjectVar) // хук реактивной переменной, хранящей данные о текущем спортивном объекте
  const [addressId, setAddressIds] = React.useState(eventType === EventType.internal && currentSportObject?.address ? 'currentSportObjectAddress' : userAddresses?.[0]?.id || ''); // id выбранного адреса доставки


  // функция обратного вызова, которая вызывается при выборе адреса доставки
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAddress: string, // id выбранного адреса доставки
  ) => {
    // если выбрана кнопка добавления нового адреса доставки
    newAddress !== 'currentSportObjectAddress' && setAddressIds(newAddress);
  };

  // компонент выбора адреса доставки
  const control = {
    value: addressId, // id выбранного адреса доставки
    onChange: handleChange, // функция обратного вызова, которая вызывается при выборе адреса доставки
    exclusive: eventType === EventType.external, // если тип события внешний, то выбор адреса доставки идет по одному
  };


  useMemo(() => {
    if (userAddresses && userAddresses?.length > 0 && addressId === 'addButton') {
      setAddressIds(userAddresses?.[0]?.id)
    }
  }, [userAddresses]); // если список адресов доставки изменился, то выбираем первый адрес из списка

  // функция преобразования объекта адреса доставки в строку
  const userAddressToString = (userAddress: IUserAddresses | null) => userAddress ? (
    `Обл. ${userAddress.region}, ` + `г. ${userAddress.city}, ` + `ул. ${userAddress.street}, ` + `д. ${userAddress.building}, ` + (userAddress?.apartment ? `кв. ${userAddress.apartment}, ` : '') + (userAddress?.entrance ? `этаж ${userAddress.entrance}, ` : '')
  ).slice(0, -2) : '';

  useEffect(() => {
    onChangeAddress(userAddressToString(userAddresses?.find((item) => item.id === addressId) as IUserAddresses))
  }, [addressId]) // при изменении выбранного адреса доставки вызываем функцию обратного вызова, которая вызывается при выборе адреса доставки

  useEffect(() => {
    currentSportObject?.address && eventType === EventType.internal && onChangeAddress(currentSportObject?.address)
  }, [eventType, currentSportObject]) // если тип события внутренний, то выбираем адрес спортивного объекта


  const dialogOptions = {
    PaperProps: {
      sx: {
        p: 0
      }
    },
    sx: {
      '& .MuiDialogTitle-root': {
        p: 2
      }

    },
    fullWidth: true,
    maxWidth: 'xs',
  } as EpicDialogOption


  const addNewUserAddressHandler = () => {
    openDialog({
      ...dialogOptions,

      title: 'Адрес доставки',
      children: <AddAddressDialogContent
        onClose={() => closeDialog()}
      />
    })
  };

  const editUserAddressHandler = (userAddress: IUserAddresses) => {
    openDialog({
      ...dialogOptions,

      title: 'Редактирование адреса доставки',
      children: <AddAddressDialogContent
        onClose={() => closeDialog()}
        userAddress={userAddress}
      />
    })
  };

  const removeUserAddressHandler = (item: IUserAddresses) => {
    openDialog({
      ...dialogOptions,

      title: 'Удаление адреса доставки',
      children: <Box  px={2}>
        <Alert
          severity={'warning'}
          sx={{
            mb: 2,
            '& .MuiAlert-icon': {
              pt: 2
            }
          }}
        >
          <Typography variant={'h4'}>Вы действительно хотите удалить адрес
            доставки?</Typography>
          <Typography variant={'caption'}>{userAddressToString(item)}</Typography>
        </Alert>
        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          mt={4}
          pb={2}
        >
          <Button
            size={'small'}
            variant={'outlined'}
            onClick={() => closeDialog()}
          >
            Отмена
          </Button>
          <Button
            variant={'contained'}
            size={'small'}
            onClick={() => {
              closeDialog();
              removeUserAddress(item.id);
              if (item.id === addressId) {
                if (userAddresses && userAddresses?.length > 0) {
                  setAddressIds(userAddresses?.[0]?.id)
                } else {
                  setAddressIds('addButton')
                }
              }
            }}
            sx={{
              ml: 1
            }}
          >
            Удалить
          </Button>
        </Box>
      </Box>
    })
  };

  return (
    <Card sx={{p: 3,width: '100%'}}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
      >
        {/* Если событие внешнее, то предлагаем выбрать адрес из списка, иначе показываем адрес спортивного объекта */}
        <Typography variant={'h4'}> {eventType !== EventType.internal ? "Выберите адрес доставки" : "Адрес доставки"} </Typography>
        {/* Если событие внешнее, то показываем кнопку добавления нового адреса доставки */}
        {eventType !== EventType.internal &&
          <Button
            size={'small'}
            color={'inherit'}
            onClick={addNewUserAddressHandler}
          >
            + Добавить адрес
          </Button>
        }
      </Box>
      {/* Если событие внешнее, то показываем текст с описанием */}
      {eventType !== EventType.internal && <Typography variant={'caption'}>
        Выберите один из ваших адресов. На этот адрес будет отправлено оборудование
      </Typography>}

      <Stack
        direction={'row'}
        spacing={2}
        alignItems={'baseline'}
        mt={3}
      >

        <ToggleButtonGroup
          size={'large'}
          color={'primary'} {...control} >
          {/*Если событие внутреннее, то показываем адрес спортивного объекта*/}
          {eventType === EventType.internal && currentSportObject?.address ? (
            <ToggleButton value={'currentSportObjectAddress'}>
              {currentSportObject?.address}
            </ToggleButton>
          ) : (
            //Иначе событие внешнее
            //Если есть сохранённые адреса пользователя, то показываем их
            userAddresses && userAddresses?.length > 0 ? (
              userAddresses?.map(item => (
                <ToggleButton
                  value={item.id}
                  key={item.id}
                  component={Box}
                  sx={{
                    position: 'relative',
                    '&:hover': {
                      '& [class*="MuiStack-root"]': {
                        visibility: 'visible'
                      }
                    }
                  }}
                >
                  <Box>
                    <Stack
                      direction={'row'}
                      spacing={1}
                      alignItems={'center'}
                      sx={{
                        position: 'absolute',
                        right: 2,
                        top: 2,
                        visibility: 'hidden'
                      }}
                    >
                      <Tooltip title={'Редактировать'}>
                        <IconButton
                          size={'small'}
                          onClick={(e) => {
                            e.stopPropagation();
                            editUserAddressHandler(item)
                          }}
                        >
                          <Edit fontSize={'small'} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={'Удалить'}>
                        <IconButton
                          size={'small'}
                          onClick={(e) => {
                            e.stopPropagation();
                            removeUserAddressHandler(item)
                          }}
                        >
                          <Delete fontSize={'small'} />
                        </IconButton>
                      </Tooltip>
                    </Stack>


                    {userAddressToString(item)}
                  </Box>
                </ToggleButton>
              ))
            ) : (
              //Если адресов нет, то показываем кнопку добавить адрес
              <ToggleButton
                value={'addButton'}
                onClick={addNewUserAddressHandler}
              >
                Добавьте новый адрес
              </ToggleButton>
            )
          )}
        </ToggleButtonGroup>
      </Stack>
    </Card>
  );
};

export default CheckoutFormFormDeliveryAddress;
