import React, {ReactNode, useState} from 'react';
import {Tooltip, Typography} from "@mui/material";
import {getNodeText} from "../utils/getNodeText";

interface Props {
  text: string | JSX.Element
  props?: {
    [key: string]: any;
  }
}

const Tooltiper = (rest: Props) => {
  const {props} = rest;
  const [tooltipDisabled, setTooltipDisabled] = useState(true);
  const [tooltipOpen, setTooltipOpen,] = useState(false);
  const text = (typeof rest.text !== 'string') ? getNodeText(rest.text) : rest.text



  return (
    <Tooltip
      open={!tooltipDisabled && tooltipOpen}
      onClose={() => !tooltipDisabled && setTooltipOpen(false)}
      onOpen={() => !tooltipDisabled && setTooltipOpen(true)}
      title={(text) ? `${text?.charAt(0).toUpperCase()}${text?.slice(1)}` : ''}
    >
      <Typography
        {...props}
        sx={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%',
          overflow: 'hidden',
        }}
        onMouseEnter={(e: any) => setTooltipDisabled(!(e.target?.offsetWidth < e.target?.scrollWidth))}
        onMouseLeave={(e: any) => setTooltipDisabled(!(e.target?.offsetWidth < e.target?.scrollWidth))}
      >
        {rest.text}
      </Typography>
    </Tooltip>
  );
}

export default Tooltiper;
