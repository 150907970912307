import React from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack, TextField,
  Toolbar,
  Typography
} from "@mui/material";
import useCart from "../../hooks/useCart";
import {Link, useSearchParams} from "react-router-dom";
import {CartItemsList} from "../../../views/cart";
import {useReactiveVar} from "@apollo/client";
import {sportObjectVar, Warehouse} from "../../store/sportObject";
import {
  NotExistingCartRecordEntity,
  useAddNotExistingEquipmentRecordToCartMutation,
  useRemoveNotExistingEquipmentRecordFromCartMutation
} from "../../@graphql/types";
import {getEquipmentPhoto} from "../../utils/getEquipmentPhoto";
import appConfig from "../../../configs/appConfig";
import MinMaxLazy from "../MinMaxLazy";
import useNotExistentEquipment from "../../hooks/useNotExistentEquipment";
import Tooltiper from "../Tooltiper";

interface Props {

}


function CartItemsNotExistingCartRecordsList(props: { items: NotExistingCartRecordEntity[] }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const {dropFromCart} = useCart()

  const [removeNotExistingCartRecord] = useRemoveNotExistingEquipmentRecordFromCartMutation()
  const [addNotExistingCartRecord] = useAddNotExistingEquipmentRecordToCartMutation()



  return (
    <>
      {props.items?.map((item: NotExistingCartRecordEntity) => (
        <Card
          elevation={0}
          variant={'outlined'}
          sx={{
            p: 2,
            mb: 2,
            width: 360
          }}
          key={item.id}
        >

          <Stack
            direction={'row'}
            spacing={2}
            alignItems={'start'}
            sx={{
              cursor: 'pointer'
            }}

            onClick={() => {
              searchParams.set('not-exist-preview', item.id)
              setSearchParams(searchParams)
            }}
          >
            <Avatar
              sx={{
                bgcolor: 'background.paper',
                borderColor: 'divider',
                borderWidth: 1,
                borderStyle: 'solid',
                borderRadius: 1,
                width: 42,
                height: 42
              }}
              variant={'square'}
            >
              <img
                src={`${appConfig?.serverUrl}${item?.images?.[0]?.url}`}
                height={42}
                alt=''
              />
            </Avatar>
            <Stack
              direction={'column'}
              spacing={1}
              sx={{
                width: 0,
                display: 'flex',
                flexBasis: '100%',
              }}
            >
              <Tooltiper
                text={item?.name || ''}
                props={{
                  variant: 'subtitle2',
                  color: 'primary.main',
                }}
              />

              <Tooltiper
                text={item?.description || ''}
                props={{
                  variant: 'body2',
                }}
              />



            </Stack>
          </Stack>
          <Divider/>
          <Stack
            direction={'row'}
            spacing={2}
            justifyContent={'flex-end'}
            width={'100%'}
          >


            <Box display={'flex'}>
              <Button
                size={'small'}
                variant={'text'}
                onClick={() => {
                  removeNotExistingCartRecord({
                    variables: {
                      input:{
                        cartRecordId: item.id,
                        equipmentCount: item.equipmentCount
                      }
                    }
                  })
                }}
              >
                Удалить
              </Button>
            </Box>
          </Stack>


        </Card>
      ))}
    </>

  );
}

function CartDrawer(props: Props) {

  const {cartItems, setCartOpen, notExistingProducts, notExistingTotalCount} = useCart()
  const {currentSportObject} = useReactiveVar(sportObjectVar)
  const {setDialogOpen} = useNotExistentEquipment()


  return (
    <>
      <Box
        minWidth={320}
        minHeight={'100%'}
        display={'flex'}
        flexDirection={'column'}
        overflow={'hidden'}
        position={'relative'}
      >
        <AppBar
          position={'relative'}
          color='default'
          elevation={0}
        >
          <Toolbar
            disableGutters
            sx={{
              bgcolor: 'background.paper',
              borderBottomColor: 'divider',
              borderBottomWidth: 1,
              borderBottomStyle: 'solid'
            }}
          >
            <CardHeader title={'Корзина'}/>
          </Toolbar>
        </AppBar>
        <CardContent sx={{overflow: 'auto', height: '100%', flexGrow: 1, pr: 2, pl: 3, py: 3}}>

          { cartItems?.length > 0 &&
            <>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Typography
                  variant={'subtitle2'}
                  sx={{
                    mb: 1
                  }}
                >
                  Со склада ГБУ "Мосспортобъект"
                </Typography>
                <Typography
                  variant={'subtitle2'}
                  sx={{
                    mb: 1
                  }}
                >
                  {cartItems.length} шт.
                </Typography>
              </Stack>
              <Divider/>
            </>
          }
          <CartItemsList items={cartItems}/>

            <>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                mt={2}
              >
                <Typography
                  variant={'subtitle2'}
                  sx={{
                    my: 1
                  }}
                >
                  Ваши заявки
                </Typography>
                <Typography
                  variant={'subtitle2'}
                >
                  {notExistingTotalCount} шт.
                </Typography>
              </Stack>

              <Divider/>

              <Button
                variant={'text'}
                size={'small'}
                fullWidth={true}
                onClick={() => setDialogOpen(true)}
                sx={{
                  mb:2
                }}
              >
                Добавить заявку
              </Button>

            </>


          <CartItemsNotExistingCartRecordsList items={notExistingProducts}/>
        </CardContent>

        <Toolbar
          disableGutters
          sx={{
            bgcolor: 'background.paper',
            borderTopColor: 'divider',
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            py: 1,
            px: 2,
          }}
        >
          <Button
            disabled={cartItems?.slice()?.filter(item => item.sportObject.id === currentSportObject?.id || item.sportObject.id === Warehouse.id).length === 0 && notExistingProducts?.length === 0}
            component={Link}
            to={'/checkout'}
            sx={{ml: 'auto'}}
            size={'small'}
            variant={'contained'}
            onClick={() => setCartOpen(false)}
          >Оформить заказ</Button>
        </Toolbar>

      </Box>
    </>
  );
}

export default CartDrawer;
