import React, {useMemo} from 'react';
import {Box, Button, Card, CardActions, CardContent, Stack, Typography} from "@mui/material";
import dayjs from "dayjs";
import {getRentRangeText} from "../../@core/utils/declination";

interface Props {
  onSubmit: () => void
  cartTotalCount: number
  rentRange: {start: string, end: string}
}

function CheckoutSidebar(props: Props) {

  const {onSubmit, cartTotalCount, rentRange: rentRangeDates} = props

  const rentRange = getRentRangeText(rentRangeDates.start, rentRangeDates.end)

  return (
    <>
      <Card>
        <CardActions>
          <Button disabled={rentRangeDates.start === '' || rentRangeDates.end === ''  } fullWidth variant={'contained'} onClick={onSubmit}>Оформить заказ</Button>
        </CardActions>
        <CardContent>
          <Stack direction={'column'}>
            <Stack direction={'row'} spacing={1}>
              <Typography >Срок аренды</Typography>
              <Typography variant={'subtitle1'}>{rentRange === '' ? 'Не указано' : rentRange}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={1}>
              <Typography >Оборудование в заказе -</Typography>
              <Typography variant={'subtitle1'}>{cartTotalCount}</Typography>
            </Stack>
          </Stack>
        </CardContent>

      </Card>
    </>
  );
}

export default CheckoutSidebar;
