import React from 'react';
import {Helmet} from "react-helmet";
import {Box, Card, Container, Grid, Link, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {styled} from "@mui/material/styles";
import Banner from "../../views/home/Banner";
import Tabs from "../../views/home/Tabs";
import Products from "../../views/home/Products";
import {SportEquipmentEntity} from "../../@core/@graphql/types";
import ProductCard from "../../@core/components/shared-components/ProductCard";
import ProductsPage from "../products";


const CatalogTitleStyle = styled("div")(({theme}) => ({
  paddingTop: theme.spacing(2)
}))
const CatalogStyle = styled("div")(({theme}) => ({
  background: theme.palette.background.paper,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  marginLeft: theme.spacing(3),
  borderBottomLeftRadius: 20,
  borderTopLeftRadius: 20,
}))

interface Props {

}

function HomePage(props: Props) {


  return (
    <>
      <Helmet>
        <title>Московский спорт</title>
      </Helmet>

      <ProductsPage/>

      {/*<Banner/>*/}

      {/*<CatalogStyle>*/}
      {/*  <Container maxWidth={'xl'}>*/}
      {/*    <CatalogTitleStyle>*/}
      {/*      <Typography variant={'h1'} mb={2}>*/}
      {/*        Доступное оборудования рядом с вами*/}
      {/*      </Typography>*/}

      {/*      <Box*/}
      {/*        display={'flex'}*/}
      {/*        flexDirection={'row'}*/}
      {/*        justifyContent={'space-between'}*/}
      {/*        alignItems={'center'}>*/}
      {/*        <Tabs/>*/}


      {/*        <Typography variant={'subtitle2'} color={'primary'}>*/}
      {/*          <Link component={RouterLink} to={'/products'}>Всё оборудование</Link>*/}
      {/*        </Typography>*/}
      {/*      </Box>*/}

      {/*      <Products/>*/}

      {/*    </CatalogTitleStyle>*/}
      {/*  </Container>*/}
      {/*</CatalogStyle>*/}

      {/*<Box sx={{my:8}}>*/}
      {/*  <Container maxWidth={'xl'}>*/}
      {/*    <CatalogTitleStyle>*/}
      {/*      <Typography variant={'h1'} mb={4}>*/}
      {/*        Ещё одна красивая секция*/}
      {/*      </Typography>*/}


      {/*        <Grid container spacing={6}  my={4}  alignItems="stretch"*/}
      {/*              direction="row">*/}

      {/*          <Grid  item lg={3} md={6} sm={8} xs={12}>*/}
      {/*            <Card sx={{height:300, p: 3}}   />*/}
      {/*          </Grid>*/}
      {/*          <Grid  item lg={3} md={6} sm={8} xs={12}>*/}
      {/*            <Card sx={{height:300, p: 3}}   />*/}
      {/*          </Grid>*/}
      {/*          <Grid  item lg={3} md={6} sm={8} xs={12}>*/}
      {/*            <Card sx={{height:300, p: 3}}   />*/}
      {/*          </Grid>*/}
      {/*          <Grid  item lg={3} md={6} sm={8} xs={12}>*/}
      {/*            <Card sx={{height:300, p: 3}}   />*/}
      {/*          </Grid>*/}

      {/*        </Grid>*/}

      {/*    </CatalogTitleStyle>*/}
      {/*  </Container>*/}
      {/*</Box>*/}


    </>
  );
}

export default HomePage;
