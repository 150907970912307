import {Stack} from '@mui/material';
import React from 'react';
import CheckoutFormRentalTime from "./CheckoutForm/CheckoutFormRentalTime";
import CheckoutFormDeliveryAddress from "./CheckoutForm/CheckoutFormDeliveryAddress";
import CheckoutFormContactDetails from './CheckoutForm/CheckoutFormContactDetails';
import CheckoutFormAdditionalServices from './CheckoutForm/CheckoutFormAdditionalServices';
import CheckoutFormNotesForDelivery from "./CheckoutForm/CheckoutFormNotesForDelivery";
import {IUserContacts} from "../../@core/context/AuthContext";
import {VehicleTypeEnum} from "../../@core/@graphql/external-order/vehicle-type.enum";
import {useReactiveVar} from "@apollo/client";
import {EventType, sportObjectVar, Warehouse} from "../../@core/store/sportObject";
import useCart from "../../@core/hooks/useCart";


interface Props {
  onChangeRentDates: (value: { startRentDate: string, endRentDate: string }) => void
  onChangeAddress: (address: string) => void
  onChangeComments: (comments: string) => void
  onChangeContacts: (contacts: IUserContacts) => void
  comments: string
  onChangeVehicleType: (vehicleType: VehicleTypeEnum) => void
}

function CheckoutForm(props: Props) {
  const {eventType, currentSportObject} = useReactiveVar(sportObjectVar)
  const {cartItems} = useCart()

  const {
    onChangeRentDates,
    onChangeAddress,
    onChangeContacts,
    onChangeComments,
    comments,
    onChangeVehicleType
  } = props


  return (

    <Stack width={'100%'} spacing={4} direction={'column'} sx={{mb: 2}}>
      <CheckoutFormRentalTime
        onChangeRentDates={onChangeRentDates}
      />
      <CheckoutFormDeliveryAddress
        onChangeAddress={onChangeAddress}
      />
      <CheckoutFormContactDetails
        onChangeContacts={onChangeContacts}
      />
      {/*{eventType === EventType.internal && cartItems?.slice()?.filter(item => item.sportObject?.id === Warehouse.id).length === 0 ? null :*/}
      {/*  <CheckoutFormAdditionalServices*/}
      {/*    onChangeVehicleType={onChangeVehicleType}*/}
      {/*  />*/}
      {/*}*/}
      {/*<CheckoutFormProductsSummary/>*/}
      <CheckoutFormNotesForDelivery
        comments={comments}
        onChangeComments={onChangeComments}
      />
    </Stack>


  );
}

export default CheckoutForm;
