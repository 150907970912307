import React, {useEffect, useState} from 'react';
import {
  Box,
  Card,
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  Skeleton,
  Stack, TextField
} from "@mui/material";
import {SportEquipmentEntity, useGetSportEquipmentsLazyQuery} from "../../../@core/@graphql/types";
import {useSearchParams} from "react-router-dom";
import useCart from "../../../@core/hooks/useCart";
import ProductCard from "../../../@core/components/shared-components/ProductCard";
import useWishlist from "../../../@core/hooks/useWishlist";
import {useReactiveVar} from "@apollo/client";
import {sportObjectVar, useSportObjectVars, Warehouse} from "../../../@core/store/sportObject";

interface Props {

}

function FirstPageIcon() {
  return <>В начало</>
}

function NextPageIcon() {
  return <>Дальше</>
}

function ProductsList(props: Props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const {currentSportObject, selectedSportObjectIds} = useReactiveVar(sportObjectVar)
  const {setCount} = useSportObjectVars()

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    searchParams.set("page", String(value));
    setSearchParams(searchParams)
  };

  const page = Number(searchParams.get('page')) || 0

  const [getSportEquipments, {data, loading}] = useGetSportEquipmentsLazyQuery()
  const count = data?.getSportEquipments.count ? Math.floor(data.getSportEquipments.count / 24) : 0
  const [state, setState] = useState({
    fromDateAvailable: '',
    untilDateAvailable: '',
  });

  useEffect(() => {
    const skip = page * 24;
    const categoryId = searchParams.get('categoryId')
    const sportKindId = searchParams.get('sportKindId')
    const equipmentTypeId = searchParams.get('equipmentTypeId')
    const globalSearch = searchParams.get('global-search')

    getSportEquipments({
      variables: {
        input: {
          skip: skip ? skip : 0,
          take: 24,
          order: {'equipment.createdAt': 'DESC'},
          technicalCondition: 'WORKING',
          sportObjectIds: selectedSportObjectIds,
          availableForRent: true,
          ...(categoryId && {sportCategoryIds: [categoryId as string]}),
          ...(sportKindId && {sportKindIds: [sportKindId as string]}),
          ...(equipmentTypeId && {equipmentTypeIds: [equipmentTypeId as string]}),
          ...(globalSearch && {name: globalSearch}),
          ...(state.fromDateAvailable !== '' && {fromDateAvailable: state.fromDateAvailable}),
          ...(state.untilDateAvailable !== '' && {untilDateAvailable: state.untilDateAvailable}),
        }
      }
    })
  }, [searchParams, state, selectedSportObjectIds]);

  useEffect(() => {
    typeof data?.getSportEquipments?.allCount === "number" && setCount(data?.getSportEquipments?.allCount)
  }, [data]);



  const {addToCart, dropFromCart, checkIsInCart, getButtonLabel} = useCart()
  const {addToWishlist, dropFromWishlist, checkIsInWishlist} = useWishlist()

  const cartButtonHandler = (equipment: SportEquipmentEntity) => {
    const isInCart = checkIsInCart(equipment.id)
    if (isInCart) {
      dropFromCart(equipment.id)
    } else {
      addToCart(equipment)
    }
  };

  const favoriteButtonHandler = (equipment: SportEquipmentEntity) => {
    const isFavorite = checkIsInWishlist(equipment.id)
    if (isFavorite) {
      dropFromWishlist(equipment.id)
    } else {
      addToWishlist(equipment)
    }
  };


  return (
    <>
      <Card variant={'outlined'} sx={{px: 3, py: 2, mb:2}}>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Box
            sx={{
              opacity: .5
            }}
          >Доступно для аренды:</Box>
          <Box> с</Box>
          <TextField
            type="date"
            value={state.fromDateAvailable}
            onChange={({target:{value}})=>{
              setState(prev => ({...prev, fromDateAvailable: value}))
            }}
            size={'small'}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Box>
            по
          </Box>
          <TextField
            type="date"
            value={state.untilDateAvailable}
            onChange={({target:{value}})=>{
              setState(prev => ({...prev, untilDateAvailable: value}))
            }}
            size={'small'}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {/*<Box sx={{*/}
          {/*  flexGrow: 1*/}
          {/*}} />*/}
          {/*<Button*/}
          {/*  disabled={!(state.fromDateAvailable !== '' || state.untilDateAvailable !== '')}*/}
          {/*  variant={'contained'}*/}
          {/*  size={'small'}*/}
          {/*>*/}
          {/*  Применить фильтр*/}
          {/*</Button>*/}
        </Stack>
      </Card>
      <Card variant={'outlined'} sx={{ px: 2, py: 1}}>
        <Grid
          container
          spacing={1}
          sx={{mt: .2}}
          alignItems="stretch"
          direction="row"
        >
          {!loading && data?.getSportEquipments   ? (
            data?.getSportEquipments?.sportEquipments?.length > 0 && selectedSportObjectIds?.length > 0 ? (
              data?.getSportEquipments?.sportEquipments as SportEquipmentEntity[])?.map((item: SportEquipmentEntity) => {
                return (<Grid key={item.id} item lg={3} md={4} sm={6} xs={12}>
                  <ProductCard
                    title={item?.name}
                    inventoryNumber={item?.inventoryNumber}
                    images={item?.photos}
                    description={item?.description}
                    categoryLabel={item?.sportCategoryObject?.name}
                    sportKindLabel={item?.sportKindObject?.name}
                    equipmentTypeLabel={item?.equipmentTypeObject?.name}
                    sportObjectLabel={item?.sportObject?.name}
                    onButtonCartClick={() => cartButtonHandler(item)}
                    buttonLabel={getButtonLabel(item.id)}
                    productId={item.id}
                    isFavorite={checkIsInWishlist(item.id)}
                    onButtonFavoriteClick={() => favoriteButtonHandler(item)}
                  />
                </Grid>)
              }
            ) : (
              <Box
                minHeight={300}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                width={'100%'}
              >
                Тут ни чего нет
              </Box>
            )
          ) : (
            [0,1,2,3,4,5].map((item, index) => (
              <Grid key={index} item lg={3} md={4} sm={6} xs={12}>
                <Skeleton sx={{ height: 300 }} animation="wave" variant="rectangular" />
              </Grid>
            ))
          )
          }

        </Grid>

        {count > 0 && <Pagination
          sx={{mt: 2}}
          boundaryCount={1}
          color={'primary'}
          showFirstButton={page > 1}
          count={count === 0 ? 1 : count}
          shape="rounded"
          page={page}
          onChange={handleChangePage}
          hidePrevButton
          hideNextButton={page === count}
          renderItem={(item) => {
            return selectedSportObjectIds?.length > 0 && <PaginationItem {...item}
                            components={{
                              first: FirstPageIcon,
                              next: NextPageIcon,
                            }}
            />
          }
          }
        />}

      </Card>
    </>
  );
}

export default ProductsList;
