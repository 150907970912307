import {createContext} from "react";
import {SportEquipmentEntity} from "../../@graphql/types";

interface IWishlistContext {
  wishlistOpen: boolean
  setWishlistOpen: (state: boolean) => void
  addToWishlist: (equipment: SportEquipmentEntity) => void
  dropFromWishlist: (id: string) => void
  wishlistItems: SportEquipmentEntity[]
  wishlistTotalCount: number
  checkIsInWishlist: (id: string) => boolean
}

const defaultState = {
  addToWishlist: () => {},
  dropFromWishlist: () => {},
  wishlistOpen: false,
  wishlistItems: [],
  setWishlistOpen: () => {},
  wishlistTotalCount: 0,
  checkIsInWishlist: () => false,
};

export const WishlistContext = createContext<IWishlistContext>(defaultState);

