import React from 'react';

interface Props {

}

export function AccountSettings(props: Props) {

  return (
    <>AccountSettings</>
  );
}

