import React from 'react';
import {Avatar, Button, Card, Chip, Link, Stack, styled, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {ExternalOrderEntity} from "../../../@core/@graphql/types";
import dayjs from "dayjs";
import appConfig from "../../../configs/appConfig";
import {ExternalOrderStatusEnum} from "../../../@core/@graphql/external-order/external-order-status.enum";



const RequestItemWrapper = styled(Card)(({theme}) => ({}))
const RequestItemHeader = styled(Stack)(({theme}) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  justifyContent: 'space-between',
  cursor: 'pointer',
}))
const RequestItemBody = styled(Stack)(({theme}) => ({
  padding: theme.spacing(2),
  justifyContent: 'space-between'
}))
const RequestItemFooter = styled(Stack)(({theme}) => ({
  padding: theme.spacing(2),
}))



interface Props {
  item: ExternalOrderEntity
}

export function OrderItem({item}: Props) {
  const navigate = useNavigate()

  const orderItemClickHandler = () => {
    navigate('/my/orders/'+item.id)
  }


  function getStatusName(status: string) {
    switch (status){
      case ExternalOrderStatusEnum.Created : {
        return 'Ожидает отправления'
      }
      case ExternalOrderStatusEnum.InTransportService : {
        return 'Оборудование в пути'
      }
      case ExternalOrderStatusEnum.ReturnedIncomplete : {
        return 'Возвращено не полностью'
      }
      case ExternalOrderStatusEnum.ReturnedComplete : {
        return 'Возвращено'
      }
      default : {
        return ''
      }
    }
  }

  return (
    <RequestItemWrapper variant={'outlined'}>
      <RequestItemHeader direction={'row'} onClick={orderItemClickHandler}>
        <Stack direction={'column'} spacing={1}>
          <Typography variant={'h2'}>
            Заказ от {dayjs(Number (item.createdAt)).format('DD MMM')}
          </Typography>
          <Typography  variant={'caption'}>
            <Link href={'#'}>{item.orderNumber}</Link>
          </Typography>
        </Stack>

        <Stack direction={'row'} spacing={2} alignItems={'center'} alignSelf={'self-start'}>
          {/*<Typography variant={'h2'}>*/}
          {/*  35 200 ₽*/}
          {/*</Typography>*/}
          <Typography variant={'body1'}>
            {getStatusName(item.status)}
          </Typography>
        </Stack>
      </RequestItemHeader>
      <RequestItemBody direction={'row'}>
        <Stack direction={'column'} spacing={1}>
          <Stack direction={'row'} spacing={2} alignItems={'center'} alignSelf={'self-start'}>

            <Typography variant={'h5'}>
              Статус доставки
            </Typography>
            <Chip label={getStatusName(item.status)} size={'small'}/>

          </Stack>
          <Typography variant={'body1'}>
            Предполагаемая дата доставки: {dayjs(Number (item.startEventDate)).format('DD MMM')}
          </Typography>
        </Stack>

        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          {item.records.map((record) => (
            appConfig?.serverUrl && <Avatar key={record.id} src={appConfig?.serverUrl + record?.equipment?.photos?.[0]?.avatarUrl || ''} />
          ))}
        </Stack>
      </RequestItemBody>
      <RequestItemFooter direction={'row'}>
        <Button variant={'outlined'} size={'small'} color={'secondary'} onClick={orderItemClickHandler} >Детали заказа</Button>
      </RequestItemFooter>
    </RequestItemWrapper>
  );
}
