import {Outlet, useLocation} from "react-router-dom";
import React, {ReactElement} from "react";
import useAuth from "../../../hooks/useAuth";
import LoginPage from "../../../../pages/login";
import Preloader from "../../../components/Preloader";


interface Props {
  children?: ReactElement<any>
}

function PrivateRoute({children}: Props) {
  const {user, isLoaded} = useAuth();
  const location = useLocation();
  const url = new URLSearchParams();
  url.set("redirect", location.pathname + location.search);

  if(!isLoaded){
    return <Preloader/>
  }

  return (user && isLoaded) ? (
    children ? children : <Outlet/>
  ) : <LoginPage/>
}

export default PrivateRoute;
