import React from 'react';
import {Outlet} from "react-router-dom";
import Page from "../../@core/components/Page";


interface Props {

}
function OrdersPage(props: Props) {

  return (
    <Page title={'Мои Заказы'}>
      <Outlet/>
    </Page>
);
}


export default OrdersPage;
