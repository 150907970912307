import React from 'react';
import {styled} from "@mui/material/styles";
import {Avatar, Box, Stack, Typography} from "@mui/material";

const Wrapper = styled(Box)(({theme})=>({
  borderBottom: '1px solid',
  borderColor: theme.palette.divider,
  padding: theme.spacing(2)
}))

interface Props {

}

export function ChatListItem(props: Props) {

  return (
    <Wrapper className={'chat-list__item'} component={'li'}>
      <Stack direction={'row'} spacing={2}>
        <Avatar sx={{
          width: 30,
          height: 30,
        }}/>
        <Stack direction={'column'}>
          <Typography variant={'subtitle2'}>
            Заказы
          </Typography>
          <Typography variant={'caption'}>
            Изменения статусов заказов и возвратов
          </Typography>
        </Stack>

        <Typography variant={'body2'}>
          02.09
        </Typography>
      </Stack>
    </Wrapper>
  );
}
