import React, {useEffect, useState} from 'react';
import {Box, Card, Stack, TextField, Typography} from "@mui/material";
import dayjs from 'dayjs';
import {declDays, declHours, getRentRangeText} from "../../../@core/utils/declination";


interface Props {
  onChangeRentDates: (value: { startRentDate: string, endRentDate: string }) => void
}

function CheckoutFormRentalTime(props: Props) {
  const { onChangeRentDates} = props

  const [state, setState] = useState({
    eventDates: {
      startEventDate: '',
      endEventDate: '',
    },
    rentDates: {
      startRentDate: '',
      endRentDate: '',
    }
  });

  useEffect(() => {
    onChangeRentDates(state.rentDates)
  }, [state.rentDates]);



  return (
    <>
      <Card sx={{
        p: 3,
        width: '100%'
      }}>
        <Typography variant={'h4'} mb={3}>
          Укажите срок аренды
          {getRentRangeText(state.rentDates.startRentDate, state.rentDates.endRentDate)}

        </Typography>

        <Stack direction={'column'} spacing={2} width={'100%'}>
          <Typography variant={'caption'} mt={-1} mb={3} component={'div'}>
              Пожалуйста укажите дату и время подачи автомобиля на объект спорта, а также дату и время возврата оборудования
            </Typography>

            <Stack direction={'row'} spacing={4}>
              <TextField
                required
                fullWidth
                size={'small'}
                InputLabelProps={{
                  shrink: true,
                }}
                type="datetime-local"
                label="Дата подачи автомобиля на объект спорта"
                value={state.rentDates.startRentDate}
                onChange={({target:{value}})=>{
                  setState(prevState => ({...prevState, rentDates: {...prevState.rentDates, startRentDate: value}}))
                }}
                error={state.rentDates.startRentDate === ''}
                helperText={state.rentDates.startRentDate === '' && 'Укажите дату подачи автомобиля'}
              />
              <TextField
                required
                fullWidth
                size={'small'}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min: state.rentDates.startRentDate
                  }
                }}
                type="datetime-local"
                label="Дата возврата оборудования"
                value={state.rentDates.endRentDate}
                onChange={({target:{value}})=>{
                  setState(prevState => ({...prevState, rentDates: {...prevState.rentDates, endRentDate: value}}))
                }}
                error={state.rentDates.endRentDate === ''}
                helperText={state.rentDates.endRentDate === '' && 'Укажите дату возврата оборудования'}
              />
            </Stack>
        </Stack>

      </Card>

    </>
  );
}

export default CheckoutFormRentalTime;
