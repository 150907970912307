type AppConfig = {
  serverUrl?: string
  pollIntervals:{
    allSportObjects: number
    sportSubZonesByZoneId: number
    sportEquipments: number
    equipmentRequests: number
    equipmentRelocationRequests: number
    allEquipmentRelocationRequests: number
    serviceJobs: number
    dictionaries: number
    users: number
  }
}

const appConfig: AppConfig = {
  // ** server url
  serverUrl: process.env.REACT_APP_SERVER_URL,

  // ** pollIntervals
  pollIntervals:  {
    // Спортобъекты
    allSportObjects: 500000,
    // Спорт подзоны
    sportSubZonesByZoneId: 500000,
    // Оборудование
    sportEquipments: 5000,
    // Заявки на оборудование
    equipmentRequests: 100000,
    // Заявки на перемещение оборудования
    equipmentRelocationRequests: 10000,
    // Заявки на перемещение оборудования (Избыточный запрос)
    allEquipmentRelocationRequests: 10000,
    // План обслуживания
    serviceJobs: 500000,
    // Виды спорта и тиы оборудования
    dictionaries: 500000,
    // Список пользователей
    users: 500000,
  }
}

export default appConfig
