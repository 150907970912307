import React from 'react';
import {styled} from "@mui/material/styles";
import {Box, Typography} from "@mui/material";
import {ChatListItem} from "./chat-list-item";

const ChatListWrapper = styled(Box)(({theme})=>({
  borderRight: '1px solid',
  borderColor: theme.palette.divider,
}))

const ChatListHeader = styled(Box)(({theme})=>({
  borderBottom: '1px solid',
  borderColor: theme.palette.divider,
  padding: theme.spacing(2)
}))

const ChatListContent = styled(Box)(({theme})=>({
  '& .chat-list__item:last-of-type':{
    border: 'none'
  }
}))



interface Props {

}

export function ChatList(props: Props) {

  return (
    <ChatListWrapper className={'chat-list'}>
      <ChatListHeader className={'chat-list__header'}>
        <Typography variant={'h3'}>Сообщения</Typography>
      </ChatListHeader>
      <ChatListContent component={'ul'} className={'chat-list__content'}>
        <ChatListItem/>
        <ChatListItem/>
        <ChatListItem/>
        <ChatListItem/>
      </ChatListContent>
    </ChatListWrapper>
  );
}
