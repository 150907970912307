import React, {useState} from 'react';
import SearchIcon from "@mui/icons-material/Search";
import {TextField, useTheme} from "@mui/material";
import {useSearchParams} from "react-router-dom";


interface Props {

}

function Search(props: Props) {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams()


  const handleChangeSearchInput = (value: string) => {
    if (value === '') {
      searchParams.delete('global-search')
      setSearchParams(searchParams)
      return
    }
    searchParams.set('global-search', value)
    setSearchParams(searchParams)
  };

  return (
    <TextField
      placeholder={'Поиск оборудования ...'}
      size="small"
      value={searchParams.get('global-search') || ''}
      InputProps={{
        startAdornment: <SearchIcon fontSize={'small'} style={{marginRight: theme.spacing(1)}}/>,
      }}
      fullWidth
      onChange={({target: {value}}) => handleChangeSearchInput(value)}
      type={'search'}
    />
  );
}

export default Search;
