import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Box, keyframes} from "@mui/material";
import {styled} from "@mui/material/styles";


const rotate1 = keyframes`
  to {
    transform: rotate(405deg)
  }
`;
const rotate2 = keyframes`
  to {
    transform: rotate(225deg)
  }
`;
const rotate3 = keyframes`
  to {
    transform: rotate(315deg)
  }
`;

const ldsCircle = keyframes`
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
`;

const RootStyled = styled('div')(({theme}) => ({
  display: 'flex',
  width: '100%',
  height: '100vh',
  backgroundColor: theme.palette.background.default,
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 99999999,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}))
const PreloaderStyled = styled('div')(({theme}) => ({
  display: 'flex',
  transform: 'translateZ(1px)',
  flexDirection: 'column',
}))
const LogoStyled = styled('div')(({theme}) => ({
  width: 150,
  height: 150,
  position: 'relative',
  "& svg g path": {
    fill: '#fff'
  },
  "& svg, div": {
    position: "absolute",
    width: '100%',
    height: '100%',
  },
  "& div": {
    width: '80%',
    height: '80%',
    margin: 20,
    borderRadius: '20%',
    transform: 'rotate(45deg)',
    mixBlendMode: 'multiply',
    animation: '2s cubic-bezier(0.66, -0.7, 0.27, 1.6) infinite',
    "&:nth-of-type(1)": {
      backgroundColor: '#29ffff',
      animationName: rotate1,
    },
    "&:nth-of-type(2)": {
      backgroundColor: '#ff65ff',
      animationName: rotate2,
    },
    "&:nth-of-type(3)": {
      backgroundColor: '#ffc',
      animationName: rotate3,
    },
  }
}))

const TextStyled = styled('div')(({theme}) => ({
  width: 64,
  display: 'block',
  margin: '18px auto',
  '& > svg > g > path': {
    fill: theme.palette.primary.main
  }
}))


const Preloader = () => {

  return (
    <RootStyled>
      <PreloaderStyled>

        <LogoStyled>
          <div/>
          <div/>
          <div/>
          <svg viewBox="0 0 200 200">
            <g transform="translate(5.000000,110.000000) scale(0.100000,-0.100000)">
              <path
                d="M884,395c0-184-3-223-20-277C827-4,731-117,623-169c-55-26-58-29-58-64c-1-45,6-46,74-14C745-198,850-99,898-3c9,17,18,32,21,32c2,0,16-27,31-60c42-93,48-94,86-12c18,39,35,71,38,72c3,0,16-21,30-47c54-102,168-197,289-243l51-20v38c0,37-2,39-62,68c-78,36-185,139-222,213c-44,86-56,165-56,379v192h-35h-35V417c0-215-16-358-40-358c-18,0-30,137-30,338v212h-40h-40V395z"/>
              <path
                d="M750,254C742,151,704,69,633,5c-28-25-55-46-60-46s-9-18-9-40c0-48,12-50,66-14c127,85,194,205,194,349v73l-34,4l-33,4L750,254z"/>
              <path
                d="M1164,271c0-73,23-164,57-221c43-73,136-155,201-175c20-7,22-4,22,31c0,34-5,41-45,66c-93,59-146,155-153,275l-5,82h-38h-39V271z"/>
              <path
                d="M844-171c-67-68-162-129-237-151c-41-12-43-14-43-51c0-21,5-38,10-38c6,0,46,14,89,30c85,33,193,104,254,167l38,40l-21,36c-12,21-23,36-23,35C910-104,880-134,844-171z"/>
              <path
                d="M1062-142c-22-38-14-52,69-121c65-54,148-100,229-128c80-28,86-27,82,16c-3,34-7,38-43,49c-78,22-186,88-252,152c-36,34-66,63-67,63C1080-111,1071-125,1062-142z"/>
            </g>
          </svg>
        </LogoStyled>
        <TextStyled>
          <svg viewBox="0 0 29.56 8.56">
            <g>
              <path
                className="st2"
                d="M1.6,6.65c0.12,0.47,0.51,0.73,1.12,0.73c0.6,0,0.82-0.22,0.82-0.57c0-0.38-0.25-0.53-1.1-0.73
                            C0.52,5.6,0.21,5.01,0.21,4.18c0-0.87,0.61-1.78,2.39-1.78c1.79,0,2.39,0.98,2.44,1.77H3.49c-0.05-0.22-0.2-0.63-0.96-0.63
                            c-0.54,0-0.71,0.24-0.71,0.5c0,0.29,0.18,0.43,1.14,0.66C4.92,5.16,5.2,5.83,5.2,6.7c0,0.98-0.74,1.86-2.54,1.86
                            C0.87,8.56,0.13,7.7,0,6.65H1.6z"
              />
              <path
                className="st2"
                d="M11.8,5.47c0,1.78-1.07,3.09-2.93,3.09c-1.83,0-2.89-1.3-2.89-3.06c0-1.82,1.1-3.1,2.97-3.1
                            C10.68,2.39,11.8,3.62,11.8,5.47z M7.63,5.48c0,1.13,0.49,1.81,1.27,1.81c0.8,0,1.24-0.67,1.24-1.8c0-1.23-0.45-1.82-1.27-1.82
                            C8.12,3.67,7.63,4.23,7.63,5.48z"
              />
              <path
                className="st2"
                d="M13.15,8.42V3.76h-0.86V2.53h0.86V2.01c0-1.22,0.6-2.01,1.88-2.01c0.27,0,0.61,0.02,0.76,0.07v1.29
                            c-0.09-0.02-0.21-0.03-0.34-0.03c-0.48,0-0.66,0.18-0.66,0.81v0.39h1.03v1.23h-1.03v4.67H13.15z"
              />
              <path
                className="st2"
                d="M15.66,2.53h0.91v-1.6h1.63v1.6h1.15v1.23H18.2v2.91c0,0.45,0.14,0.62,0.62,0.62c0.09,0,0.25,0,0.37-0.02v1.12
                            c-0.32,0.11-0.77,0.11-1.1,0.11c-1.15,0-1.53-0.61-1.53-1.65v-3.1h-0.91V2.53z"
              />
              <path
                className="st2"
                d="M20.3,4.13c0-0.59,0-1.11-0.01-1.6h1.6c0.02,0.13,0.04,0.72,0.04,1.03c0.26-0.67,0.89-1.17,1.89-1.17v1.56
                            c-1.18-0.03-1.89,0.29-1.89,1.9v2.57H20.3V4.13z"
              />
              <path
                className="st2"
                d="M29.48,6.9c0,0.56,0.05,1.38,0.08,1.52H28C27.95,8.31,27.92,8,27.92,7.85c-0.21,0.33-0.61,0.71-1.66,0.71
                            c-1.42,0-2.02-0.93-2.02-1.85c0-1.34,1.08-1.96,2.8-1.96c0.29,0,0.64,0,0.84,0V4.39c0-0.42-0.15-0.83-0.91-0.83
                            c-0.69,0-0.84,0.29-0.91,0.72h-1.55c0.09-1.05,0.74-1.89,2.53-1.88c1.58,0.01,2.44,0.63,2.44,2.03V6.9z M27.89,5.74
                            c-0.14,0-0.45,0-0.67,0c-0.98,0-1.35,0.3-1.35,0.87c0,0.43,0.26,0.79,0.85,0.79c1.04,0,1.16-0.72,1.16-1.5V5.74z"
              />
            </g>
          </svg>
        </TextStyled>
        <Box display={'flex'} mx={'auto'} sx={{opacity: .5}}>Мосспортобъекты...</Box>
      </PreloaderStyled>
    </RootStyled>
  );
};

Preloader.propTypes = {};

export default Preloader;
