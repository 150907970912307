import React from 'react';
import {Link as RouterLink} from "react-router-dom";
import Logo from "../shared-components/Logo";
import {Link, Typography, useTheme} from "@mui/material";
import {styled} from "@mui/material/styles";

interface Props {
  brandName?: string
}

const LinkStyle = styled(Link)(({theme}) => ({
  textDecoration: 'none',
  alignItems: 'center',
  display: 'inline-flex',
  fontWeight: theme.typography.fontWeightBold,
  whiteSpace: 'nowrap'
})) as typeof Link

const Logotype = styled(Logo)(({theme}) => ({
  marginRight: theme.spacing(2),
  padding: theme.spacing(.5),
  width: 32,
  height: 32,
  borderRadius: 8,
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: theme.palette.primary.main,
  fill: theme.palette.primary.main,
}))

function Brand(props: Props) {
  const theme = useTheme();
  const {
    brandName = 'Московский спорт'
  } = props


  return (
    <LinkStyle
      component={RouterLink}
      to="/"
    >
      <Logotype/>
      <Typography variant={'inherit'} color={'text.primary'}>{brandName}</Typography>
    </LinkStyle>
  );
}

export default Brand;
