import React, {FormEvent, useEffect, useRef, useState} from 'react';
import {Avatar, Box, IconButton, Stack, TextField, Typography} from "@mui/material";
import {lighten, styled} from "@mui/material/styles";
import {
  ExternalOrderMessageEntity,
  GetMessagesByIdDocument,
  useGetMessagesByIdQuery,
  useSendMessageMutation
} from "../../@core/@graphql/types";
import {sortByCreatedAt} from "../../@core/utils/sortByCreatedAt";
import Iconify from "../../@core/components/Iconify";
import {Message} from "./Message";
import {AnimatePresence, motion} from "framer-motion";


interface Props {
  chatId?: string
}

export function Messages(props: Props) {
  const {chatId} = props

  const [messages, setMessages] = useState<ExternalOrderMessageEntity[]>([]);
  const [polling, setPolling] = useState(0);
  const [sendMessage] = useSendMessageMutation()
  const inputRef = useRef<HTMLDivElement | null>(null);
  const messageEl = useRef<HTMLDivElement | null>(null);

  const {data} = useGetMessagesByIdQuery({
    variables: {
      orderId: Number(chatId)
    },
    skip: !chatId,
    pollInterval: polling
  })

  useEffect(() => {
    if (chatId) {
      setPolling(1000)
    } else {
      setPolling(0)
    }
  }, [chatId]);

  useEffect(() => {
    if (data && data?.getOrderById?.messages) {
      setMessages(data.getOrderById.messages.slice().sort(sortByCreatedAt) as ExternalOrderMessageEntity[])
    }
  }, [data]);

  useEffect(() => {
    if (messageEl?.current) {
      messageEl.current.addEventListener('DOMNodeInserted', event => {
        const {currentTarget: target}: any = event;
        target.scroll({top: target.scrollHeight, behavior: 'smooth'});
      });
    }
  }, [messageEl, messages])

  function sendMessageHandler(event: FormEvent<HTMLDivElement>) {
    event.preventDefault()
    if (inputRef?.current) {
      const target: any = inputRef.current?.getElementsByTagName('textarea')[0]
      const message: string = target.value as string

      sendMessage({
        refetchQueries: [
          {
            query: GetMessagesByIdDocument,
            variables: {
              orederId: chatId
            }
          }
        ],
        variables: {
          input: {
            message: message,
            externalOrderId: chatId + ''
          }
        }
      }).finally(() => {
        target.value = ''
      })
    }

  }

  const handleKeyDown = (event: any) => {
    if (event.which === 13 && event.altKey) {
      sendMessageHandler(event);
      event.preventDefault();
    }
  };

  return (
    chatId ? (
      <Wrapper direction={'column'}>
        <Header
          direction={'row'}
          spacing={2}
          alignItems={'center'}
        >
          <Stack direction={'column'}>
            <Typography variant={'subtitle1'}>
              Заказ № {data?.getOrderById.orderNumber}
            </Typography>
          </Stack>
        </Header>
        <Content>
          <Box
            className={'messages-container'}
            ref={messageEl}
          >
            {messages.map((message) => (
              <AnimatePresence
                key={message.id}
              >

                <motion.div
                  initial={{opacity: 0, y: 20}}
                  animate={{opacity: 1, y: 0}}
                  exit={{opacity: 0, y: 20}}
                  transition={{duration: 0.3}}
                >
                  <Message message={message}/>
                </motion.div>
              </AnimatePresence>
            ))}

          </Box>
        </Content>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            width: '100%',
            py: 2,
            px: 4,
          }}
          component={'form'}
          onSubmit={(e: FormEvent<HTMLDivElement>) => sendMessageHandler(e)}
        >
          <Box sx={{mr: 1}}>
          </Box>
          <TextField
            ref={inputRef}
            onKeyDown={handleKeyDown}
            multiline
            minRows={3}
            maxRows={10}
            variant='filled'
            placeholder={'Введите сообщение'}
            fullWidth
            autoFocus
            size={'small'}
          />
          <IconButton
            color='inherit'
            sx={{ml: 1}}
            type={"submit"}
          >
            <Iconify icon={'carbon:send-filled'}/>
          </IconButton>
        </Box>
      </Wrapper>
    ) : null
  );
}

const Wrapper = styled(Stack)(({theme}) => ({
  height: '100%',

}))


const Header = styled(Stack)(({theme}) => ({
  padding: theme.spacing(3),
  borderBottom: '1px solid',
  borderColor: theme.palette.divider,
}))

const Content = styled(Stack)(({theme}) => ({
  backgroundColor: lighten(theme.palette.background.default, .5),
  height: '100%',
  position: 'relative',
  ' .messages-container': {
    padding: theme.spacing(2),
    overflowY: 'auto',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  },

}))
