import {createContext, Dispatch} from "react";
import {UserEntity} from "src/@core/@graphql/types";

export type UserContactsInput = {
  fio: string
  phone: string
  position: string
}
export type UserAddressesInput = {
  region: string,
  city: string,
  street: string,
  building: string,
  apartment?: string,
  entrance?: string,
  floor?: string,
  intercom?: string,
}

export interface IUserContacts {
  id: string
  fio: string
  phone: string
  position: string
}

export interface IUserAddresses {
  id:string
  region: string,
  city: string,
  street: string,
  building: string,
  apartment?: string,
  entrance?: string,
  floor?: string,
  intercom?: string,
}

interface IAuthContext {
  user: UserEntity | null;
  token: string | null;
  setUser: Dispatch<any>;
  setToken: (tokenData: string | null) => void;
  logOut: () => void;
  isLoaded:boolean;
  userAddresses: IUserAddresses[] | null
  addUserAddress: (input: UserAddressesInput) => void
  updateUserAddress: (input: IUserAddresses) => void
  removeUserAddress: (id: string) => void
  userContacts:IUserContacts[] | null
  addUserContacts: (input: UserContactsInput) => void
  updateUserContacts: (userContacts: IUserContacts) => void
  removeUserContacts: (id: string) => void
}

const defaultState = {
  user: null,
  userContacts: [],
  userAddresses: [],
  token: null,
  setUser: () => {
  },
  setToken: () => {
  },
  logOut: () => {
  },
  addUserAddress: () => {
  },
  updateUserAddress: () => {
  },
  removeUserAddress: () => {
  },
  addUserContacts: () => {
  },
  updateUserContacts: () => {
  },
  removeUserContacts: () => {
  },
  isLoaded:false
};

export const AuthContext = createContext<IAuthContext>(defaultState);

