import React, {useState} from 'react';
import Avatar from "@mui/material/Avatar";
import {darken} from "@material-ui/core";
import Badge from "@mui/material/Badge";
import {badgeClasses, IconButton, Tooltip, useTheme} from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import {styled} from "@mui/material/styles";
import Iconify from "../Iconify";
import {useNavigate} from "react-router-dom";


const BadgeStyle = styled(Badge)(({theme}) => ({
  justifyContent: 'center',
  alignItems: 'center',
  [`& .${badgeClasses.anchorOriginBottomRight}`]: {
    fontSize: '10px',
    fontWeight: theme.typography.fontWeightLight,
    right: 5,
    position: 'relative',
    top: 'initial',
    bottom: 'initial',
    left: 'initial',
    transform: 'none',
  }
})) as typeof Badge

interface Props {
  count?: number
}

function RequestsPanel(props: Props) {
  const theme = useTheme()
  const navigate = useNavigate()

  const [count, setCount] = useState(0);

  const handelClick = () => {
    navigate('my/orders')
  }

  return (
    <>
      <Tooltip title={'Заказы'}>
        <BadgeStyle
          badgeContent={count }
          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
          color={'secondary'}
        >

          <IconButton size={'small'} sx={{p:0}} onClick={() => handelClick()} >
            <Avatar
              alt='Wish list'
              sx={{
                width: 28,
                height: 28,
                bgcolor: darken(theme.palette.background.default, .05),
                color: 'primary.main',
                fontSize: "16px"
              }}
            >
              <Iconify icon={'raphael:package'} color={count > 0 ? 'inherit' : 'text.secondary'} fontSize={'inherit'}/>
            </Avatar>
          </IconButton>
        </BadgeStyle>
    </Tooltip>
    </>
  );
}

export default RequestsPanel;
