import React from 'react';
import Page from "../../@core/components/Page";
import {Outlet} from "react-router-dom";

interface Props {

}

function ChatPage(props: Props) {

  return (
    <Page title={'Сообщения'}>
      <Outlet/>
    </Page>
  );
}

export default ChatPage;
