import React, {FC, useEffect, useState} from 'react';
import {styled} from "@mui/material/styles";
import {Box, Typography} from "@mui/material";
import ChatListItem from "./chat-list-item";
import {ExternalOrderEntity, useGetMessagesQuery, useGetOrdersQuery} from "../../../@core/@graphql/types";
import {useLocation} from "react-router";

const ChatListWrapper = styled(Box)(({theme})=>({
  borderRight: '1px solid',
  borderColor: theme.palette.divider,
  height:'100%',
  position: 'absolute',
  width: '100%',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}))

const ChatListHeader = styled(Box)(({theme})=>({
  borderBottom: '1px solid',
  borderColor: theme.palette.divider,
  padding: theme.spacing(2)
}))

const ChatListContent = styled(Box)(({theme})=>({
  height:'calc(100% - 55px)',
  overflowY: 'auto'
}))


export const ChatList: FC = () => {

  const location = useLocation()
  const [polling, setPolling] = useState(0);

  const {data} = useGetMessagesQuery({
    variables:{
      input: {
        statuses: []
      }
    },
    pollInterval: polling
  })

  useEffect(() => {
    if (location.pathname.includes('chat')) {
      setPolling(1000)
    } else {
      setPolling(0)
    }
  }, [location]);


  return (
    <ChatListWrapper className={'chat-list'}>
      <ChatListHeader className={'chat-list__header'}>
        <Typography variant={'h3'}>Сообщения</Typography>
      </ChatListHeader>
      <ChatListContent component={'div'} className={'chat-list__content'}>
        {
          data?.getOrders?.map((order) => (
            <ChatListItem key={order.id} order={order as ExternalOrderEntity}/>
          ))
        }
      </ChatListContent>
    </ChatListWrapper>
  );
};
