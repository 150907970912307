import React, {useState} from 'react';
import useCart from "../../@core/hooks/useCart";
import {Link, useSearchParams} from "react-router-dom";
import {SportEquipmentEntity} from "../../@core/@graphql/types";
import appConfig from "../../configs/appConfig";
import {Box, Button, Card, CardActions, CardContent, Container, Grid, Stack, Typography} from "@mui/material";
import {CartItemsList} from "../../views/cart";
//@ts-ignore
import {ReactComponent as Cart} from "../../@core/assets/empty-shoping-cart.svg";

interface Props {

}

function CartPage(props: Props) {
  const {cartItems, setCartOpen, dropFromCart, selectedProducts, setSelectedProducts} = useCart()
  const [searchParams, setSearchParams] = useSearchParams()

  function getEquipmentPhoto(equipment: SportEquipmentEntity) {
    if (equipment?.mainPhoto?.url) {
      return `${appConfig?.serverUrl}${equipment?.mainPhoto?.url}`
    }
    if (equipment?.photos?.[0]?.url) {
      return `${appConfig?.serverUrl}${equipment?.photos?.[0]?.url}`
    }
    return "static/logo.svg";
  }

  return (
    <>
      <Container maxWidth={'xl'} sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        mb: 8
      }}>

        <Stack direction={'row'} spacing={1} my={4} alignItems={'center'}>
          <Typography variant={'h3'} >Корзина </Typography>
          <Typography variant={'subtitle2'} >({cartItems.length})</Typography>
        </Stack>

        <Grid container spacing={4} >
          <Grid item lg={9}>
            {cartItems?.length === 0 && <Stack height={'100%'} alignItems={'center'} justifyContent={'center'} p={3} >

              <Cart width={'150'} />

              <Typography variant={'h2'} >В корзине ничего нет</Typography>
              <Typography variant={'body1'} sx={{mb:2}} >Перейдите в каталог и добавьте обрудование в корзину</Typography>
              <Button  component={Link} to={'/'}  variant={'contained'}>Перейти в каталог</Button>
            </Stack>}

            <Stack direction={'column'} spacing={4} >
              <CartItemsList
                items={cartItems}
                // selectable={true}
                // onSelectChange={(newValues: SportEquipmentEntity[]) => setSelectedProducts(newValues)}
                fullWidth={true}
              />
            </Stack>
          </Grid>
          <Grid item lg={3}>
            {/*<Card>*/}
              <CardActions>
                <Button disabled={cartItems?.length === 0}  component={Link} to={'/checkout'} fullWidth variant={'contained'}>Перейти к оформлению</Button>
              </CardActions>
              {/*<CardContent>*/}
              {/*  Какой то блок для быстрого подтверждения с общей информацией по всем заявкам*/}
              {/*</CardContent>*/}

            {/*</Card>*/}
          </Grid>

        </Grid>



      </Container>
    </>
  );
}

export default CartPage;
