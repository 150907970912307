import React, {FC, useEffect} from 'react';
import {
  Box,
  Button, ButtonBase,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid, IconButton, Stack,
  TextField, Tooltip, Typography
} from "@mui/material";
import {useNavigate} from 'react-router-dom';
import {useLocation} from "react-router";
import Page from "./Page";
import {ArrowBack, Settings} from "@mui/icons-material";
import {
  GetCartDocument, ImageStoreEntity,
  SportEquipmentEntity,
  useAddNotExistingEquipmentRecordToCartMutation,
  useAddToCartMutation
} from "../@graphql/types";
import {client} from "../../index";
import useCart from "../hooks/useCart";
import UploaderService from "../API/UplodaService";
import appConfig from "../../configs/appConfig";
import useNotExistentEquipment from "../hooks/useNotExistentEquipment";
import CardMedia from "@mui/material/CardMedia";
import Iconify from "./Iconify";

interface Props {

}

const INITIAL_STATE = {
  description: '',
  equipmentCount: 1,
  images: [] as ImageStoreEntity[],
  name: '',
}
const ComponentsSelectionDialog: FC<Props> = (props: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {dialogOpen, setDialogOpen} = useNotExistentEquipment()

  const [state, setState] = React.useState(INITIAL_STATE);

  useEffect(() => {
    if (!dialogOpen) {
      setState(INITIAL_STATE)
    }
  }, [dialogOpen])

  // мутация для добавления в корзину несуществующего товара
  const [addNotExistingEquipmentRecord] = useAddNotExistingEquipmentRecordToCartMutation()
  // мутация для добавления в корзину товара
  const [addProductToCart] = useAddToCartMutation()

  const cart = useCart()


  function validate() {
    return state.name.length > 0 && state.equipmentCount > 0
  }

  function submitHandler() {
    if (!validate()) return

    addNotExistingEquipmentRecord({
      variables: {
        input: {
          description: state.description,
          equipmentCount: state.equipmentCount,
          imageIds: state.images.map(image => image.id),
          name: state.name,
        }
      }
    }).then((res) => {
      cart.setCartOpen(true)
      setTimeout(() => {
        onClose()
      }, 100)

    })
  }

  function onClose() {
    setDialogOpen(false)
  }


  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
    >
      <Page title={'Подача заявки на поиск оборудования'}>
        <Container
          maxWidth={'xl'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            mb: 4
          }}
        >

          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            alignContent={'center'}
            width={'100%'}
            spacing={2}
            sx={{
              mt: 3,
              mb: 3,
            }}
          >
            <Typography
              variant={'h2'}
              component={'div'}
            >
              Заявка на подбор оборудования
            </Typography>
            <Box>
              <IconButton
                size={'small'}
                onClick={() => onClose()}
              >
                <Iconify
                  icon={'mdi:close'}
                />
              </IconButton>
            </Box>

          </Stack>
          <Typography
            variant={'body1'}
            sx={{
              mt: 1,
              mb: 3,
              fontSize: '1.1rem'
            }}
            gutterBottom={true}
          >
            Не смогли найти нужное оборудование? Заполните форму и мы подберем его для вас!
          </Typography>

          <Card
            variant={'outlined'}
            sx={{
              p: 3,
            }}
          >
            <Grid
              container
              spacing={2}
            >

              <Grid
                item
                xs={12}
              >
                <Typography
                  variant='h6'
                  gutterBottom
                  color={'text.secondary'}
                >
                  Основная информация
                </Typography>
                <TextField
                  label={'Название оборудования'}
                  fullWidth
                  size={'small'}
                  variant={'outlined'}
                  value={state.name}
                  onChange={(e) => {
                    setState({...state, name: e.target.value})
                  }}
                  InputProps={{
                    sx: {
                      fontSize: '1.4rem'
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  label={'Описание'}
                  fullWidth
                  size={'small'}
                  variant={'outlined'}
                  multiline
                  rows={4}
                  value={state.description}
                  onChange={(e) => {
                    setState({...state, description: e.target.value})
                  }}
                />
              </Grid>

            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography
                variant='h6'
                gutterBottom
                color={'text.secondary'}
                mt={2}
              >
                Фотографии
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{
                  minHeight: 100,
                }}
              >
                {/* здесь должны отображаться фотографии */}
                <Grid
                  item
                >
                  <ButtonBase
                    color='inherit'
                    component='label'
                    sx={{
                      border: '1px dashed #ccc',
                      borderRadius: 1,
                      p: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      width: 100,
                      height: 100,
                      textAlign: 'center',
                      fontSize: '.8rem',
                      '&:hover': {
                        borderColor: 'primary.main',
                        color: 'primary.main',
                        '& svg': {
                          color: 'primary.main',
                        }
                      }
                    }}

                  >
                    <Iconify
                      icon={'mdi:camera'}
                      sx={{
                        fontSize: '2rem',
                        color: 'text.secondary'

                      }}
                    />
                    Загрузить фотографии
                    <input
                      type='file'
                      hidden
                      multiple
                      onChange={(e) => {
                        // обработка выбранных фотографий
                        UploaderService.uploadImages(e).then((res) => {
                          if (res) {
                            setState(prevState => ({
                              ...prevState,
                              images: [...prevState.images, ...res]
                            }))
                          }
                        })
                      }}
                    />
                  </ButtonBase>

                </Grid>


                {state.images.map((image, index) => (
                  <Grid
                    item
                    key={index}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                      }}
                    >
                      <Tooltip
                        title={'Удалить'}
                      >
                        <IconButton
                          size={'small'}
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            zIndex: 1,
                            color: 'white',
                          }}
                          onClick={() => {
                            setState(prevState => ({
                              ...prevState,
                              images: prevState.images.filter((_, i) => i !== index)
                            }))
                          }}
                        >
                          <Iconify
                            icon={'mdi:trash-can-outline'}
                            sx={{
                              filter: 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.6))'
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <CardMedia
                        component='img'
                        height='100'
                        image={`${appConfig.serverUrl}/images/${image.id}`}
                        alt={image.name}
                        sx={{
                          borderRadius: 1,
                        }}
                      />
                    </Box>

                  </Grid>
                ))}
              </Grid>

            </Grid>

          </Card>
        </Container>
      </Page>
      <DialogActions>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={submitHandler}
          size={'small'}
        >
          Подать заявку
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ComponentsSelectionDialog
