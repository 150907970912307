import {Avatar, Stack, Typography} from '@mui/material';
import React from 'react';
import {styled} from "@mui/material/styles";


const Wrapper = styled(Stack)(({theme})=>({
}))


const Header = styled(Stack)(({theme})=>({
  padding: theme.spacing(2),
  borderBottom: '1px solid',
  borderColor: theme.palette.divider,
}))

const Content = styled(Stack)(({theme})=>({
  padding: theme.spacing(2),
}))

interface Props {

}

export function Messages(props: Props) {

  return (
    <Wrapper direction={'column'} >
      <Header direction={'row'} spacing={2} alignItems={'center'}>
        <Avatar sx={{
          width: 34,
          height: 34,
        }}/>
        <Stack direction={'column'} spacing={-1}>
          <Typography variant={'subtitle1'}>
            Заказы
          </Typography>
          <Typography variant={'caption'}>
            Изменения статусов заказов и возвратов
          </Typography>
        </Stack>
      </Header>
      <Content>
        Сообщения
      </Content>
    </Wrapper>
  );
}
