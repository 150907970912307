import React from 'react';
import {Box, Link, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {SidebarOrdersLink} from "./types";
import {Link as RouterLink} from "react-router-dom";
import {useParams, useLocation} from "react-router";

const MenuList = styled(Box)(({theme}) => ({}))

const ListItem = styled(Box)(({theme}) => ({
  padding: theme.spacing(.5, 2),
  '& .active':{
    color: theme.palette.primary.main
  }
}))

interface Props {
  title: string
  links: SidebarOrdersLink[]
}

function List(props: Props) {
  const {links, title} = props
  const location = useLocation()

  return (
    <Box>

      <Typography variant={'h5'} px={2} py={.5}>
        {title}
      </Typography>

      <MenuList >
        {links.map((link, index) => {
          return <ListItem  key={index}>
            <Link className={location.pathname.includes(link.link) ? 'active' : ''} color={'inherit'} component={RouterLink} to={link.link}>
              {link.title}
            </Link>
          </ListItem>
        })}
      </MenuList>
    </Box>
  );
}

export default List;
