// ** React Imports
import {ReactNode} from 'react'

// ** MUI Imports
import {createTheme, responsiveFontSizes, ThemeProvider, StyledEngineProvider} from '@mui/material/styles'

// ** Type Imports
import {Settings} from 'src/@core/context/settingsContext'

// ** Theme Config
import themeConfig from 'src/configs/themeConfig'

// ** Theme Override Imports
import overrides from './overrides'
import typography from './typography'

// ** Theme
import themeOptions from './ThemeOptions'

// ** Global Styles
import {ruRU} from "@mui/x-data-grid";
import {ruRU as coreRuRu} from "@mui/material/locale";
import {CssBaseline, GlobalStyles} from "@mui/material";
import GlobalStyling from "../components/GlobalStyles";


declare module '@mui/material/styles' {
  interface PaletteColor {
    lighter: string,
    light: string,
    main: string,
    dark: string,
    darker: string,
    contrastText: string,
  }

  interface TypeText {
    primary: string,
    secondary: string,
    disabled: string,
    contrast: string
  }

  interface TypeBackground {
    paper: string,
    default: string,
    neutral: string,
    dark: string
  }

  interface TypeAction {
    active: string,
    hover: string,
    selected: string,
    disabled: string,
    disabledBackground: string,
    focus: string,
    hoverOpacity: number,
    disabledOpacity: number,
  }

  interface Palette {
    common: {
      black: string,
      white: string
    },
    primary: {
      lighter: string,
      light: string,
      main: string,
      dark: string,
      darker: string,
      contrastText: string,
    },
    secondary: {
      lighter: string,
      light: string,
      main: string,
      dark: string,
      darker: string,
      contrastText: string,
    },
    info: {
      lighter: string,
      light: string,
      main: string,
      dark: string,
      darker: string,
      contrastText: string,
    },
    success: {
      lighter: string,
      light: string,
      main: string,
      dark: string,
      darker: string,
      contrastText: string,
    },
    warning: {
      lighter: string,
      light: string,
      main: string,
      dark: string,
      darker: string,
      contrastText: string,
    },
    error: {
      lighter: string,
      light: string,
      main: string,
      dark: string,
      darker: string,
      contrastText: string,
    },
    gradients: {
      primary: string,
      info: string,
      success: string,
      warning: string,
      error: string,
    },
    chart: {
      violet: string[],
      blue: string[],
      green: string[],
      yellow: string[],
      red: string[],
    },
    divider: string,
    text: TypeText,
    background: TypeBackground,
    action: TypeAction,
  }

}


interface Props {
  settings: Settings
  children: ReactNode
}

const ThemeComponent = (props: Props) => {
  // ** Props
  const { settings, children } = props

  // ** Merged ThemeOptions of Core and User
  const coreThemeConfig = themeOptions(settings)

  // ** Pass ThemeOptions to CreateTheme Function to create partial theme without component overrides
  let theme = createTheme(coreThemeConfig)

  // ** Continue theme creation and pass merged component overrides to CreateTheme function
  theme = createTheme(theme, {
    components: { ...overrides(theme) },
  }, ruRU, coreRuRu)

  // ** Set responsive font sizes to true
  if (themeConfig.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }


  return (

    // <StyledEngineProvider injectFirst={true}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={() => GlobalStyling(theme) as any} />
      {children}
    </ThemeProvider>
    // </StyledEngineProvider>
  )
}

export default ThemeComponent
