import * as Yup from 'yup';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Form, FormikHelpers, FormikProvider, useFormik} from 'formik';
// material
import {IconButton, InputAdornment, Stack, TextField, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Iconify from "../../@core/components/Iconify";
import {LoginUserInput, useLoginLazyQuery} from "../../@core/@graphql/types";
import useAuth from "../../@core/hooks/useAuth";
import {UserTypeEnum} from "../../@core/@graphql/auth/user-type.enum";
// component

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const auth = useAuth();
  const [loadLogin, {called, loading, data, error}] = useLoginLazyQuery();

  useEffect(() => {
    if (data){
      const {token, user} = data.login;
      auth.setToken(token)
      auth.setUser(user)

    }
  }, [data])

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    login: Yup.string().required('Логин обязателен к заполнению'),
    password: Yup.string().required('Пароль обязателен к заполнению'),
  });

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    } as LoginUserInput,
    validationSchema: LoginSchema,
    onSubmit: (
      values: LoginUserInput,
      {setSubmitting}: FormikHelpers<LoginUserInput>,
    ) => {
      loadLogin({
        variables: {
          input: {...values, sourceType: UserTypeEnum.External}
        }
      })
        .finally(() => setSubmitting(false))
    },
  });

  const {errors, touched, values, isSubmitting, handleSubmit, getFieldProps} = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {error &&
          <Typography variant={'caption'} color={'error'} mb={2} display={'block'} > {
            error.message === 'Wrong Login or password' ? <>Не верный логин или пароль </> : <>Что-то пошло не так,  попробуйте войти позже.</>
          } </Typography>
        }

        <Stack spacing={3} mb={4}>
          <TextField
            fullWidth
            size={'small'}
            autoComplete="username"
            type="email"
            label="Логин или email"
            {...getFieldProps('login')}
            error={Boolean(touched.login && errors.login)}
            helperText={touched.login && errors.login}
          />

          <TextField
            fullWidth
            size={'small'}
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Пароль"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <LoadingButton fullWidth size="medium" type="submit" variant="contained" loading={isSubmitting}>
          Войти
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
