import React from 'react';
import {AppBar, AppBarTypeMap, Box, Container, Link, Stack, Toolbar, toolbarClasses, Typography} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import {styled} from "@mui/material/styles";


const AppBarStyle = styled(AppBar)(({theme}) => ({
  height: 43,
  justifyContent: 'end',
  borderBottom: `1px solid ${theme.palette.divider}`,
  background: theme.palette.background.paper
})) as typeof AppBar

const ToolbarStyle = styled(Toolbar)(({theme}) => ({
  [`&.${toolbarClasses.root}`]: {
    minHeight: 24,
    alignItems: 'center',
    display: 'flex'
  },
})) as typeof Toolbar

interface ITopBarProps {
  address?: string
  workingHours?: string
  phoneNumber?: string
  className?: string

  [key: string]: any
}

const TopBar = (props: ITopBarProps) => {
  const {
    address = 'Адрес офиса, г. Москва',
    workingHours = 'Автозаводская ул, 23А, корп 4, 3 этаж, комн 33',
    phoneNumber = '+7 (495) 681-17-16',
    className,

    ...rest
  } = props

  return (
    <AppBarStyle
      className={className}
      elevation={0}
      color={'inherit'}
      position={'relative'}
      component={'div'}
      {...rest}
    >
      <Container maxWidth={'xl'}>
        <ToolbarStyle disableGutters>
          <Stack direction={'row'} spacing={1} alignItems={'center'} fontSize={'14px'} mb={.8}>
            <Box display={'flex'} color={'InactiveCaptionText'}>
              <LocationOnIcon fontSize={'inherit'}/>
            </Box>
            <Typography variant={'subtitle2'}>{address}</Typography>
            <Typography variant={'caption'}>{workingHours}</Typography>
          </Stack>
          <Box sx={{flexGrow: 1}}/>
          <Stack direction={'row'} spacing={1} alignItems={'center'} fontSize={'14px'} mb={.8}>
            <Box display={'flex'} color={'InactiveCaptionText'}>
              <PhoneIcon fontSize={'inherit'} color={'inherit'}/>
            </Box>
            <Typography variant={'subtitle2'} color={'black'}>

              <Link href={'tel:' + phoneNumber} color={'inherit'}>
                {phoneNumber}
              </Link>
            </Typography>
          </Stack>
        </ToolbarStyle>
      </Container>
    </AppBarStyle>
  );
};

export default TopBar;
