import {PaletteMode, PaletteOptions} from "@mui/material";
import {alpha} from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1: any, color2: any) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
// const GREY = {
//   0: '#FFFFFF',
//   100: '#F0F4F9',
//   200: '#E9EBF7',
//   300: '#DFE3E8',
//   400: '#C4CDD5',
//   500: '#919EAB',
//   600: '#707579',
//   700: '#454F5B',
//   800: '#353d43',
//   900: '#161C24',
//   500_8: alpha('#919EAB', 0.08),
//   500_12: alpha('#919EAB', 0.12),
//   500_16: alpha('#919EAB', 0.16),
//   500_24: alpha('#919EAB', 0.24),
//   500_32: alpha('#919EAB', 0.32),
//   500_48: alpha('#919EAB', 0.48),
//   500_56: alpha('#919EAB', 0.56),
//   500_80: alpha('#919EAB', 0.8),
// };
const GREY = {
  0: '#FFFFFF',
  100: '#f2f0f0',
  200: '#f0e9e9',
  300: '#e3dfdf',
  400: '#c8c4c4',
  500: '#969191',
  600: '#767070',
  700: '#4b4545',
  800: '#3c3535',
  900: '#1c1616',
  500_8: alpha('#ab9191', 0.08),
  500_12: alpha('#ab9191', 0.12),
  500_16: alpha('#ab9191', 0.16),
  500_24: alpha('#ab9191', 0.24),
  500_32: alpha('#ab9191', 0.32),
  500_48: alpha('#ab9191', 0.48),
  500_56: alpha('#ab9191', 0.56),
  500_80: alpha('#ab9191', 0.8),
};

const SECONDARY = {
  lighter: '#919194',
  light: '#666669',
  main: '#48484a',
  dark: '#313133',
  darker: '#202021',
  contrastText: '#fff',
};

const PRIMARY = {
  lighter: '#ff969b',
  light: '#ff4c55',
  main: '#e60023',
  dark: '#ad081b',
  darker: '#ad000a',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const palette = (mode: PaletteMode) => {

  // ** Vars
  const lightColor = '36, 22, 22'
  const darkColor = '243, 243, 255'
  const mainColor = mode === 'light' ? lightColor : darkColor

    return {
      common: {black: '#000', white: '#fff'},
      primary: {...PRIMARY},
      secondary: {...SECONDARY},
      info: {...INFO},
      success: {...SUCCESS},
      warning: {...WARNING},
      error: {...ERROR},
      grey: GREY,
      gradients: GRADIENTS,
      divider: GREY[500_24],
      text: {
        primary: `rgba(${mainColor}, 0.87)`,
        secondary: `rgba(${mainColor}, 0.68)`,
        disabled: `rgba(${mainColor}, 0.38)`,
        contrast: GREY[0]
      },
      background: {
        paper: mode === 'light' ? '#fff' : '#2a2a2e',
        default: mode === 'light' ? GREY[100] : '#212125',
        neutral: mode === 'light' ? GREY[200] : 'black',
        dark: mode === 'light' ? '#24242c' : 'black',

      },
      action: {
        active: `rgba(${mainColor}, 0.54)`,
        hover: `rgba(${mainColor}, 0.04)`,
        selected: `rgba(${mainColor}, 0.08)`,
        disabled: `rgba(${mainColor}, 0.3)`,
        disabledBackground: `rgba(${mainColor}, 0.18)`,
        focus: `rgba(${mainColor}, 0.12)`,
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
      },
    } as PaletteOptions
  }
;

export default palette;
