import React from 'react';
import {Card, Stack} from "@mui/material";
import {OrderItem} from "../order-item";
import {ExternalOrderEntity, useGetOrdersQuery} from "../../../@core/@graphql/types";

interface Props {

}

export function OrdersList(props: Props) {
  const {data} = useGetOrdersQuery({
    variables: {
      input: {
        statuses: []
      }
    }
  })
  return (
    <Card variant={'elevation'} sx={{px: 2, py: 4, mb: 2}}>
      <Stack spacing={4}>
        {data?.getOrders.slice().sort((a,b) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0)).map((item) => (
          <OrderItem key={item.id} item={item as ExternalOrderEntity}/>
        ))}

      </Stack>
    </Card>
  );
}
