import React, {FC, useEffect, useMemo, useState} from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  TextField,
  ToggleButton,
  Tooltip,
  Typography
} from "@mui/material";
import {ToggleButtonGroup} from "../../../@core/components/shared-components/ToggleButtonGroup";
import {IUserContacts, UserContactsInput} from "../../../@core/context/AuthContext";
import {EpicDialogOption, useDialog} from "../../../@core/providers/DialogProvider";
import useAuth from "../../../@core/hooks/useAuth";
import * as Yup from "yup";
import {Form, FormikHelpers, FormikProvider, useFormik} from "formik";
import {Delete, Edit} from "@mui/icons-material";

interface CheckoutFormFormContactDetailsProps {
  onChangeContacts: (contacts: IUserContacts) => void
}

// компонент выбора контактов пользователя
const CheckoutFormFormContactDetails: FC<CheckoutFormFormContactDetailsProps> = (props) => {
  const {onChangeContacts} = props // функция обратного вызова, которая передает выбранный контакт в родительский компонент
  const [openDialog, closeDialog] = useDialog(); // хук для открытия диалогового окна
  const {userContacts, removeUserContacts, updateUserContacts, addUserContacts} = useAuth() // хук для получения контактов пользователя
  const [contactId, setContactId] = useState(userContacts?.[0]?.id || ''); // id выбранного контакта

  useEffect(() => {
    console.log('userContacts', userContacts)
  }, [userContacts]);


  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newContactId: string,
  ) => {
    setContactId(newContactId);
  };

  const control = {
    value: contactId,
    onChange: handleChange,
    exclusive: true,
  };


  // если изменен выбранный контакт, то передаем его в родительский компонент
  useEffect(() => {
    onChangeContacts(userContacts?.find((item) => item.id === contactId) as IUserContacts)
  }, [contactId])

  // если не выбран контакт и есть контакты, то выбираем первый
  useMemo(() => {
    if (userContacts && userContacts?.length > 0 && contactId === 'addButton') {
      setContactId(userContacts?.[0].id)
    }
  }, [userContacts]);


  const dialogOptions = {
    PaperProps: {
      sx: {
        p: 0
      }
    },
    sx: {
      '& .MuiDialogTitle-root': {
        p: 2
      }

    },
    fullWidth: true,
    maxWidth: 'xs',
  } as EpicDialogOption

  // компонент диалогового окна для добавления нового контакта
  const addNewUserContactsHandler = () => {
    openDialog({
      ...dialogOptions,

      title: 'Данные получателя',
      children: <UserContactsDialogContent
        onClose={() => closeDialog()}
      />
    })
  };

  // компонент диалогового окна для редактирования контакта
  const editUserContactsHandler = (userContact: IUserContacts) => {
    openDialog({
      ...dialogOptions,

      title: 'Изменение данных получателя',
      children: <UserContactsDialogContent
        onClose={() => closeDialog()}
        userContact={userContact}
      />
    })
  };

  // компонент диалогового окна для подтверждения удаления контакта
  const removeUserContactsHandler = (userContact: IUserContacts) => {
    openDialog({
      ...dialogOptions,

      title: 'Удаление данных получателя',
      children: <>
        <Box
          px={3}
          my={1}
        >
          <Alert severity={'warning'}>
            <Typography
              variant={'h4'}
              gutterBottom
            >
              Вы действительно хотите удалить данные получателя?
            </Typography>
            <Typography variant={'caption'}>
              {userContact.fio}, {userContact.phone}, {userContact.position}
            </Typography>

            <Typography variant={'body1'}>
              Данные будут удалены без возможности восстановления.
            </Typography>
          </Alert>
        </Box>
        <DialogActions>
          <Button
            size={'small'}
            variant={'outlined'}
            onClick={() => closeDialog()}
          >Отмена</Button>
          <Button
            size={'small'}
            variant={'contained'}
            onClick={() => {
              closeDialog()
              removeUserContacts(userContact.id)
              if (userContact.id === contactId) {
                if (userContacts && userContacts?.length > 0) {
                  setContactId(userContacts?.[0]?.id)
                } else {
                  setContactId('addButton')
                }
              }

            }}
          >Удалить</Button>
        </DialogActions>
      </>
    })
  };

  return (
    <>
      <Card
        sx={{
          p: 3,
          width: '100%'
        }}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Typography variant={'h4'}> Выберите получателя </Typography>
          <Button
            size={'small'}
            color={'inherit'}
            onClick={addNewUserContactsHandler}
          >+ Добавить получателя</Button>
        </Box>
        <Typography variant={'caption'}>
          Укажите корректные данные для связи с получателем.
        </Typography>

        <Stack
          direction={'row'}
          spacing={2}
          alignItems={'baseline'}
          mt={3}
        >

          <ToggleButtonGroup
            size={'large'}
            color={'primary'} {...control} >
            {
              userContacts && userContacts?.length > 0 ? userContacts?.map(item => (
                  <ToggleButton
                    value={item.id}
                    key={item.id}
                    component={Box}
                    sx={{
                      '&:hover': {
                        '& [class*="MuiStack-root"]': {
                          visibility: 'visible'
                        }
                      }
                    }}
                  >
                    <Box>
                      <Stack
                        direction={'row'}
                        spacing={1}
                        alignItems={'center'}
                        sx={{
                          position: 'absolute',
                          right: 2,
                          top: 2,
                          visibility: 'hidden'
                        }}
                      >
                        <Tooltip title={'Редактировать'}>
                          <IconButton
                            size={'small'}
                            onClick={(e) => {
                              e.stopPropagation();
                              editUserContactsHandler(item)
                            }}
                          >
                            <Edit fontSize={'small'}/>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={'Удалить'}>
                          <IconButton
                            size={'small'}
                            onClick={(e) => {
                              e.stopPropagation();
                              removeUserContactsHandler(item)
                            }}
                          >
                            <Delete fontSize={'small'}/>
                          </IconButton>
                        </Tooltip>
                      </Stack>

                      {item.fio}, <br/>{item.phone}, <br/>{item.position}
                    </Box>


                  </ToggleButton>
                )) :
                <ToggleButton
                  value={'addButton'}
                  onClick={addNewUserContactsHandler}
                >
                  Добавьте новые данные получателя
                </ToggleButton>
            }


          </ToggleButtonGroup>

        </Stack>

      </Card>
    </>
  );
};

export default CheckoutFormFormContactDetails;

interface UserContactsDialogContentProps {
  onClose: () => void
  userContact?: IUserContacts
}

// контент диалогового окна для добавления и редактирования контактов
const UserContactsDialogContent: FC<UserContactsDialogContentProps> = (props) => {
  const {onClose, userContact} = props
  const {addUserContacts, updateUserContacts} = useAuth()
  const phoneRegExp = /^((8|\+374|\+994|\+995|\+375|\+7|\+380|\+38|\+996|\+998|\+993)[\- ]?)?\(?\d{3,5}\)?[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}(([\- ]?\d{1})?[\- ]?\d{1})?$/

  const UserContactsSchema = Yup.object().shape({
    fio: Yup.string().required('Укажите Имя и Фамилию'),
    phone: Yup.string().required('Укажите Телефон').matches(phoneRegExp, 'Не правильный номер телефона'),
    position: Yup.string().required('Укажите Должность'),
  });

  const formik = useFormik({
    initialValues: {
      fio: userContact?.fio || '',
      phone: userContact?.phone || '',
      position: userContact?.position || '',
    } as UserContactsInput,
    validationSchema: UserContactsSchema,
    onSubmit: (
      values: UserContactsInput,
      {setSubmitting}: FormikHelpers<UserContactsInput>,
    ) => {
      if (userContact) {
        updateUserContacts({...values, id: userContact.id})
      } else {
        console.log(values)
        addUserContacts({...values})
      }

      setSubmitting(false)
      onClose()
    },
  });

  const {errors, touched, handleSubmit, getFieldProps} = formik;

  return <>
    <FormikProvider value={formik}>
      <Form
        autoComplete='off'
        noValidate
        onSubmit={handleSubmit}
      >
        <DialogContent>


          <Stack
            spacing={3}
            mb={4}
          >

            <TextField
              required
              fullWidth
              size={'small'}
              autoComplete='off'
              type='text'
              label='Имя и Фамилия'
              autoFocus
              {...getFieldProps('fio')}
              error={Boolean(touched.fio && errors.fio)}
              helperText={touched.fio && errors.fio}
            />
            <TextField
              required
              fullWidth
              size={'small'}
              autoComplete='off'
              type='tel'
              label='Номер телефона'
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />

            <TextField
              required
              fullWidth
              size={'small'}
              autoComplete='off'
              type='text'
              label='Должность'
              {...getFieldProps('position')}
              error={Boolean(touched.position && errors.position)}
              helperText={touched.position && errors.position}
            />

          </Stack>

        </DialogContent>
        <DialogActions>
          <Button
            size={'small'}
            color={'primary'}
            variant={'contained'}
            type='submit'
          >
            {userContact ? 'Сохранить изменения' : 'Добавить получателя'}
          </Button>
        </DialogActions>
      </Form>
    </FormikProvider>
  </>;
}
