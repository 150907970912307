import React, {ReactNode} from 'react';
import {Outlet} from "react-router-dom";

interface Props {
  children?: ReactNode;
}

function BlankLayout({children}: Props) {

  return (
    <>
      <main className="app-main">{
        children ? children : <Outlet/>
      }</main>
    </>
  );
}

export default BlankLayout;
