import React from 'react';

interface Props {

}

export function Account(props: Props) {

  return (
    <>Account</>
  );
}
