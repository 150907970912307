import React, {useMemo, useState} from 'react';
import useCart from "../../../@core/hooks/useCart";
import {URLSearchParamsInit, useSearchParams} from "react-router-dom";
import {SportEquipmentEntity} from "../../../@core/@graphql/types";
import appConfig from "../../../configs/appConfig";
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Typography, useTheme
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import useWishlist from "../../../@core/hooks/useWishlist";
import {useReactiveVar} from "@apollo/client";
import {sportObjectVar, Warehouse} from "../../../@core/store/sportObject";
import {Theme} from "@mui/material/styles";

interface CartItemsListProps {
  items: SportEquipmentEntity[]
  fullWidth?: boolean
  selectable?: boolean
  onSelectChange?: (newValues: SportEquipmentEntity[]) => void
}

function CartItem({disabled, index, item, fullWidth, selectable, selectedItems, setSelectedItems, searchParams, setSearchParams, getEquipmentPhoto, checkIsInWishlist, favoriteButtonHandler, theme, dropFromCart}: { setSearchParams: (nextInit: URLSearchParamsInit, navigateOptions?: ({ replace?: boolean | undefined; state?: any } | undefined)) => void, checkIsInWishlist: (id: string) => boolean, fullWidth: boolean | undefined, theme: Theme, favoriteButtonHandler: (equipment: SportEquipmentEntity) => void, getEquipmentPhoto: (equipment: SportEquipmentEntity) => (string), setSelectedItems: (value: (((prevState: { itemId: string; state: boolean }[]) => { itemId: string; state: boolean }[]) | { itemId: string; state: boolean }[])) => void, selectable: boolean | undefined, dropFromCart: (id: string) => void, item: SportEquipmentEntity, searchParams: URLSearchParams, index: number, selectedItems: { itemId: string; state: boolean }[];disabled?:boolean }) {
  return <Card
    elevation={0}
    variant={'outlined'}
    sx={{
      width: !fullWidth ? 360 : '100%',
      p: 2,
      opacity: disabled ? .5 : 1

    }}
    key={item.id}
  >

    <Stack
      direction={'row'}
      spacing={1}
    >
      {selectable && selectedItems?.[index] && <Box>
        <Checkbox checked={selectedItems[index]?.state} onChange={
          ({target: {checked}}) => {
            setSelectedItems(prevState => {``
              const arr = [...prevState]
              arr[index] = {...arr[index], state: checked}

              return arr
            })
          }
        }/>
      </Box>}
      <Stack
        direction={'row'}
        spacing={2}
        alignItems={'start'}
        sx={{
          cursor: 'pointer'
        }}

        onClick={() => {
          searchParams.set('preview', item.id)
          setSearchParams(searchParams)
        }}
      >
        <Avatar sx={{
          bgcolor: 'background.paper',
          borderColor: 'divider',
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: 1,
          width: 42,
          height: 42
        }}
                variant={'square'}
        >
          <img src={getEquipmentPhoto(item)} height={42} alt=""/>
        </Avatar>
        <Stack direction={'column'} spacing={1} width={'100%'}>
          <Typography variant={'subtitle2'} color={'primary.main'}>
            {item.name}
          </Typography>

          <Typography variant={'body2'} color={'text.secondary'}>
            Инв. номер: {item.inventoryNumber}
          </Typography>


        </Stack>
      </Stack>

    </Stack>
    <Divider/>
    <Stack direction={'row'} spacing={2} justifyContent={'flex-end'} width={'100%'}>
      <Button size={'small'} variant={'text'} color={'inherit'} onClick={() => favoriteButtonHandler(item)}>
        {checkIsInWishlist(item.id) ? <Box component={'span'} color={theme.palette.info.dark}>В избранном</Box> : 'В избранное'}
      </Button>

      <Box display={'flex'} >
        <Button size={'small'} variant={'text'} onClick={() => dropFromCart(item.id)}>
          Удалить
        </Button>
      </Box>
    </Stack>

  </Card>;
}

export function CartItemsList(props: CartItemsListProps) {
  const {fullWidth, selectable} = props

  const {currentSportObject} = useReactiveVar(sportObjectVar)

  const {dropFromCart} = useCart()
  const [searchParams, setSearchParams] = useSearchParams()
  const {addToWishlist, dropFromWishlist, checkIsInWishlist} = useWishlist()

  const [selectedItems, setSelectedItems] = useState([...props.items].map(item => ({
    itemId: item.id,
    state: true
  })));

  const items = useMemo(() => {
    return {
      enabled: props.items?.slice()?.filter(item => item.sportObject.id === currentSportObject?.id || item.sportObject.id === Warehouse.id),
      disabled: props.items?.slice()?.filter(item => item.sportObject.id !== currentSportObject?.id && item.sportObject.id !== Warehouse.id ),
    }
  }, [props.items]);

  const favoriteButtonHandler = (equipment: SportEquipmentEntity) => {
    const isFavorite = checkIsInWishlist(equipment.id)
    if (isFavorite) {
      dropFromWishlist(equipment.id)
    } else {
      addToWishlist(equipment)
    }
  };


  function getEquipmentPhoto(equipment: SportEquipmentEntity) {
    if (equipment?.mainPhoto?.url) {
      return `${appConfig?.serverUrl}${equipment?.mainPhoto?.url}`
    }
    if (equipment?.photos?.[0]?.url) {
      return `${appConfig?.serverUrl}${equipment?.photos?.[0]?.url}`
    }
    return "static/sport-pattern.png";
  }

  const theme = useTheme()
  return (
    <>
      <Stack direction={'column'} spacing={2}>
        {items.enabled.map((item, index) => (
          <CartItem {...{index, item, fullWidth, selectable, selectedItems, setSelectedItems, searchParams, setSearchParams, getEquipmentPhoto, checkIsInWishlist, favoriteButtonHandler, theme, dropFromCart}} />

        ))
        }

        {items.disabled.length > 0 &&
          <Typography variant={'subtitle2'} sx={{color: 'text.disabled', pt: 3}}>Находится на других спортобъектах</Typography>}
        {items.disabled.map((item, index) => (
          <CartItem {...{index, item, fullWidth, selectable, selectedItems, setSelectedItems, searchParams, setSearchParams, getEquipmentPhoto, checkIsInWishlist, favoriteButtonHandler, theme, dropFromCart}} disabled={true} />

        ))
        }
      </Stack>
    </>
  );
}
