import React from 'react';
import {badgeClasses, Box, IconButton, Tooltip} from "@mui/material";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {styled} from "@mui/material/styles";
import useCart from "../../hooks/useCart";


const BadgeStyle = styled(Badge)(({theme}) => ({
  justifyContent: 'center',
  alignItems: 'center',
  [`& .${badgeClasses.anchorOriginBottomRight}`]: {
    right: 'initial',
    position: 'relative',
    top: 'initial',
    bottom: 'initial',
    left: 'initial',
    transform: 'scale(1) translate(-40%, 0%)',
    transformOrigin: '0',
  },
  [`& .${badgeClasses.invisible}`]: {
    transform: 'scale(0) translate(-40%, 0%)',
    transformOrigin: '0',
  }
})) as typeof Badge

interface Props {
  count?: number
}

function BasketPanel(props: Props) {
  const {count} = props
  const {cartOpen, setCartOpen} = useCart()

  return (
    <>
        <BadgeStyle
          badgeContent={count}
          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
          color={'secondary'}
        >
          <Tooltip title={'Корзина'} onClick={()=> {
            setCartOpen(!cartOpen)
          }}>
            <Box>
              <IconButton size={'small'} sx={{p:0}} >
                <Avatar
                  alt='Wish list'
                  sx={{width: 32, height: 32, bgcolor: 'primary.main', fontSize: "14px", color: 'text.contrast'}}
                >
                  <ShoppingCartIcon color={'inherit'} fontSize={'inherit'}/>
                </Avatar>
              </IconButton>
            </Box>

          </Tooltip>
        </BadgeStyle>
    </>
  );
}

export default BasketPanel;
