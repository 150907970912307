import React, {createContext, FC, useEffect, useMemo, useState} from 'react';
import ProductPreview from "../../components/product-preview";
import ComponentsSelectionDialog from "../../components/EquipmentSelectionDialog";
import {useSearchParams} from "react-router-dom";
import {
  NotExistingCartRecordEntity,
  SportEquipmentEntity,
  useRemoveNotExistingEquipmentRecordFromCartMutation
} from "../../@graphql/types";
import useCart from "../../hooks/useCart";
import {Box, Button, Collapse, Dialog, Grid, IconButton, Skeleton, Stack, Typography} from "@mui/material";
import {Gallery, GalleryProps, Item} from "react-photoswipe-gallery";
import Slider from "react-slick";
import appConfig from "../../../configs/appConfig";
import {DataSourceArray} from "photoswipe";
import {TechnicalCondition} from "../../@graphql/equipment-list/technical-condition.enum";
import Iconify from "../../components/Iconify";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

interface IRequestForNotExistentEquipmentContext {
  dialogOpen: boolean;
  setDialogOpen: (value: boolean) => void;
}

const defaultState = {
  dialogOpen: false,
  setDialogOpen: () => {
  },
};

export const RequestForNotExistentEquipmentContext = createContext<IRequestForNotExistentEquipmentContext>(defaultState);


interface Props {
  children: React.ReactNode;
}

function ComponentsSelectionPreview() {
  const [searchParams, setSearchParams] = useSearchParams()
  const previewNotExistRecordId = searchParams.get('not-exist-preview')
  const [product, setProduct] = useState<NotExistingCartRecordEntity | null>(null);
  const [loading, setLoading] = useState(true);
  const {notExistingProducts, getButtonLabel} = useCart()
  // const {addToCart, dropFromCart, checkIsInCart, getButtonLabel} = useCart()

  useEffect(() => {
    return () => {
      setProduct(null)
      setLoading(true)
    };
  }, []);


  useEffect(() => {

    if (!!previewNotExistRecordId) {
      console.log('previewNotExistRecordId', previewNotExistRecordId)
      console.log('notExistingProducts', notExistingProducts)
      const notExistingProduct = notExistingProducts.find((item) => item.id === previewNotExistRecordId)
      console.log('notExistingProduct', notExistingProduct)
      if (notExistingProduct) {
        setProduct(notExistingProduct)
      }
    }

  }, [previewNotExistRecordId, notExistingProducts]);

  useEffect(() => {
    console.log(product)
    !!product && setLoading(false)
  }, [product]);

  const uiElements: GalleryProps['uiElements'] = [
    {
      name: 'bulletsIndicator',
      order: 9,
      isButton: false,
      appendTo: 'wrapper',
      onInit: (el, pswpInstance) => {
        let prevIndex = -1
        const thumbnails: HTMLElement[] = []


        el.style.position = 'absolute'
        // el.style.bottom = '20px'
        el.style.top = '70px'
        el.style.left = '20px'
        // el.style.right = '0'
        el.style.display = 'grid'
        el.style.gridGap = '10px'
        el.style.gridTemplateColumns = 'repeat(auto-fit, 100px)'
        el.style.gridTemplateRows = 'repeat(auto-fit, 100px)'
        el.style.justifyContent = 'center'


        const dataSource = pswpInstance.options.dataSource as DataSourceArray

        for (let i = 0; i < dataSource.length; i++) {
          const slideData = dataSource[i]

          const thumbnail = document.createElement('div')
          thumbnail.style.transition = 'transform 0.15s ease-in'
          thumbnail.style.opacity = '0.6'
          thumbnail.style.cursor = 'pointer'
          thumbnail.onclick = (e: MouseEvent) => {
            const target = e.target as HTMLImageElement | HTMLDivElement
            const thumbnailEl =
              target.tagName === 'IMG'
                ? target.parentElement
                : (e.target as HTMLImageElement | HTMLDivElement)
            pswpInstance.goTo(thumbnails.indexOf(thumbnailEl!))
          }

          const thumbnailImage = document.createElement('img')
          slideData?.msrc && thumbnailImage.setAttribute('src', slideData.msrc)
          thumbnailImage.style.width = '100%'
          thumbnailImage.style.height = '100%'
          thumbnailImage.style.objectFit = 'cover'

          thumbnail.appendChild(thumbnailImage)

          el.appendChild(thumbnail)

          thumbnails.push(thumbnail)
        }

        pswpInstance.on('change', () => {
          if (prevIndex >= 0) {
            const prevThumbnail = thumbnails[prevIndex]
            prevThumbnail.style.opacity = '0.6'
            prevThumbnail.style.cursor = 'pointer'
            // prevThumbnail.style.transform = 'scale(1)'
          }

          const currentThumbnail = thumbnails[pswpInstance.currIndex]
          currentThumbnail.style.opacity = '1'
          currentThumbnail.style.cursor = 'unset'
          // currentThumbnail.style.transform = 'scale(1.2)'

          prevIndex = pswpInstance.currIndex
        })
      },
    },
  ]

  const [removeNotExistingCartRecord] = useRemoveNotExistingEquipmentRecordFromCartMutation()

  return (
    <>
      <Dialog
        open={!!previewNotExistRecordId}
        onClose={() => {
          searchParams.delete('not-exist-preview')
          setSearchParams(searchParams)
          setProduct(null)
          setLoading(true)
        }}
        maxWidth={'md'}

        PaperProps={{
          style: {width: '100%'}
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              position: 'relative'
            }}
          >
            <Box
              sx={{
                height: 550,
                overflow: 'hidden',
                '& .slick-prev': {
                  left: 10,
                  zIndex: 1
                },
                '& .slick-next': {
                  right: 10
                },
                '& .slick-dots': {
                  bottom: 10
                },
              }}
            >
              <Gallery
                uiElements={uiElements}
                options={{bgOpacity: 1}}
              >
                <Slider {...{
                  dots: true,
                  infinite: false,
                  speed: 500,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }}>
                  {product?.images && product?.images?.length > 0 ? (
                    product?.images?.map((photo, index) => (
                      <Box
                        key={index}
                      >
                        <Item
                          original={appConfig.serverUrl + photo.url}
                          thumbnail={appConfig.serverUrl + (photo?.avatarUrl || photo.url)}
                          width={photo.width as number}
                          height={photo.height as number}
                          alt={product.name as string}
                        >
                          {({ref, open}) => (
                            <Box
                              position={'relative'}
                              sx={{
                                bgcolor: 'background.default',
                                minHeight: 550,
                                height: '100%',
                                width: '100%',
                              }}
                            >
                              <Box
                                sx={{
                                  top: '0',
                                  left: '0',
                                  bottom: '0',
                                  position: 'absolute',
                                  height: '100%',
                                  width: '100%',
                                  backgroundImage: `url(${appConfig.serverUrl + photo.url})`,
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'center center'
                                }}
                                onDoubleClick={open}
                                ref={ref as React.MutableRefObject<HTMLImageElement>}
                              />
                            </Box>

                          )}
                        </Item>
                      </Box>

                    ))
                  ) : (

                    loading ? <Skeleton
                      variant='rectangular'
                      width={'100%'}
                      height={550}
                    /> : <Box
                      sx={{
                        minHeight: 550,
                        height: '100%',
                        width: '100%',
                        backgroundImage: `url(static/sport-pattern.png)`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'none',
                        backgroundPosition: 'center center'
                      }}
                    />
                  )

                  }
                </Slider>
              </Gallery>

            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >

            <Box
              sx={{
                height: 550,
                overflow: 'hidden',
              }}
            >
              <Stack
                direction={'column'}
                height={'100%'}
              >
                {!loading ? (
                  <>
                    <Box
                      position={'sticky'}
                      top={0}
                      p={3}
                      sx={{
                        bgcolor: 'background.paper'
                      }}
                    >
                      <Typography
                        variant={'h2'}
                        color={'text.main'}
                      >
                        {product?.name}

                      </Typography>
                        <Box >

                          <Typography
                            variant={'caption'}
                            sx={{color: 'text.secondary', fontWeight: 'bold'}}
                          >
                            Описание:
                          </Typography>
                            <Typography
                              variant={'caption'}
                              component={'span'}
                              sx={{
                                color: 'text.primary',
                                display: '-webkit-box',
                                WebkitLineClamp: 'auto',
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                              }}
                            >
                              {product?.description}
                            </Typography>
                        </Box>
                    </Box>
                  </>
                ) : (
                  <Box p={3}>
                    <Skeleton
                      variant='text'
                      sx={{fontSize: '1.2rem'}}
                    />
                    <Box mb={2}>
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        width={70}
                        style={{display: 'inline-block'}}
                      />
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        style={{display: 'inline-block', marginLeft: '.5rem'}}
                        width={200}
                      />
                    </Box>

                    <Box>
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        width={90}
                        style={{display: 'inline-block'}}
                      />
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        style={{display: 'inline-block', marginLeft: '.5rem'}}
                        width={180}
                      />
                    </Box>
                    <Box>
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        width={80}
                        style={{display: 'inline-block'}}
                      />
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        style={{display: 'inline-block', marginLeft: '.5rem'}}
                        width={190}
                      />
                    </Box>

                    <Box>
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        width={60}
                        style={{display: 'inline-block'}}
                      />
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        style={{display: 'inline-block', marginLeft: '.5rem'}}
                        width={170}
                      />
                    </Box>


                    <Box>
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        width={85}
                        style={{display: 'inline-block'}}
                      />
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                        style={{display: 'inline-block', marginLeft: '.5rem'}}
                        width={250}
                      />
                      <Skeleton
                        variant='text'
                        sx={{fontSize: '1rem'}}
                      />
                    </Box>
                  </Box>
                )}
              </Stack>

              <Stack
                direction={'row'}
                spacing={1}
                p={3}
                position={'sticky'}
                bottom={0}
                sx={{
                  bgcolor: 'background.paper'
                }}
              >
                <Stack
                  direction={'column'}
                  spacing={2}
                  flexGrow={0}
                  width={'100%'}
                >
                  <Button
                    disabled={loading}
                    variant={'contained'}
                    onClick={() => {
                      removeNotExistingCartRecord({
                        variables: {
                          input:{
                            cartRecordId: product?.id as string,
                            equipmentCount: product?.equipmentCount as number,
                          }
                        }
                      }).then(() => {
                        searchParams.delete('not-exist-preview')
                        setSearchParams(searchParams)
                        setProduct(null)
                        setLoading(true)
                      })
                    }}
                  >
                    Удалить
                  </Button>
                </Stack>
              </Stack>

            </Box>


          </Grid>
        </Grid>

      </Dialog>
    </>
  )
}

const RequestForNotExistentEquipmentProvider: FC<Props> = ({children}: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const contextValue = useMemo(
    () => ({
      dialogOpen,
      setDialogOpen,
    }),
    [
      dialogOpen,
    ]
  );
  return (
    <RequestForNotExistentEquipmentContext.Provider value={contextValue}>
      <ComponentsSelectionDialog/>
      <ComponentsSelectionPreview/>
      {children}
    </RequestForNotExistentEquipmentContext.Provider>
  );
};

export default RequestForNotExistentEquipmentProvider
