import React, {useEffect} from 'react';
import {Dialog, DialogContent,  Stack, Typography} from "@mui/material";

import LoginForm from "../../views/login/LoginForm";
import {styled} from "@mui/material/styles";
import Logo from "../../@core/components/shared-components/Logo";
import {useLocation} from "react-router";
import useAuth from "../../@core/hooks/useAuth";
import {useNavigate} from "react-router-dom";

interface Props {

}
const Logotype = styled(Logo)(({theme}) => ({
  marginRight: theme.spacing(2),
  padding: theme.spacing(.5),
  width: 32,
  height: 32,
  borderRadius: 8,
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: theme.palette.primary.main,
  fill: theme.palette.primary.main,
}))

function LoginPage(props: Props) {
  const {search} = useLocation()
  const {token} = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (token) {
      navigate(search || '/')
    }
  }, [token]);



  return (
    <>
    <Dialog open={true} PaperProps={{
      sx:{ p:5 }
    }}>
      <DialogContent  >
        <>

          <Stack direction={'row'} spacing={1} >
            <Logotype/>
            <Typography variant="h4" gutterBottom>
              Московский спорт
            </Typography>
          </Stack>

          <Typography sx={{ color: 'text.secondary', mt: 2, mb: 5 }}>Для входа в каталог оборудования, <br/> введите свои данные ниже.</Typography>

          <LoginForm />
        </>

      </DialogContent>
    </Dialog>
    </>
  );
}

export default LoginPage;
