import {NavItemsType} from "../@core/interfaces/interfaces";
import useAuth from "../@core/hooks/useAuth";

export const dashboardNavItems = (): NavItemsType => {
  return [
    {
      title: 'Оборудование',
      icon: 'warehouse',
      path: '/equipments'
    },
    {
      title: 'План обслуживания',
      icon: 'reports',
      path: '/equipment-service-jobs'
    },
    {
      title: 'Заявки на оборудование',
      icon: 'equipment-request',
      path: '/equipment-request',
    },
    {
      title: 'МСО.Объявления',
      icon: 'equipment-outgoing-request',
      path: '/equipment-relocation',
    },
  ]
}

export const dashboardAfterNavItems = (): NavItemsType => {
  const {user} = useAuth()
  return (user && !user?.isForSportObject ) ? [
    {
      title: 'Настройки',
      icon: 'settings',
      path: '/settings',
    },
  ] : []
}

export const mainNavItems = (): NavItemsType => {
  return [
    {
      title: 'Оборудование',
      path: '/equipments'
    },
    {
      title: 'Спортобъект',
      path: '/equipmentRequest',
    },
  ]
}

