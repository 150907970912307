import React, {SyntheticEvent, useEffect, useMemo, useState} from 'react';
import {NavItemsType, NavLink, NavSectionTitle} from "../../interfaces/interfaces";
import VerticalNavSectionTitle from "./VerticalNavSectionTitle";
import VerticalNavLink from "./VerticalNavLink";
import {Box, Button, Container, Fade, Grid, Grow, Menu, Typography, useTheme} from "@mui/material";
import ListIcon from '@mui/icons-material/List';
import CloseIcon from '@mui/icons-material/Close';
import {SportCategoryDictionaryEntity, useGetSportCategoriesQuery, useGetSportKindsQuery} from "../../@graphql/types";
import {styled} from "@mui/material/styles";
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";

interface Props {
  navVisible?: boolean
  groupActive: string[]
  currentActiveGroup: string[]
  navItems?: NavItemsType
  setGroupActive: (value: string[]) => void
  setCurrentActiveGroup: (item: string[]) => void
}

const resolveNavItemComponent = (item: NavLink | NavSectionTitle) => {
  const theme = useTheme()
  if ((item as NavSectionTitle).sectionTitle) return VerticalNavSectionTitle

  return VerticalNavLink
}

const List = styled(Box)(({theme}) => ({}))
const ListHeader = styled(Typography)(({theme}) => ({})) as typeof Typography
const ListItems = styled(Box)(({theme}) => ({}))
const ListItem = styled(({active, ...props}: any) => (
  <Button
    variant={'text'}
    fullWidth
    size={'small'}
    disableRipple
    color={active ? 'primary' : 'inherit'}
    style={{
      textAlign: 'left'
    }}
    {...props} />
))(({theme}) => ({
  justifyContent: 'left',
  fontWeight: 'normal',
}))

interface CategoriesProps {
  open: boolean,
  onChangeActiveCategory: (category: SportCategoryDictionaryEntity) => void
  handleDropdownClose: () => void
  activeCategory: SportCategoryDictionaryEntity | null
}

function Categories({open, onChangeActiveCategory, activeCategory, handleDropdownClose}: CategoriesProps) {

  const {data: categoriesData} = useGetSportCategoriesQuery()
  const navigate = useNavigate()


  useEffect(() => {
    if (!activeCategory && categoriesData?.getAllSportCategoryDictionary) {
      onChangeActiveCategory(categoriesData?.getAllSportCategoryDictionary?.[0] as SportCategoryDictionaryEntity)
    }
  }, [categoriesData, activeCategory]);


  const changeActiveCategoryHandler = (category: SportCategoryDictionaryEntity) => {
    onChangeActiveCategory(category)
  }

  return (

    <List py={4} px={2}>
      <ListItems>
        {categoriesData?.getAllSportCategoryDictionary?.map((item, index) => (
          <Grow
            in={open}
            style={{transformOrigin: '0 0 0'}}
            {...(open ? {timeout: 400 * index + 1} : {})}
            key={item?.id}
          >
            <Box>
              <ListItem onMouseOver={() => changeActiveCategoryHandler(item as SportCategoryDictionaryEntity)}
                        active={item.id === activeCategory?.id}
                        onClick={() => {
                          navigate({
                            pathname: "/products",
                            search: `?${createSearchParams({
                              categoryId: item.id as string,
                            })}`
                          })
                          handleDropdownClose()
                        }}
              >
                {item.name}
              </ListItem>
            </Box>
          </Grow>
        ))}
      </ListItems>
    </List>
  );
}


const CatalogMenu = ({open, anchorEl, handleDropdownClose, children}: any) => {
  const theme = useTheme()


  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleDropdownClose}

      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      PaperProps={{
        sx: {
          maxWidth: 'calc(100% - 16px)',
          height: 500,
          width: '100%',
          borderRadius: '0 !important',
          mt: 2.6,
          boxShadow: 'none !important',
          background: `linear-gradient(90deg,${theme.palette.background.default} 0,${theme.palette.background.default} 50.9999%,${theme.palette.background.paper} 51%,${theme.palette.background.paper})`,
          p: 0,
          borderBottom: `1px solid ${theme.palette.divider}`,
          left: '0px !important',
        }
      }}
      MenuListProps={{
        sx: {
          p: 0,
          height: '100%',
        }
      }}
    >
      {children}
    </Menu>
  )
}

function Navigate(props: Props) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const open = Boolean(anchorEl);
  const [activeCategory, setActiveCategory] = useState<SportCategoryDictionaryEntity | null>(null);
  const {data: sportKindsData} = useGetSportKindsQuery()
  const [sportKindsLoading, setSportKindsLoading] = useState(true);
  const navigate = useNavigate()


  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }
  const handleDropdownClose = () => {
    setAnchorEl(null)
  }


  const changeActiveCategoryHandler = (category: SportCategoryDictionaryEntity) => {
    setActiveCategory(category)
  }

  const categoriesValue = useMemo(() => {
    if (activeCategory && sportKindsData?.getAllSportKindDictionary) {
      setSportKindsLoading(true)
      const sportKinds = sportKindsData.getAllSportKindDictionary.sportKinds.filter(item => item.sportCategoryId === activeCategory.id).sort((a, b) => a.name.localeCompare(b.name))
      if (sportKinds) {
        setSportKindsLoading(false)
        return sportKinds
      }
    }
  }, [activeCategory, sportKindsData])


  return (
    <>
      <Button variant={'contained'} size={'small'}
              onClick={handleDropdownOpen}
      >
        {!open ? <ListIcon fontSize={'small'} sx={{mr: 1}}/> : <CloseIcon fontSize={'small'} sx={{mr: 1}}/>}
        Каталог
      </Button>

      <CatalogMenu {...{open, anchorEl, handleDropdownClose}} >

        <Container maxWidth={"xl"} sx={{
          height: '100%',
          position: 'relative',
          top: 6
        }}>
          <Grid container spacing={1} sx={{
            height: '100%',
          }}>
            <Grid item lg={2} sx={{
              height: '100%',
              overflow: 'auto'
            }}>

              <Box>
                <Categories open={open} onChangeActiveCategory={changeActiveCategoryHandler}
                            activeCategory={activeCategory} handleDropdownClose={handleDropdownClose}/>
              </Box>
            </Grid>
            <Grid item lg={10} sx={{
              bgcolor: 'background.paper',
              height: '100%',
              overflow: 'auto'
            }}>
              <Grid container spacing={1} px={4} mt={2}>
                {categoriesValue?.map((item) => {
                  return (

                    <Grid item lg={3}>

                      <Fade
                        in={!sportKindsLoading}
                        timeout={500}
                      >
                        <Typography
                          sx={{
                            cursor: 'pointer'
                          }}
                          variant={'subtitle2'}
                          p={2}
                          onClick={() => {
                            navigate({
                              pathname: "/products",
                              search: `?${createSearchParams({
                                categoryId: activeCategory?.id as string,
                                sportKindId: item?.id
                              })}`
                            })
                            handleDropdownClose()
                          }}

                        >{item.name}</Typography>
                      </Fade>
                      <List>
                        {[...item?.equipmentTypes]?.sort((a, b) => a.name.localeCompare(b.name))?.map((equipmentType, index) => {
                          return (
                            <Fade
                              in={!sportKindsLoading}
                              timeout={1000}
                            >
                              <Box>
                                <ListItem
                                  onClick={() => {
                                    navigate({
                                      pathname: "/products",
                                      search: `?${createSearchParams({
                                        categoryId: activeCategory?.id as string,
                                        equipmentTypeId: equipmentType?.id,
                                        sportKindId: item?.id
                                      })}`
                                    })

                                    handleDropdownClose()
                                  }}>
                                  {equipmentType.name}
                                </ListItem>
                              </Box>
                            </Fade>
                          )
                        })}
                      </List>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
        </Container>

      </CatalogMenu>
    </>
  )
}

export default Navigate;
