import React from 'react';
import Page from "../../@core/components/Page";
import {Outlet} from "react-router-dom";

interface Props {

}

function SubscriptionPage(props: Props) {

  return (
    <Page title={'Настройки уведомлений'}>
      <Outlet/>
    </Page>
  );
}

export default SubscriptionPage;
