import React, {ReactNode} from "react";
import Footer from "../../@core/components/Footer";
import Header from "../../@core/components/Header";
import TopBar from "../../@core/components/TopBar";
import {Outlet} from "react-router-dom";
import {Drawer} from "@mui/material";
import useCart from "../../@core/hooks/useCart";
import {styled} from "@mui/material/styles";
import CartDrawer from "../../@core/components/CartDrawer";


export interface MainLayoutProps {
  children?: ReactNode;
}

const MainStyle = styled('main')(({theme})=>({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1
}))

export function MainLayout({children}: MainLayoutProps) {


  const {cartOpen, setCartOpen} = useCart()
  return (
    <>
      <TopBar className="app-top-bar"/>
      <Header className="app-navbar"/>
      <MainStyle className="app-main">
        {children ? children : <Outlet/>}
        <Drawer
          open={cartOpen}
          onClose={() => setCartOpen(false)}
          anchor={'right'}
          PaperProps={{
            sx: {bgcolor: 'background.default'}
          }}
        >
          <CartDrawer/>
        </Drawer>
      </MainStyle>
      {/*<Footer className="app-footer"/>*/}
    </>
  );
}

export default MainLayout;
